// Hide the honey pot

/* purgecss start ignore */

input[name$="[inputUserName]"] {
    display: none;
}

.fb-cl-hide-element {
    display: none;
    -webkit-transition: all 1s;
    transition: all 1s;
}

.dynamic-multi-file {
    padding: 0;
    border: 0;
    height: auto;
}

#formMessages {
    ul {
        margin-bottom: 0px;

        li {
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }
}

#innovation-form {
    .innovation-form-consent {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .modal {
        padding-left: 15px;
        padding-right: 15px;

        .modal-dialog {
            max-width: 800px;
            color: var(--grey);
        }
    }
}

.invalid-feedback {
    color: white;
    background: var(--red);
    display: inline-block !important;
    width: auto;
    padding: 0px 5px;
}

.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
    transition: all 0.2s;
}

.was-validated input.custom-control-input:valid:checked:before {
    background: #34ce57;
    border-color: #34ce57;
}

.custom-checkbox {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding-left: 25px;

    input {
        position: absolute;
        left: 0;
        top: 5px;
        z-index: 1;
        opacity: 1;

        &:before {
            content: "";
            position: absolute;
            width: 1rem;
            height: 1rem;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background: #ffffff;
            border: #ffffff solid 1px;
            border-radius: 0.25rem;
        }

        &:checked {
            opacity: 100%;
        }

        &:checked:before {
            content: "";
            background: #007bff;
            border-color: #007bff;
            border-radius: 0.25rem;
            position: absolute;
            width: 1rem;
            height: 1rem;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        &:checked:after {
            content: "";
            display: block;
            position: absolute;
            left: 5px;
            top: -3px;
            width: 9px;
            height: 15px;
            border: solid #ffffff;
            background: 0 0;
            border-width: 0 4px 4px 0;
            transform: rotate(45deg);
        }
    }

    .custom-control-label::before,
    .custom-control-input:checked ~ .custom-control-label::before {
        display: none;
    }

    .custom-control-input:checked ~ .custom-control-label::after {
        background-image: none;
    }

    label.required {
        order: 1;

        &:after {
            left: 100%;
            top: 0;
        }
    }

    span.invalid-feedback {
        order: 2;
    }
}

.form-builder-html-tag-element.form-title {
    font-size: inherit !important;
    font: 300 37px/45px var(--defaultFonts) !important;
}

#self-service-support-form {
    .dropdown-menu {
        overflow: visible !important;
    }
}

/* purgecss end ignore */