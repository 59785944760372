@media (min-width: 992px) {
    header {
        #nav {
            flex-grow: 1;

            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                li.mobile-header {
                    display: none;
                }
            }

            > ul {
                display: flex;
                justify-content: space-between;
                font: 400 16px/30px var(--defaultFonts);

                @media (max-width: 820px) {
                    font-size: 14px;
                }

                > li {
                    transition: all 0.3s calc(var(--delay) * 0.04s);
                    display: block;

                    &.hidden {
                        display: none;
                    }

                    > a {
                        position: relative;
                        display: block;

                        &,
                        &:hover {
                            color: var(--grey);
                        }

                        &:before {
                            content: "";
                            border: 0px solid transparent;
                            position: absolute;
                            bottom: -25px;
                            left: 50%;
                            transform: translateX(-50%);
                            transition: all 0.3s;
                            opacity: 0.95;
                        }
                    }

                    .container {
                        position: absolute;
                        left: 50%;
                        top: 100%;
                        width: calc(100% - 60px);
                        transform: translateX(-50%);
                        font-size: 14px;
                        line-height: 24px;

                        > .row {
                            margin-bottom: 0;

                            > .col {
                                margin-bottom: 0;
                            }
                        }

                        > .row > .col > ul {
                            display: grid;
                            grid-template-columns: repeat(3, 1fr);
                            grid-gap: 30px;
                            transition: all 0.4s;
                            max-height: 0;
                            overflow: hidden;
                            box-sizing: content-box;

                            &:before {
                                content: "";
                                position: absolute;
                                left: 50%;
                                transform: translateX(-50%);
                                top: 0;
                                width: 100vw;
                                height: 100%;
                                background: var(--grey);
                                z-index: -1;
                                opacity: 0.95;
                            }

                            a {
                                color: var(--white);
                                transition: color 0.2s;

                                &:hover {
                                    color: var(--yellow);
                                }
                            }

                            li.active:not(.active-trail) > a {
                                color: var(--yellow);
                            }

                            > li {
                                opacity: 0;
                                position: relative;
                                top: -30px;
                                transition: all 0.3s;

                                > a {
                                    font-size: 16px;
                                    font-weight: bold;
                                }

                                ul a {
                                    font-weight: 400;
                                }
                            }
                        }
                    }

                    &.hover,
                    &.active {
                        > a {
                            color: var(--cepheidBlue);
                        }
                    }

                    &.hover {
                        > a {
                            &:before {
                                border: 12px solid transparent;
                                border-bottom-color: var(--grey);
                            }
                        }

                        .container > .row > .col > ul {
                            max-height: ~"calc(100vh - 125px)";
                            padding-top: 30px;
                            margin-bottom: 30px;

                            > li {
                                transition: all 0.3s
                                    calc((var(--delay) * 0.075s) + 0.15s);
                                top: 0;
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        form.open ~ #nav > ul > li {
            margin-top: -20px;
            opacity: 0;
            transition: all 0.3s calc(1 / var(--delay) * 0.3s);
        }
    }
}
