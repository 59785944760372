.accordion {
    .row.accordion-item {
        border-top: 1px solid var(--mediumGrey);
        cursor: pointer;
        position: relative;
        margin: 0;

        &:before {
            content: "";
            position: absolute;
            left: 0%;
            top: 0;
            width: 0%;
            height: 100%;
            background: var(--lightBlue);
            z-index: -1;
        }

        &:hover {
            &:before {
                width: 100%;
            }
        }

        &:last-of-type {
            border-bottom: 1px solid var(--mediumGrey);
        }

        div[class*="col"] {
            padding-top: 45px;
            padding-bottom: 45px;
            margin-bottom: 0;

            @media (max-width: 767px) {
                padding-top: 15px;
                padding-bottom: 15px;
            }

            &.image {
                padding-top: 0;
                padding-bottom: 0;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;

                @media (max-width: 991px) {
                    line-height: 0;
                }

                img {
                    position: static;
                }
            }
        }

        h2 {
            display: flex;
            margin: 0;

            strong {
                transition: all 0.3s !important;
            }
        }

        .content-container {
            margin: 30px 0 0;

            @media (max-width: 767px) {
                margin: 15px 0 0;
            }
        }

        svg {
            @media (min-width: 768px) {
                margin-top: 5px;
            }

            &,
            rect {
                transition: all 0.3s;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: bottom center;
            position: absolute;
            bottom: 0;
            left: 0;
            padding-top: 10px;
            transition: all 0.3s;
            max-height: 124px;

            .pimcore_editable & {
                position: relative;
            }

            @media (max-width: 991px) {
                position: relative;
                height: 0px;
                width: 100%;
                padding: 0;
            }
        }

        .content {
            max-height: 0px;
            overflow: hidden;
            transition: all 0.3s;
        }

        &.open {
            cursor: auto;

            &:before {
                opacity: 0;
            }

            h2,
            svg {
                cursor: pointer;
            }

            h2 strong {
                color: var(--grey);
            }

            svg {
                transform: rotate(90deg);

                rect:first-of-type {
                    opacity: 0;
                }
            }

            img {
                padding-top: 30px;
                transition: all 0.5s;
                max-height: 500px;

                @media (max-width: 991px) {
                    height: 440px;
                }

                @media (max-width: 767px) {
                    height: 300px;
                }
            }

            .content {
                max-height: 2000px;
            }
        }
    }
}

.accordion.support .row.accordion-item {
    &:before {
        background: var(--cepheidBlue);
    }
}

/* purgecss start ignore */
.leadership-block_not-edit {
    margin: 30px auto;

    .leadership-block__parent_container {
        display: flex;
        flex-wrap: wrap;
        position: relative;

        .leadership-block__item {
            cursor: pointer !important;
            margin: 20px 0px;
            width: 25%;

            @media (max-width: 992px) {
                width: 33.33%;
            }

            @media (max-width: 625px) {
                width: 100%;
            }

            .leadership-block_image {
                img {
                    width: 160px;
                    max-width: 100%;
                    transition: opacity 0.3s;


                    @media (max-width: 625px) {
                        width: 261px;
                        height: auto;
                    }
                }
            }

            .leadership-block__titles {
                padding: 5px;

                h2 {
                    font-size: 20px;
                    line-height: 35px;
                    margin: 0;
                    padding: 0;
                }

                h6 {
                    font-weight: bold;
                    font-size: 9px;
                }
            }

            a {
                color: var(--grey);

                h6 {
                    font-weight: bold;
                    font-size: 10px !important;
                }
            }

            .leadership-block__profile_content_not-edit {
                display: none;

                @media (max-width: 625px) {
                    p {
                        font-size: 16px;
                    }
                }
            }
        }

        .activate {

            .leadership-block__titles {
                background-color: var(--cepheidBlue);
                width: 160px;
                margin: auto;

                @media (max-width: 625px) {
                    width: 261px;
                }
            }

            .leadership-block_image img {
                filter: unset !important;
            }

            .leadership-block__yellow_arrow {
                position: relative;
                top: 25px;
                z-index: 1;
                background-color: white;
                padding: 0px 20px !important;

                @media (max-width: 625px) {
                    top: 15px;
                    right: 35%;
                }

            }

            .leadership-block__profile_content_not-edit {
                border: 0.845854px solid var(--cepheidBlue);
                box-shadow: 2px 2px 8px -3px var(--cepheidBlue);
                -webkit-box-shadow: 2px 2px 8px -3px var(--cepheidBlue);
                -moz-box-shadow: 2px 2px 8px -3px var(--cepheidBlue);
                height: auto;
                max-width: 1000px;
                width: auto;
                text-align: left;
                position: absolute;
                left: 0;
                margin: 18px 70px 20px 70px;
                padding: 0px calc((25% - 225px) / 2);

                @media (max-width: 992px) {
                    padding: 0px calc((33.33% - 225px) / 2);
                }

                @media (max-width: 625px) {
                    p {
                        padding: 0;
                    }

                    padding: 25px;
                    margin: 5px 20px 20px 20px;
                }

                & * {
                    padding: 25px 0px;
                    margin: 0;
                }

                .leadership-block__close_container {
                    padding: 0;
                    position: absolute;
                    right: 0;
                    top: 0;

                    a {
                        padding: 0;

                        img {
                            padding-right: 10px;
                            padding-top: 10px;
                        }
                    }
                }
            }
        }
    }
}

/* purgecss end ignore */