/* purgecss start ignore */

.icons-list {
    background-size: cover;

    .icons-list__text {
        margin-top: 15px;
    }

    h2 {
        color: var(--white);
        display: flex;
        align-items: center;
        justify-content: center;

        strong {
            font-size: unset;
            color: unset;
            line-height: unset;
        }
    }

    h2:after,
    h2:before {
        content: '';
        width: 150px;
        height: 2px;
        background: var(--white);
        margin: 0 45px;

        @media (max-width: 500px) {
            margin: 0 25px;
        }
    }

    .icons-list__container .row {
        text-align: center;
        align-items: flex-end;
        margin-top: 20px;

        @media (max-width: 576px) {
            align-items: flex-start;
        }

        .icons-list__col {
            border-right: solid 1px var(--white);

            @media (max-width: 576px) {
                border-right: none;
            }
        }

        .icons-list__col:last-child {
            border-right: none;
        }
    }

    // flex container - horizontal icon and text

    .icons-list__flex-containers-parent {
        display: flex;

        .icons-list__remove-borders {
            border-right: none !important;
        }

        .icons-list__flex-containers-child {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 80px;

            @media (max-width: 770px) {
                margin-bottom: 150px;
            }

            @media (max-width: 576px) {
                margin-bottom: 50px;
                justify-content: flex-start;
            }

            img {
                padding-right: 15px;
                height: 78px;
            }
        }

    }

    .icons-list__flex-containers {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        height: 75px;
        justify-content: start;

        p {
            text-align: left;
            margin: 0;
        }
    }
}

/* purgecss end ignore */