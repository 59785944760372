.area-social-links {
    margin: 30px 0;
    display: inline-grid;
    grid-gap: 30px;
    grid-auto-flow: column;

    a {
        position: relative;

        &:before,
        &:after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border-radius: 100%;
            transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
            width: 0;
            height: 0;
            border: 0px solid var(--white);
        }

        &:before {
            transition-delay: 0.075s;
        }

        img {
            display: block;
            height: 15px;
        }

        &:hover {
            &:before {
                width: 50px;
                height: 50px;
                border: 1px solid white;
            }

            &:after {
                width: 40px;
                height: 40px;
                border: 2px solid white;
            }
        }
    }

    @media (max-width: 767px) {
        margin: 25px 0;
    }
}
