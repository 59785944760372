.preview-images,
.template-preview-images {
    .products,
    .preview-images {
        div[class*="col"]:not(.btns) {
            border-right: 1px solid var(--mediumGrey);

            &:last-of-type {
                border: none;
            }

            @media (min-width: 1074px) {
                &:nth-of-type(4n) {
                    border: none;
                }
            }

            @media (max-width: 1073px) and (min-width: 813px) {
                &:nth-of-type(3n) {
                    border: none;
                }
            }

            @media (max-width: 812px) and (min-width: 520px) {
                &:nth-of-type(2n) {
                    border: none;
                }
            }

            @media (max-width: 519px) {
                border: none;
            }
        }

        div[class*="col"]:last-child .preview-item {
            border-bottom: none;
        }
    }

    .preview-item {
        display: block;
        width: 230px;
        min-height: 150px;
        text-align: center;
        color: var(--grey);
        position: relative;
        padding-bottom: 50px;
        margin: auto;
        transition: all 0.3s;
        background: white;

        .pimcore_tag_image {
            z-index: 2;
        }

        @media (max-width: 767px) {
            padding-bottom: 0;
            margin-bottom: 50px;
        }

        @media (max-width: 519px) {
            border-bottom: 1px solid var(--mediumGrey);
        }

        strong {
            margin-bottom: 5px;
            display: block;
        }

        .image {
            position: relative;
        }

        img {
            display: block;
            margin: auto;
            width: 100%;
            height: 208px;
            object-fit: contain;
            position: relative;

            &.play {
                content: "";
                position: absolute;
                width: 74px;
                height: 74px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        @media (min-width: 768px) {
            .text {
                position: absolute;
                bottom: 0;
                background: rgba(255, 255, 255, 0.65);
                width: 100%;
            }
        }

        .text p:after {
            background: url("../icons/arrow-2.svg") no-repeat center;
        }
    }

    .col:only-child .preview-item {
        width: 100%;

        img {
            height: 350px;
        }
    }

    .carousel .preview-item {
        border-bottom: none;
    }

    .large {
        div[class*="col"]:not(.btns) {
            border: none;
        }

        .preview-item {
            width: 100%;

            @media (min-width: 768px) {
                background: linear-gradient(to bottom, transparent, #eee);
            }

            .text {
                padding: 20px;
                transition: all 0.3s;
                background: white;
            }

            &:hover .text {
                background: rgba(255, 255, 255, 0.65);
            }

            img {
                height: 250px;
            }

            .software-badge {
                width: 80px;
                height: auto;
                position: absolute;
                z-index: 10;
                bottom: 71px;
                right: 0px;

                @media (max-width: 768px) {
                    bottom: 65px;
                }
            }
        }
    }
}

.product-row img {
    width: 185px;
    max-width: 100%;
    display: block;
    margin: auto;
}
