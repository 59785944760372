.bootstrap-select {
    .filter-option-inner-inner {
        color: #495057;
    }

    a.dropdown-item {
        border-radius: 0;
    }

    .bs-select-all {
        border-right: 2px solid #fff !important;
    }

    .bs-select-all,
    .bs-deselect-all {
        justify-content: center !important;
    }
}

#tm-instrument-app .bootstrap-select > button,
#tm-assay-app .bootstrap-select > button,
#inserts-filter-app .bootstrap-select > button {
    background-color: #fff;
    border: 1px solid #ced4da;
}

#contact-list select,
.bs-select {
    background-color: #f8f9fa;
    border-color: #ddd;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    color: #495057;
    width: 100%;
}
