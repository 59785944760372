.image-hotspot {
    position: absolute;
    background: rgba(235, 105, 9, 0.2);
    border: 1px solid rgba(235, 105, 9, 1);
    border-radius: 50%;
}

.image-marker {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: #2aabeb;
}

.area-image img {
    max-width: 100%;
    height: auto;
}

.area-image .full-img img {
    width: 100%;
}

/* purgecss start ignore */

@media (min-width: 768px) {

    .width25 {
        width: 25%;
        margin: 0 auto;
    }

    .width50 {
        width: 50%;
        margin: 0 auto;
    }

    .width75 {
        width: 75%;
        margin: 0 auto;
    }

    .width100 {
        width: 100%;
        margin: 0 auto;
    }

}

/* purgecss end ignore */
