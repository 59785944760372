.poc-sticky-nav-container {
    
     nav.poc-sticky-nav {

        background: var(--cepheidBlue);
        position: sticky;
        top: 80px;
        z-index: 4;

        @media (max-width: 767px) {
            top: 70px;
        }

         .poc-sticky-editmode-link a {
             color: #fff;
         }

        .row,
        .col {
            margin-top: 0;
            margin-bottom: 0;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            text-align: center;
            border-bottom: none;

            @media (max-width: 767px) {
                justify-content: space-between;
            }

            @media (max-width: 480px) {
                font-size: 10px;
                line-height: 12px;
                font-weight: bold;
            }

            li {
                margin-right: 60px;

                &:last-of-type {
                    margin-right: 0;
                }

                @media (max-width: 991px) {
                    margin-right: 30px;
                }

                @media (max-width: 480px) {
                    margin-right: 15px;
                }

                a {
                    display: inline-block;
                    color: white;
                    padding: 30px 0;
                    border-left: none !important;
                    border-right: none !important;
                    border-top: none !important;
                    border-bottom: 0px solid var(--yellow);
                    transition: all 0.3s;
                    margin: 0;
                    overflow: visible;

                    @media (max-width: 480px) {
                        padding: 15px 0;
                    }

                    &.active {
                        padding-bottom: 20px;
                        background: none;
                        color: white;
                        border: none;
                        border-bottom: 10px solid var(--yellow);

                        @media (max-width: 480px) {
                            padding-bottom: 10px;
                            border-bottom: 5px solid var(--yellow);
                        }
                    }
                }
            }
        }
    }
}