.iframe-container {
    position: relative;
    margin: 0 auto;
    overflow: hidden;
}

.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

//override bootstrap select
select.selectpicker {
    display: block!important;
}