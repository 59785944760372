/* purgecss start ignore */
.area-resources.template-default {
    .col-md-auto .preview-item {
        max-width: 350px;
    }

    .preview-item {
        width: 100%;
        max-width: 100%;
        padding: 20px;
        height: 250px;
        position: relative;
        display: flex;
        align-items: center;
        background: var(--lightGrey);
        transition: background 0.3s;
        text-align: center;
        color: var(--grey);
        margin-top: 50px;
        overflow: visible;

        @media (max-width: 767px) {
            height: auto;
            padding: 45px 30px 30px;
        }

        &:hover {
            background: var(--mediumGrey);
        }

        .text {
            width: 100%;
        }

        h4 {
            margin: 0;
        }

        h6 {
            margin: 0;
        }

        p+p {
            display: none;
        }

        >img {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 80px;
            width: 80px;
            object-fit: contain;

            @media (max-width: 767px) {
                height: 70px;
                width: 70px;
            }
        }
    }

    &.edit .preview-item:hover {
        background: var(--lightGrey);
    }

    &.white-theme {
        background: var(--lightGrey);

        .preview-item {
            background: white;

            &:hover {
                background: var(--mediumGrey);
            }
        }

        &.edit .preview-item:hover {
            background: white;
        }
    }

    &.white-drop-shadow-theme {
        background: var(--lightGrey);

        .preview-item {
            background: white;
            align-items: flex-start;
            padding-top: 50px;
            box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
            transition: all 0.3s;
        }

        @media (min-width: 768px) {
            &:not(.edit) .preview-item:hover {
                background: #fff;
                box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);

                h6 {
                    opacity: 1;
                }
            }
        }

        .preview-item p {
            max-height: 100px;
        }

        .wysiwyg ul {
            margin: 5px 0;
        }
    }
}

.preview-item p {
    margin: 0;
    transition: all 0.3s;
    font-weight: 400;
    position: relative;
    overflow: hidden;

    @media (min-width: 767px) {
        max-height: 0;
    }

    +span.arrow {
        content: "";
        height: 0px;
        background: url("../icons/arrow-3.svg") no-repeat center;
        opacity: 0;
        transition: all 0.3s;
        display: block;
        margin: auto;
        width: 100%;
    }
}

#support-section {
    .area-resources.template-default:not(.edit) .preview-item {
        align-items: flex-start;
        padding-top: 50px;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
        transition: all 0.3s;
    }

    @media (min-width: 768px) {
        .area-resources.template-default:not(.edit) .preview-item:hover {
            background: #fff;
            box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
        }
    }

    .preview-item p {
        max-height: 100px;
    }

    .wysiwyg ul {
        margin: 5px 0;
    }
}

@media (min-width: 768px) {
    .area-resources.template-default:not(.edit) .preview-item:hover {
        background: linear-gradient(to bottom, #fff 75%, #eee);

        h6 {
            opacity: 0;
        }

        p {
            max-height: 100px;

            +span.arrow {
                opacity: 1;
                margin-top: 5px;
                height: 12px;
            }
        }
    }
}

.inserts {
    margin-top: 30px;
}

@media (min-width: 768px) {
    .impact-download {
        text-align: right;
    }
}

.impact-briefs {
    h4:only-child {
        margin: 0;
    }

    .description {
        max-height: 0px;
        overflow: hidden;
        transition: all 0.5s;

        &.open {
            max-height: 500px;
        }
    }

    svg,
    rect {
        transition: all 0.5s;
    }

    @media (max-width: 767px) {
        .impact-download a.btn {
            width: 100%;
            justify-content: center;
        }
    }

    .read-more.open svg {
        transform: rotate(90deg);

        rect:first-of-type {
            opacity: 0;
        }
    }

    hr {
        margin: 0;
    }
}

.template-object-cards {
    overflow: hidden;
    background: var(--cepheidBlue);
    position: relative;
    min-height: 70vh;

    .object-cards-bg {
        img {
            transform-origin: top;
            height: 100%;
            width: 100%;
            object-fit: cover;
            position: absolute;
            opacity: 0;
            transition: opacity 0.5s ease-in;
            top: 0;
        }

        &.position-left-top img {
            object-position: left top !important;
        }

        &.position-left-center img {
            object-position: left center !important;
        }

        &.position-left-bottom img {
            object-position: left bottom !important;
        }

        &.position-right-top img {
            object-position: right top !important;
        }

        &.position-right-center img {
            object-position: right center !important;
        }

        &.position-right-bottom img {
            object-position: right bottom !important;
        }

        &.position-center-top img {
            object-position: center top !important;
        }

        //&.position-center img {
        //    object-position: center;
        //}

        &.position-center-bottom img {
            object-position: center bottom !important;
        }
    }

    &.adjusted .object-cards-bg img {
        opacity: 1;
    }

    .object-cards {
        &>.row {
            @media (max-width: 1199px) {
                justify-content: center;
            }
        }

        .col {
            display: flex;
            flex-direction: column;
            text-align: center;
            margin: 8px;
            padding: 32px;
            border-radius: 8px;
            background-color: #fff;
            margin-right: 15px;
            margin-left: 15px;
            box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.20);
            position: relative;
            transition: all 0.25s;
            // overflow: hidden;
            max-width: 350px;
            min-width: 350px;

            &:hover {
                box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.30);
            }

            @media (max-width: 767px) {
                margin-bottom: 30px;
            }

            >a {
                color: var(--grey);
                text-decoration: none;
                font-size: 1rem;

                &.preview-item p.clamped-6 {
                    margin-top: 0.5rem;
                    margin-bottom: 0.5rem;
                    font-size: 0.875rem;

                    @media (min-width: 767px) {
                        max-height: none;
                    }
                }

                &::before {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 49.99%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: 6px;
                }

                &.is-new::before {
                    background: url("../icons/new-product.svg") no-repeat;
                    background-position: -130px -123px;
                    background-size: auto;
                }

                span.learn-more {
                    display: inline-block;
                    border-bottom: 1px solid transparent;
                    color: var(--cepheidBlue);
                    font-weight: 600;
                    text-decoration: none;

                    &:hover {
                        border-bottom-color: var(--cepheidBlue);
                        color: var(--cepheidBlue);
                    }
                }
            }

            &.two-col-layout {
                flex: 1 1 45%;

                @media (min-width: 1200px) {
                    max-width: 540px;
                    min-width: 540px;
                }
            }

            // .preview-item {
            //     display: flex;
            //     flex-wrap: wrap;
            //     justify-content: center;
            //     align-items: flex-end;
            //     height: 100%;
            // }
            // a.preview-item > img {
            //     height: 60%
            // }
            // a.preview-item > div.text {
            //     display: flex;
            //     flex-direction: column;
            //     justify-content: space-between;
            //     height: 40%;
            // }
        }
    }
}

.area-resources .simple-list {

    >hr {
        margin-top: 50px;
    }

    .simple-list__container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .simple-list__text {
            width: 70%;

            h3 {
                margin-bottom: 0;
            }
        }

        .simple-list__download {
            width: 20%;
        }


        @media (max-width: 1200px) {
            .simple-list__text {
                width: 60%;
            }

            .simple-list__download {
                width: 30%;
            }

        }

        @media (max-width: 770px) {
            .simple-list__text {
                width: 90%;
            }

            .simple-list__download {
                width: unset;
            }

        }

    }
}

/* purgecss end ignore */