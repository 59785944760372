:root {
    //Colors
    --grey: #4c4d4f; //Abbey
    --lightGrey: #ecedec; //Gray Nurse
    --mediumGrey: #bec0bd; //Green Spring
    --cepheidBlue: #007ac2; //Lochmara
    --darkBlue: #007ac2; //Lochmara, was #0B7AB0
    --lightBlue: #7eccf0; //Seagull
    --green: #77b800; //Limeade
    --yellow: #fdb71a; //Lightning Yellow
    --white: #ffffff; //White
    --errorRed: #e81818;
    --yellowGradient: linear-gradient(#fdb71a,
            #f7941d); //Lightning Yellow and Tree Poppy
    --greenGradient: linear-gradient(#77b800, #74a333); //Limeade and Sushi
    --greenGradientReverse: linear-gradient(180deg,
            #68883d 0%,
            #8ab64e 76.56%,
            #8ab64e 100%);
    --blueGradient: linear-gradient(#6cbced 7.81%, #007ac2 85.42%); //Lochmara
    --grayGradient: linear-gradient(180deg, #bcbebb 0%, #ebebe9 100%);

    //Fonts
    --defaultFonts: "Helvetica Neue", Arial, "Lucida Grande", sans-serif;
    --desktopFontSize: 18px;
    --desktopLineHeight: 26px;
    --mobileFontSize: 15px;
    --mobileLineHeight: 20px;

    --sectionSpacing: 60px;
    --sectionSpacingMobile: 30px;
}

//Media Queries
@mobile: 767px;

@light: 300;
@reg: 400;
@medium: 500;

body {
    font-size: var(--desktopFontSize);
    line-height: var(--desktopLineHeight);
    font-family: var(--defaultFonts);
    color: var(--grey);

    #ccc,
    #ccc button {
        font-family: var(--defaultFonts) !important;

        svg {
            vertical-align: top;
        }
    }

    svg text {
        font-family: var(--defaultFonts);
    }

    .light {
        font-weight: @light;

        @media (max-width: 767px) {
            font-weight: @reg;
        }
    }

    @media (max-width: 767px) {
        .desktop-only {
            display: none;
        }
    }

    @media (min-width: 768px) {
        .mobile-only {
            display: none;
        }
    }

    &.mobileOpen {
        overflow: hidden;
    }

    hr {
        border-color: var(--mediumGrey);
        margin: 30px 0;
    }

    main {
        min-height: ~"calc(100vh - 410px)";
    }

    strong {
        font-weight: 600;
    }

    p {
        margin: 15px 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .wysiwyg,
    .product-sections {
        table {
            width: 100%;
            border: none;
            padding: 15px 0;

            @media (max-width: 767px) {
                padding: 15px 25px;
            }

            thead {
                background: var(--lightGrey);
                color: var(--cepheidBlue);
                border-bottom: 2px solid var(--mediumGrey);

                th {
                    border: none;
                    font-weight: 500;
                    padding: 20px;
                }
            }

            tr {
                td {
                    border: none;
                    border-bottom: 1px solid var(--mediumGrey);
                    padding: 20px;
                }
            }

            &.no-border {
                padding: 0;

                tr td {
                    border: none;
                    padding: 0;

                    img {
                        margin: 15px 15px 15px 0;
                        min-width: 85px;
                        min-height: 85px;
                    }
                }
            }
        }
    }

    .bg-light-grey {
        background: var(--lightGrey);
    }

    .bg-grey {
        background: var(--mediumGrey);
    }

    .blue {
        background: var(--blueGradient);
    }

    .white {
        background: var(--white);
    }

    .alt-blue {
        background: var(--cepheidBlue) center / cover no-repeat url("backgrounds/gradient.png");
    }

    .white {
        background: var(--white);
    }

    .white-txt {
        color: white !important;

        h2,
        h3 {
            color: white;
        }

        span.breadcrumbs a,
        span.breadcrumbs a:not([class]) {
            color: white;
        }
    }

    .green {
        background: var(--greenGradient);
    }

    .green-reversed {
        background: var(--greenGradientReverse);
    }

    .enlarged-images {
        img {
            max-width: none;

            @media (max-width: 767px) {
                max-width: 100%;
            }
        }
    }

    .deep-blue-txt {
        color: #196fb9;
    }

    .deep-blue .large-margins {
        border-top: 20px #196fb9 solid;
        padding-top: 15px;
    }

    .lit-blue-txt {
        color: #00BDF2;
    }

    .lit-blue .large-margins {
        border-top: 20px #00BDF2 solid;
        padding-top: 15px;
    }

    .deep-green-txt {
        color: #73A428;
    }

    .deep-green .large-margins {
        border-top: 20px #73A428 solid;
        padding-top: 15px;
    }

    .golden-txt {
        color: #FEB600 !important;
    }

    .yellow-txt {
        color: #fdb71a !important;
    }

    .golden .large-margins {
        border-top: 20px #FEB600 solid;
        padding-top: 15px;
    }

    .orange-txt {
        color: #E16406;
    }

    .orange .large-margins {
        border-top: 20px #E16406 solid;
        padding-top: 15px;
    }

    .purple-txt {
        color: #6F5890;
    }

    .purple .large-margins {
        border-top: 20px #6F5890 solid;
        padding-top: 15px;
    }

    .gray:not(.area-resources) {
        background: var(--grayGradient);
    }

    .yellow {
        background: var(--yellowGradient);
    }

    .blue-txt {
        color: var(--cepheidBlue) !important;

        span.breadcrumbs a,
        span.breadcrumbs a:not([class]) {
            color: var(--cepheidBlue) !important;
        }
    }

    .grey-txt {
        color: var(--grey) !important;

        span.breadcrumbs a,
        span.breadcrumbs a:not([class]) {
            color: var(--grey) !important;
        }
    }

    .wysiwyg a:not(.btn):not(.small-btn),
    .gallery-content a:not(.btn):not(.small-btn):not(.x-btn),
    .news-item a,
    .form a:not(.dropdown-item),
    #product-information a:not(.btn):not(.small-btn),
    .contact-list a:not(.btn),
    .cta-section a:not(.btn):not(.small-btn),
    .blue-slide-link a:not(.btn):not(.small-btn),
    a.white {
        color: var(--cepheidBlue);
        padding: 0 3px;
        margin: -3px;
        position: relative;
        z-index: 1;
        overflow: hidden;

        background: linear-gradient(to right, #007ac2) no-repeat right;
        background-size: 0% auto;

        &,
        &:before {
            transition: all 0.3s, background-position 0s;
        }

        &:hover,
        &.news-item:hover & {
            color: white;
            background-size: 100% auto;
            background-position: left;
        }
    }

    .news-item {
        a {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 15px;
            padding-bottom: 15px;
        }

        &:hover a {
            color: white;
            background-size: 100% auto;
            background-position: left;
        }
    }

    .form a:not(.dropdown-item),
    a.white:not(.btn):not(.small-btn):not(.x-btn),
    .white a:not(.btn):not(.small-btn):not(.x-btn) {
        color: white;
        font-weight: bold;
        background-image: linear-gradient(to right, white);
        text-decoration: underline;

        &:hover {
            color: var(--darkBlue);
        }
    }

    [class*="clamped"],
    h4.clamped+p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;

        &.clamped-3 {
            -webkit-line-clamp: 3;
        }

        &.clamped-4 {
            -webkit-line-clamp: 4;
        }

        &.clamped-6 {
            -webkit-line-clamp: 6;
        }
    }

    h1 {
        font: @light 50px/58px var(--defaultFonts);
        margin: 0 0 30px;

        &:only-child {
            margin: 0;
        }
    }

    h2,
    .col-form-label {
        font: @light 37px/45px var(--defaultFonts);
        color: var(--cepheidBlue);

        strong {
            font-size: 26px;
            line-height: 34px;
            color: var(--grey);

            @media (max-width: 767px) {
                font-size: var(--mobileFontSize);
                line-height: var(--mobileLineHeight);
            }
        }
    }

    .col-form-label {
        color: white;
        padding: 0;
    }

    .support-header {
        display: flex;
        align-items: center;
        margin-bottom: 30px !important;

        span {
            display: inline-block;
            margin: 0 30px;

            @media (max-width: 767px) {
                margin: 0 15px;
            }
        }

        hr {
            flex-grow: 1;
            margin: 0;
            border-color: white;
        }
    }

    .support-subhead {
        display: flex;
        align-items: center;
        color: white;
        font: @light 20px/28px var(--defaultFonts);
        margin: -20px 0 40px 0;
        justify-content: center;
    }

    h1,
    h2,
    h4,
    p,
    li {
        sup {
            font-size: 50%;
            top: -1em;

            sup {
                font-size: unset;
                top: unset;
            }
        }
    }

    h3 {
        font: @light 21px/25px var(--defaultFonts);
        margin: 0 0 30px;

        @media (max-width: @mobile) {
            margin-bottom: 15px;

            &:not(.medium) {
                font-weight: @reg;
            }
        }

        &.reg {
            font-weight: @reg;
        }

        &.medium {
            font-weight: @medium;
            color: var(--cepheidBlue);
        }

        &.hr {
            background: white;
            display: inline-flex;
            align-items: center;
            padding: 0 30px;
            transition: all 0.7s;
            color: transparent;

            &:before,
            &:after {
                content: "";
                position: absolute;
                width: 0%;
                background: var(--mediumGrey);
                height: 1px;
                z-index: -1;
                transition: all 0.7s;
            }

            &:before {
                right: 50%;
            }

            &:after {
                left: 50%;
            }

            &.visible {
                color: var(--grey);

                &:before,
                &:after {
                    width: 50%;
                }
            }
        }
    }

    h4,
    .read-more {
        font: @medium 21px/28px var(--defaultFonts);
        margin-bottom: 15px;

        &.light {
            margin: 0;
        }

        &.sml-txt {
            color: var(--grey);
            margin: 0;
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: all 0.2s;

            &:hover {
                opacity: 0.7;
            }

            svg {
                margin-right: 10px;
            }
        }
    }

    h2,
    h4,
    .col-form-label {
        margin: 0 0 15px;
    }

    h2.center {
        margin-bottom: var(--sectionSpacing);

        @media (max-width: @mobile) {
            margin-bottom: var(--sectionSpacingMobile);
        }
    }

    h5 {
        font: @light 12px/16px var(--defaultFonts);
        margin: 0 0 30px;
        color: #828282;

        span.breadcrumbs a {
            color: #828282;
        }

        @media (max-width: @mobile) {
            &:not(.medium) {
                font-weight: @reg;
            }
        }

        &.medium {
            font-size: var(--desktopFontSize);
            line-height: var(--desktopLineHeight);
            font-weight: @medium;
            color: var(--cepheidBlue);
        }
    }

    table h5 {
        margin: 0;
    }

    h6 {
        font: @medium 12px/20px var(--defaultFonts);
        letter-spacing: 0.5px;
        text-transform: uppercase;
        margin: 0 0 15px;
    }

    .campaign-form-container {
        position: sticky;
        top: 60px;

        .brick-area.form>.container>.row>.col {
            padding: 0;
        }
    }

    .wysiwyg,
    .featurette,
    .product-sections {
        ul {
            margin: 15px 0;
            padding: 0 0 0 20px;
            list-style: none;

            li {
                position: relative;

                &:before {
                    content: "•";
                    color: var(--cepheidBlue);
                    position: absolute;
                    left: -20px;
                    font-size: 24px;

                    @media (max-width: 767px) {
                        font-size: 18px;
                    }
                }

                ul {
                    margin: 0;

                    li:before {
                        content: "-";
                    }
                }
            }

            @media (max-width: 767px) {
                padding-left: 25px;
            }
        }

        .clear ul:not(.blue-list) {
            li:before {
                color: white;
            }
        }

        .yellow-check-list,
        .yellow-white-check-list {
            list-style: none;

            li {
                margin-bottom: 30px;
                padding-left: 35px;
                min-height: 54px;
            }
        }

        .yellow-check-list li:before {
            content: url('/static/img/yellow-check-box.svg');
        }

        .yellow-white-check-list li:before {
            content: url('/static/img/yellow-white-check-box.svg');
        }
    }

    div.svg svg {
        max-width: 100%;
        height: auto;
    }

    .sml-txt {
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.4px;
        color: var(--mediumGrey);

        @media (max-width: @mobile) {
            font-size: 10px;
            line-height: 14px;
        }
    }

    .offset-txt {
        position: relative;
        top: 30px;
    }

    .flex-end {
        flex-grow: 1;
        display: flex;
        align-items: flex-end;
    }

    @media (max-width: @mobile) {
        font-size: var(--mobileFontSize);
        line-height: var(--mobileLineHeight);

        h1,
        h2,
        .col-form-label {
            font-size: 25px;
            line-height: 30px;
        }

        h3 {
            font-size: var(--mobileFontSize);
            line-height: var(--mobileLineHeight);
        }

        h4 {
            font-weight: bold;
            font-size: 16px;
            line-height: 18px;
        }

        h6 {
            font-weight: bold;
        }
    }

    .training-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
        grid-gap: 30px;
    }

    section.brick-area,
    .padded {
        padding: var(--sectionSpacing) 0;

        @media (max-width: 767px) {
            padding: var(--sectionSpacingMobile) 0;
        }

        &.no-bottom {
            padding-bottom: 0;

            @media (min-width: 992px) {
                &.image div[class*="col"]:first-child {
                    padding-bottom: 60px;
                }
            }
        }

        &.no-top {
            padding-top: 0;

            @media (min-width: 992px) {
                &.image div[class*="col"]:first-child {
                    padding-top: 60px;
                }
            }
        }

        &.gallery.multiple {
            padding-bottom: 0;
        }

        section.brick-area {
            padding: 0;
            background: none;
        }

        .brick-separate {
            padding-top: var(--sectionSpacing);

            @media (max-width: @mobile) {
                padding-top: var(--sectionSpacingMobile);
            }
        }

        &.form,
        &.form-section {
            background: #007ac2;
            background: var(--darkBlue);
            color: white;

            .required:after {
                content: "*";
            }

            .custom-checkbox {
                margin: 0 0 1em;
            }

            .col-form-label.required,
            .form-builder-html-tag-element {
                font-size: var(--desktopFontSize);
                font-weight: bold;
                margin: 1em 0 0;
            }

            .form-builder-html-tag-element {
                margin: 0;
            }

            .custom-control.custom-checkbox:not(:first-of-type) {
                margin: 1em 0;
            }

            br {
                display: none;
            }

            .row,
            .form-group .form-group {

                &,
                >div {
                    margin: 0;

                    @media all and (-ms-high-contrast: none),
                    (-ms-high-contrast: active) {
                        margin-right: 20px;
                    }
                }
            }

            .row {
                display: grid;
                grid-column-gap: 30px;
                grid-row-gap: 1em;
                grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            }
        }
    }

    .left {
        text-align: left;
    }

    .center {
        text-align: center;
        justify-content: center;
    }

    .rel {
        position: relative;
    }

    .flex-right {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
    }

    .container,
    .container-fluid {
        padding: 0 30px;
        max-width: 1170px;

        @media (max-width: 767px) {
            padding: 0 15px;
        }

        .row {
            flex: 1;
            margin-bottom: -30px;

            >div {
                margin-bottom: 30px;
            }
        }

        .row.large-margins {
            margin-bottom: -60px;

            >div {
                margin-bottom: 60px;
            }
        }

        @media (min-width: 768px) {
            .row.large-margins {
                margin-bottom: -120px;

                >div {
                    margin-bottom: 120px;
                }
            }
        }
    }

    .container-fluid {
        max-width: none;
    }

    .container-fluid.carousel-layout,
    .impact-stories .container-fluid {
        max-width: 1330px;
    }

    .cta-row.icons .pimcore_tag_block {
        display: flex;
        flex-wrap: wrap;
    }

    .back-to-top {
        visibility: hidden;
        opacity: 0;
        position: fixed;
        z-index: 8;
        bottom: 30px;
        right: 30px;
        cursor: pointer;
        transition: all 0.4s, visibility 0s 0.4s;

        &.display {
            transition: all 0.4s;
            visibility: visible;
            opacity: 1;
        }
    }
}