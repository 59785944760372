/* purgecss start ignore */

body .cta-section {
    background: var(--cepheidBlue) center / cover no-repeat;
    background-image: url(backgrounds/gradient.png);
    text-align: center;

    h2,
    .pimcore_editable a {
        color: white;
    }

    h2 {
        margin-bottom: 30px;

        &:only-child {
            margin: 0;
        }
    }

    .reduce-bottom-margin {
        margin-bottom: 15px;
    }

    .contact-layout {
        text-align: left;
        margin-top: 60px;

        >.row>div {
            display: flex;
            align-items: center;
        }
    }

    .col-lg-4 {
        display: flex;
        align-items: center;
    }

    h4 {
        color: white;
        display: inline-block;
        margin: 0;
        font-size: var(--desktopFontSize);
        flex-grow: 1;

        a:not(.btn) {
            color: white;
            position: relative;
            background: linear-gradient(to right, white) no-repeat right;
            background-size: 0% auto;
            word-break: break-word;

            &:hover {
                color: var(--cepheidBlue);
            }
        }
    }

    p {
        font-size: 18px;
        line-height: 26px;
    }

    .icon {
        width: 50px;
        height: 50px;
        margin-right: 20px;
        vertical-align: unset;
        min-width: 50px;
    }

    a.cta-btn {
        color: var(--cepheidBlue);
        border: 1px solid white;

        &:before {
            background: white;
        }

        &:hover {
            border-color: white;
            color: white;
        }
    }

    .cta-container {
        max-width: 790px;
        padding: 0 30px;
        margin: auto;
        position: relative;

        @media (max-width: 767px) {
            padding: 0 25px;

            .content {
                padding: 30px 25px;
            }
        }
    }

    &-color {

        &-blue {
            background: var(--blueGradient);
        }

        &-alt-blue {
            background: var(--cepheidBlue) center / cover no-repeat
        }

        &-gray {
            background: var(--greyGradient);
        }

        &-bg-grey {
            background: var(--mediumGrey);
        }

        &-bg-light-grey {
            background: var(--lightGrey);
        }

        &-green {
            background: var(--greenGradient);
        }

        &-green-reversed {
            background: var(--greenGradientReverse);
        }

        &-yellow {
            background: var(--yellowGradient);

            .arrow-box svg.arrow-svg {
                path {
                    fill: var(--white);
                }
            }

        }

        &-white {
            background: var(--white);
        }
    }
}

/* purgecss end ignore */