.area-gallery-single-images .col-sm-4 {
  margin-bottom: 30px;
}

.carousel-caption {
  bottom: 25%;
  text-shadow: 0 0 5px #000;
}
.carousel-indicators.preview {
  bottom: -10px;
}
/* Indicators list style */
.carousel-indicators.preview li {
  border: medium none;
  border-radius: 0;
  height: 54px;
  margin-bottom: 5px;
  margin-left: 0;
  margin-right: 5px !important;
  margin-top: 0;
  width: 100px;
}
/* Indicators images style */
.carousel-indicators.preview img {
  border: 2px solid #ffffff;
  float: left;
  height: 54px;
  left: 0;
  width: 100px;
  opacity: 0.7;
}
/* Indicators active image style */
.carousel-indicators.preview .active img {
  border: 2px solid #428bca;
  opacity: 1;
}
.portal-page .gallery .carousel-inner > .item > img {
  min-width: 100%;
  height: auto;
}
.tabbed-slider {
  padding-bottom: 30px;
}
.tabbed-slider .nav a small {
  display: block;
}
.tabbed-slider .nav {
  background: #eee;
}
.tabbed-slider .nav-justified > li > a {
  border-radius: 0px;
}
.tabbed-slider .carousel-inner .item {
  height: 300px;
  overflow: hidden;
}
.tabbed-slider .carousel-inner .item-0,
.tabbed-slider .nav-pills .item-0.active a {
  background-color: #16a085;
}
.tabbed-slider .carousel-inner .item-1,
.tabbed-slider .nav-pills .item-1.active a {
  background-color: #2980b9;
}
.tabbed-slider .carousel-inner .item-2,
.tabbed-slider .nav-pills .item-2.active a {
  background-color: #e67e22;
}
.tabbed-slider .carousel-inner .item-3,
.tabbed-slider .nav-pills .item-3.active a {
  background-color: #8e44ad;
}
.tabbed-slider .carousel-inner .item-4,
.tabbed-slider .nav-pills .item-4.active a {
  background-color: #2980b9;
}
.tabbed-slider .carousel-inner .item-5,
.tabbed-slider .nav-pills .item-5.active a {
  background-color: #e67e22;
}

/* purgecss start ignore */
input[name$="[inputUserName]"] {
  display: none;
}
.fb-cl-hide-element {
  display: none;
  -webkit-transition: all 1s;
  transition: all 1s;
}
.dynamic-multi-file {
  padding: 0;
  border: 0;
  height: auto;
}
#formMessages ul {
  margin-bottom: 0px;
}
#formMessages ul li {
  padding-top: 5px;
  padding-bottom: 5px;
}
#innovation-form .innovation-form-consent {
  padding-top: 15px;
  padding-bottom: 15px;
}
#innovation-form .modal {
  padding-left: 15px;
  padding-right: 15px;
}
#innovation-form .modal .modal-dialog {
  max-width: 800px;
  color: var(--grey);
}
.invalid-feedback {
  color: white;
  background: var(--red);
  display: inline-block !important;
  width: auto;
  padding: 0px 5px;
}
.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
  transition: all 0.2s;
}
.was-validated input.custom-control-input:valid:checked:before {
  background: #34ce57;
  border-color: #34ce57;
}
.custom-checkbox {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding-left: 25px;
}
.custom-checkbox input {
  position: absolute;
  left: 0;
  top: 5px;
  z-index: 1;
  opacity: 1;
}
.custom-checkbox input:before {
  content: "";
  position: absolute;
  width: 1rem;
  height: 1rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border: #ffffff solid 1px;
  border-radius: 0.25rem;
}
.custom-checkbox input:checked {
  opacity: 100%;
}
.custom-checkbox input:checked:before {
  content: "";
  background: #007bff;
  border-color: #007bff;
  border-radius: 0.25rem;
  position: absolute;
  width: 1rem;
  height: 1rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.custom-checkbox input:checked:after {
  content: "";
  display: block;
  position: absolute;
  left: 5px;
  top: -3px;
  width: 9px;
  height: 15px;
  border: solid #ffffff;
  background: 0 0;
  border-width: 0 4px 4px 0;
  transform: rotate(45deg);
}
.custom-checkbox .custom-control-label::before,
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  display: none;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
}
.custom-checkbox label.required {
  order: 1;
}
.custom-checkbox label.required:after {
  left: 100%;
  top: 0;
}
.custom-checkbox span.invalid-feedback {
  order: 2;
}
.form-builder-html-tag-element.form-title {
  font-size: inherit !important;
  font: 300 37px/45px var(--defaultFonts) !important;
}
#self-service-support-form .dropdown-menu {
  overflow: visible !important;
}
/* purgecss end ignore */

.image-hotspot {
  position: absolute;
  background: rgba(235, 105, 9, 0.2);
  border: 1px solid #eb6909;
  border-radius: 50%;
}
.image-marker {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: #2aabeb;
}
.area-image img {
  max-width: 100%;
  height: auto;
}
.area-image .full-img img {
  width: 100%;
}
/* purgecss start ignore */
@media (min-width: 768px) {
  .width25 {
    width: 25%;
    margin: 0 auto;
  }
  .width50 {
    width: 50%;
    margin: 0 auto;
  }
  .width75 {
    width: 75%;
    margin: 0 auto;
  }
  .width100 {
    width: 100%;
    margin: 0 auto;
  }
}
/* purgecss end ignore */

body main .bootstrap-select button {
  background-color: #dae0e5;
  border-color: #d3d9df;
}
body main #inserts-filter-app,
body main #msds-list {
  padding-top: var(--sectionSpacing);
  padding-bottom: var(--sectionSpacing);
}
body main #inserts-filter-app .header-wrapper,
body main #msds-list .header-wrapper {
  background: var(--lightGrey);
  color: var(--cepheidBlue);
  border-bottom: 2px solid var(--mediumGrey);
  font-weight: 500;
}
body main #inserts-filter-app .inserts-wrapper > div:nth-child(even),
body main #msds-list .inserts-wrapper > div:nth-child(even),
body main #inserts-filter-app .msds-wrapper > div:nth-child(even),
body main #msds-list .msds-wrapper > div:nth-child(even) {
  background: var(--lightGrey);
}
body main #inserts-filter-app .catalog-num > p,
body main #msds-list .catalog-num > p,
body main #inserts-filter-app .document-num > p,
body main #msds-list .document-num > p {
  margin-top: 0;
  font-size: 18px;
}
body main #inserts-filter-app .catalog-num > p > span,
body main #msds-list .catalog-num > p > span,
body main #inserts-filter-app .document-num > p > span,
body main #msds-list .document-num > p > span {
  font-size: 14px !important;
  line-height: 17px;
  letter-spacing: 0.4px;
  color: var(--mediumGrey) !important;
}
body main #inserts-filter-app .document-num p,
body main #msds-list .document-num p {
  font-size: 18px;
}
body main .tc-grey {
  color: var(--grey);
}
body main .campaign-container .container {
  padding: 0;
}
body main .grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
}
body main .grid-item {
  padding: 20px;
  font-size: 30px;
}
body .page-alert {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0 !important;
}
body .page-alert #alert-close {
  color: #fff;
  font-weight: 200;
  position: absolute;
  right: 0;
  margin-right: 20px;
  line-height: 0;
}
body .page-alert h3 {
  display: inline;
  line-height: 1em;
  margin: 0 7px 0 0;
}
body .page-alert a {
  transition: all 1s;
  color: #fff;
  display: inline;
  line-height: 1em;
  font-size: 1.1em;
}
body .page-alert a:hover {
  text-decoration: underline;
}
body .page-alert img {
  height: 30px;
  width: 30px;
  object-fit: contain;
  margin-right: 10px;
}
body .spinner-grow {
  color: #007ac2;
}
body .border-cepheid-blue {
  border-color: #007ac2 !important;
}
body .mdsRegionalIFU {
  cursor: pointer;
  font-weight: 500;
  font-size: 18px;
}
body .mdsRegionalIFU span.active svg {
  transform: rotate(90deg);
  transition: all 0.4s;
}
body .mdsRegionalIFU span.active svg rect:first-of-type {
  opacity: 0;
}
body .mdsRegionalIFU span svg {
  cursor: pointer;
  transition: all 0.4s;
}
body .isIFU .document-num {
  height: 100px;
}
body .isIFU .slide {
  transition: 0.5s cubic-bezier(0.5, 1, 0.5, 1);
  transform: translateY(0);
  width: 100%;
  height: 0;
  overflow: hidden;
}
body .isIFU .slide-up {
  transform: translateY();
}
body .isIFU .slider .info-wrapper {
  overflow: hidden;
  min-width: 100vw;
}
@media (min-width: 767px) {
  body .isIFU .slider .info-wrapper {
    min-width: calc(66vw - 10px);
  }
}
@media (min-width: 1024px) {
  body .isIFU .slider .info-wrapper {
    min-width: 760px;
  }
}
body .isIFU .slider .info-wrapper .slide-assets {
  width: 100%;
  display: flex;
  gap: 5%;
}
body .isIFU .slider .info-wrapper .slide-assets-fileTitle,
body .isIFU .slider .info-wrapper .slide-assets-filePart {
  width: 50%;
}
/**
 * Background helper classes
 */
/* purgecss start ignore */
.blue-gradient-bg {
  background: linear-gradient(180deg, #0078C1 10.42%, #6CBCED 100%);
}
.green-gradient-bg {
  background: linear-gradient(180deg, #77B800 0%, #74A333 100%);
}
.grey-gradient-bg {
  background: linear-gradient(180deg, #4C4D4F 10.42%, #C4C4C4 100%);
}
.orange-gradient-bg {
  background: linear-gradient(180deg, #FDB71A 0%, #F7941D 100%);
}
.unset-style strong {
  font-size: unset;
  color: unset;
  line-height: unset;
}
.bolded-blue span {
  color: #15BEF0;
  text-transform: uppercase;
  font-weight: bold;
}
.profile-language,
.request-btn,
form#siteSearch {
  display: none !important;
}
span.new-file {
  color: var(--cepheidBlue);
  font-weight: 500;
  text-transform: uppercase;
}
a.supportProductBoldTitle.dropdown-item {
  font-weight: 800;
}
.edocPage .preview-images .preview-item {
  padding-bottom: unset;
}
.edocPage .preview-images .preview-item .text {
  position: unset;
}

#ot-sdk-cookie-policy .ot-sdk-subgroup li::before {
  content: "";
}

/* purgecss start ignore */
.video-responsive,
.pimcore_tag_video {
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.video-responsive iframe,
.pimcore_tag_video iframe {
  width: 100%;
  height: 100%;
  position: absolute;
}
.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
/**
 * Magnific fade transition,
 */
.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}
/* purgecss end ignore */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.75s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.accordion .row.accordion-item {
  border-top: 1px solid var(--mediumGrey);
  cursor: pointer;
  position: relative;
  margin: 0;
}
.accordion .row.accordion-item:before {
  content: "";
  position: absolute;
  left: 0%;
  top: 0;
  width: 0%;
  height: 100%;
  background: var(--lightBlue);
  z-index: -1;
}
.accordion .row.accordion-item:hover:before {
  width: 100%;
}
.accordion .row.accordion-item:last-of-type {
  border-bottom: 1px solid var(--mediumGrey);
}
.accordion .row.accordion-item div[class*="col"] {
  padding-top: 45px;
  padding-bottom: 45px;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .accordion .row.accordion-item div[class*="col"] {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.accordion .row.accordion-item div[class*="col"].image {
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 991px) {
  .accordion .row.accordion-item div[class*="col"].image {
    line-height: 0;
  }
}
.accordion .row.accordion-item div[class*="col"].image img {
  position: static;
}
.accordion .row.accordion-item h2 {
  display: flex;
  margin: 0;
}
.accordion .row.accordion-item h2 strong {
  transition: all 0.3s !important;
}
.accordion .row.accordion-item .content-container {
  margin: 30px 0 0;
}
@media (max-width: 767px) {
  .accordion .row.accordion-item .content-container {
    margin: 15px 0 0;
  }
}
@media (min-width: 768px) {
  .accordion .row.accordion-item svg {
    margin-top: 5px;
  }
}
.accordion .row.accordion-item svg,
.accordion .row.accordion-item svg rect {
  transition: all 0.3s;
}
.accordion .row.accordion-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: bottom center;
  position: absolute;
  bottom: 0;
  left: 0;
  padding-top: 10px;
  transition: all 0.3s;
  max-height: 124px;
}
.pimcore_editable .accordion .row.accordion-item img {
  position: relative;
}
@media (max-width: 991px) {
  .accordion .row.accordion-item img {
    position: relative;
    height: 0px;
    width: 100%;
    padding: 0;
  }
}
.accordion .row.accordion-item .content {
  max-height: 0px;
  overflow: hidden;
  transition: all 0.3s;
}
.accordion .row.accordion-item.open {
  cursor: auto;
}
.accordion .row.accordion-item.open:before {
  opacity: 0;
}
.accordion .row.accordion-item.open h2,
.accordion .row.accordion-item.open svg {
  cursor: pointer;
}
.accordion .row.accordion-item.open h2 strong {
  color: var(--grey);
}
.accordion .row.accordion-item.open svg {
  transform: rotate(90deg);
}
.accordion .row.accordion-item.open svg rect:first-of-type {
  opacity: 0;
}
.accordion .row.accordion-item.open img {
  padding-top: 30px;
  transition: all 0.5s;
  max-height: 500px;
}
@media (max-width: 991px) {
  .accordion .row.accordion-item.open img {
    height: 440px;
  }
}
@media (max-width: 767px) {
  .accordion .row.accordion-item.open img {
    height: 300px;
  }
}
.accordion .row.accordion-item.open .content {
  max-height: 2000px;
}
.accordion.support .row.accordion-item:before {
  background: var(--cepheidBlue);
}
/* purgecss start ignore */
.leadership-block_not-edit {
  margin: 30px auto;
}
.leadership-block_not-edit .leadership-block__parent_container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.leadership-block_not-edit .leadership-block__parent_container .leadership-block__item {
  cursor: pointer !important;
  margin: 20px 0px;
  width: 25%;
}
@media (max-width: 992px) {
  .leadership-block_not-edit .leadership-block__parent_container .leadership-block__item {
    width: 33.33%;
  }
}
@media (max-width: 625px) {
  .leadership-block_not-edit .leadership-block__parent_container .leadership-block__item {
    width: 100%;
  }
}
.leadership-block_not-edit .leadership-block__parent_container .leadership-block__item .leadership-block_image img {
  width: 160px;
  max-width: 100%;
  transition: opacity 0.3s;
}
@media (max-width: 625px) {
  .leadership-block_not-edit .leadership-block__parent_container .leadership-block__item .leadership-block_image img {
    width: 261px;
    height: auto;
  }
}
.leadership-block_not-edit .leadership-block__parent_container .leadership-block__item .leadership-block__titles {
  padding: 5px;
}
.leadership-block_not-edit .leadership-block__parent_container .leadership-block__item .leadership-block__titles h2 {
  font-size: 20px;
  line-height: 35px;
  margin: 0;
  padding: 0;
}
.leadership-block_not-edit .leadership-block__parent_container .leadership-block__item .leadership-block__titles h6 {
  font-weight: bold;
  font-size: 9px;
}
.leadership-block_not-edit .leadership-block__parent_container .leadership-block__item a {
  color: var(--grey);
}
.leadership-block_not-edit .leadership-block__parent_container .leadership-block__item a h6 {
  font-weight: bold;
  font-size: 10px !important;
}
.leadership-block_not-edit .leadership-block__parent_container .leadership-block__item .leadership-block__profile_content_not-edit {
  display: none;
}
@media (max-width: 625px) {
  .leadership-block_not-edit .leadership-block__parent_container .leadership-block__item .leadership-block__profile_content_not-edit p {
    font-size: 16px;
  }
}
.leadership-block_not-edit .leadership-block__parent_container .activate .leadership-block__titles {
  background-color: var(--cepheidBlue);
  width: 160px;
  margin: auto;
}
@media (max-width: 625px) {
  .leadership-block_not-edit .leadership-block__parent_container .activate .leadership-block__titles {
    width: 261px;
  }
}
.leadership-block_not-edit .leadership-block__parent_container .activate .leadership-block_image img {
  filter: unset !important;
}
.leadership-block_not-edit .leadership-block__parent_container .activate .leadership-block__yellow_arrow {
  position: relative;
  top: 25px;
  z-index: 1;
  background-color: white;
  padding: 0px 20px !important;
}
@media (max-width: 625px) {
  .leadership-block_not-edit .leadership-block__parent_container .activate .leadership-block__yellow_arrow {
    top: 15px;
    right: 35%;
  }
}
.leadership-block_not-edit .leadership-block__parent_container .activate .leadership-block__profile_content_not-edit {
  border: 0.845854px solid var(--cepheidBlue);
  box-shadow: 2px 2px 8px -3px var(--cepheidBlue);
  -webkit-box-shadow: 2px 2px 8px -3px var(--cepheidBlue);
  -moz-box-shadow: 2px 2px 8px -3px var(--cepheidBlue);
  height: auto;
  max-width: 1000px;
  width: auto;
  text-align: left;
  position: absolute;
  left: 0;
  margin: 18px 70px 20px 70px;
  padding: 0px calc((25% - 225px) / 2);
}
@media (max-width: 992px) {
  .leadership-block_not-edit .leadership-block__parent_container .activate .leadership-block__profile_content_not-edit {
    padding: 0px calc((33.33% - 225px) / 2);
  }
}
@media (max-width: 625px) {
  .leadership-block_not-edit .leadership-block__parent_container .activate .leadership-block__profile_content_not-edit {
    padding: 25px;
    margin: 5px 20px 20px 20px;
  }
  .leadership-block_not-edit .leadership-block__parent_container .activate .leadership-block__profile_content_not-edit p {
    padding: 0;
  }
}
.leadership-block_not-edit .leadership-block__parent_container .activate .leadership-block__profile_content_not-edit * {
  padding: 25px 0px;
  margin: 0;
}
.leadership-block_not-edit .leadership-block__parent_container .activate .leadership-block__profile_content_not-edit .leadership-block__close_container {
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.leadership-block_not-edit .leadership-block__parent_container .activate .leadership-block__profile_content_not-edit .leadership-block__close_container a {
  padding: 0;
}
.leadership-block_not-edit .leadership-block__parent_container .activate .leadership-block__profile_content_not-edit .leadership-block__close_container a img {
  padding-right: 10px;
  padding-top: 10px;
}
/* purgecss end ignore */

.blue-box {
  background: var(--blueGradient);
  padding: 20px 15px;
  text-align: center;
  color: white;
  height: 100%;
  min-width: 170px;
}
.blue-box h1 {
  margin-bottom: 20px;
}
.blue-box h1:after {
  content: "";
  height: 2px;
  width: 45px;
  background: var(--lightBlue);
  display: block;
  margin: 20px auto 0;
}
.blue-box h3 {
  margin-bottom: 0;
}
.cta-row.default .pimcore_tag_block {
  display: grid;
  grid-template-columns: repeat(auto-fill, 1fr);
  grid-auto-flow: column;
}
.cta-row.educational .pimcore_tag_block {
  width: 100%;
}
.educational-site {
  padding-top: 30px;
}
.educational-site + .educational-site {
  border-top: 1px solid var(--mediumGrey);
}
.educational-site img {
  max-width: 100%;
  height: auto;
}
.educational-site a.btn.event-btn {
  color: var(--cepheidBlue);
}
.educational-site a.btn.event-btn:before {
  background: var(--cepheidBlue);
}
@media (max-width: 767px) {
  .educational-site {
    text-align: center;
  }
  .educational-site h2 {
    line-height: 0;
  }
  .educational-site img {
    margin: auto;
  }
  .cta-row h2 {
    text-align: center;
  }
}


.country-map .map-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 67%;
}
.country-map .map-container .mapgl {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.country-map .map-container .mapboxgl-control-container,
.country-map .map-container .popups {
  display: none;
}

/**
 * Cta Cards Brick
 */
@media (min-width: 24px) {
  .cta-cards {
    padding: 30px 0px;
  }
  .cta-card {
    width: 100%;
    margin-top: 35px;
    margin-bottom: 30px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .cta-card__header {
    height: 75px;
    display: flex;
    align-items: center;
    padding-left: 17px;
  }
  .cta-card__image {
    padding-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cta-card__image img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  .cta-card-links__item {
    display: flex;
    align-items: center;
  }
  .cta-card-links__link {
    padding-left: 12px;
  }
  .cta-card-links__link a {
    text-decoration: underline;
    color: #414344;
  }
}
@media (min-width: 992px) {
  .cta-card {
    height: 340px;
  }
}
/* Editmode */
.cta-card-editmode {
  border: 1px dashed black;
  padding: 20px;
  margin-bottom: 20px;
}
.cta-card-editmode .cta-card__header {
  height: auto;
}
.cta-card-editmode .cta-card__image {
  height: 150px;
  width: 150px;
}

/* purgecss start ignore */
body .cta-section {
  background: var(--cepheidBlue) center / cover no-repeat;
  background-image: url(backgrounds/gradient.png);
  text-align: center;
}
body .cta-section h2,
body .cta-section .pimcore_editable a {
  color: white;
}
body .cta-section h2 {
  margin-bottom: 30px;
}
body .cta-section h2:only-child {
  margin: 0;
}
body .cta-section .reduce-bottom-margin {
  margin-bottom: 15px;
}
body .cta-section .contact-layout {
  text-align: left;
  margin-top: 60px;
}
body .cta-section .contact-layout > .row > div {
  display: flex;
  align-items: center;
}
body .cta-section .col-lg-4 {
  display: flex;
  align-items: center;
}
body .cta-section h4 {
  color: white;
  display: inline-block;
  margin: 0;
  font-size: var(--desktopFontSize);
  flex-grow: 1;
}
body .cta-section h4 a:not(.btn) {
  color: white;
  position: relative;
  background: linear-gradient(to right, white) no-repeat right;
  background-size: 0% auto;
  word-break: break-word;
}
body .cta-section h4 a:not(.btn):hover {
  color: var(--cepheidBlue);
}
body .cta-section p {
  font-size: 18px;
  line-height: 26px;
}
body .cta-section .icon {
  width: 50px;
  height: 50px;
  margin-right: 20px;
  vertical-align: unset;
  min-width: 50px;
}
body .cta-section a.cta-btn {
  color: var(--cepheidBlue);
  border: 1px solid white;
}
body .cta-section a.cta-btn:before {
  background: white;
}
body .cta-section a.cta-btn:hover {
  border-color: white;
  color: white;
}
body .cta-section .cta-container {
  max-width: 790px;
  padding: 0 30px;
  margin: auto;
  position: relative;
}
@media (max-width: 767px) {
  body .cta-section .cta-container {
    padding: 0 25px;
  }
  body .cta-section .cta-container .content {
    padding: 30px 25px;
  }
}
body .cta-section-color-blue {
  background: var(--blueGradient);
}
body .cta-section-color-alt-blue {
  background: var(--cepheidBlue) center / cover no-repeat;
}
body .cta-section-color-gray {
  background: var(--greyGradient);
}
body .cta-section-color-bg-grey {
  background: var(--mediumGrey);
}
body .cta-section-color-bg-light-grey {
  background: var(--lightGrey);
}
body .cta-section-color-green {
  background: var(--greenGradient);
}
body .cta-section-color-green-reversed {
  background: var(--greenGradientReverse);
}
body .cta-section-color-yellow {
  background: var(--yellowGradient);
}
body .cta-section-color-yellow .arrow-box svg.arrow-svg path {
  fill: var(--white);
}
body .cta-section-color-white {
  background: var(--white);
}
/* purgecss end ignore */

/* purgecss start ignore */
.featurette,
.video-cta {
  position: relative;
}
.featurette.brick-area,
.video-cta.brick-area {
  min-height: 480px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media (max-width: 767px) {
  .featurette.brick-area,
  .video-cta.brick-area {
    min-height: 240px;
  }
}
#product-resources .featurette.brick-area,
#product-resources .video-cta.brick-area {
  min-height: 0;
}
.featurette .container .row,
.video-cta .container .row {
  position: relative;
}
.featurette .container .row .gallery-content,
.video-cta .container .row .gallery-content {
  background: white;
  display: flex;
  flex-direction: column;
  padding: 45px;
}
.featurette .container .row .gallery-content ul,
.video-cta .container .row .gallery-content ul {
  padding-left: 20px;
}
.featurette .container .row .gallery-content h3:last-child,
.video-cta .container .row .gallery-content h3:last-child {
  margin: 0;
}
.featurette .container .row .gallery-content.text-shadow,
.video-cta .container .row .gallery-content.text-shadow {
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
}
.featurette .container .row .gallery-content > div > div,
.video-cta .container .row .gallery-content > div > div {
  padding-top: 15px !important;
}
.featurette .container .row .gallery-content.clear,
.video-cta .container .row .gallery-content.clear {
  background: none;
  padding: 0;
}
.featurette .container .row .gallery-content.clear + .image img,
.video-cta .container .row .gallery-content.clear + .image img {
  padding: 60px;
}
@media (max-width: 767px) {
  .featurette .container .row .gallery-content.clear + .image img,
  .video-cta .container .row .gallery-content.clear + .image img {
    padding: 0 0 60px;
  }
}
@media (max-width: 767px) {
  .featurette .container .row .gallery-content.clear + .image.order-lg-first img,
  .video-cta .container .row .gallery-content.clear + .image.order-lg-first img {
    padding: 0 0 0px;
  }
}
.featurette .container .row .gallery-content.clear:not(.dark-text),
.video-cta .container .row .gallery-content.clear:not(.dark-text) {
  color: white;
}
.featurette .container .row .gallery-content.clear:not(.dark-text) h1,
.video-cta .container .row .gallery-content.clear:not(.dark-text) h1,
.featurette .container .row .gallery-content.clear:not(.dark-text) h2,
.video-cta .container .row .gallery-content.clear:not(.dark-text) h2,
.featurette .container .row .gallery-content.clear:not(.dark-text) h3.medium,
.video-cta .container .row .gallery-content.clear:not(.dark-text) h3.medium {
  color: white;
}
.featurette .container .row .gallery-content.clear:not(.dark-text) a.btn,
.video-cta .container .row .gallery-content.clear:not(.dark-text) a.btn {
  text-shadow: none;
}
.featurette .container .row .gallery-content.clear:not(.dark-text) a.small-btn,
.video-cta .container .row .gallery-content.clear:not(.dark-text) a.small-btn {
  color: white;
}
@media (max-width: 767px) {
  .featurette .container .row .gallery-content,
  .video-cta .container .row .gallery-content {
    padding: 20px;
  }
  .featurette .container .row .gallery-content.clear,
  .video-cta .container .row .gallery-content.clear {
    padding: 20px 0;
  }
}
.featurette .container .row a > .image,
.video-cta .container .row a > .image {
  height: 100%;
}
.featurette .container .row .image > .pimcore_tag_image_empty,
.video-cta .container .row .image > .pimcore_tag_image_empty {
  opacity: 0.3;
}
.featurette .container .row .image > .pimcore_tag_image,
.video-cta .container .row .image > .pimcore_tag_image {
  height: 100% !important;
}
.featurette .container .row .image > .pimcore_tag_image img,
.video-cta .container .row .image > .pimcore_tag_image img {
  height: 100% !important;
  object-fit: cover;
}
.featurette .container .row .image .thumbnail,
.video-cta .container .row .image .thumbnail {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.featurette .container .row .image .thumbnail.contain,
.video-cta .container .row .image .thumbnail.contain {
  object-fit: contain;
}
@media (max-width: 991px) {
  .featurette .container .row .image .thumbnail,
  .video-cta .container .row .image .thumbnail {
    min-height: 0;
    height: auto;
    object-fit: unset;
  }
}
.featurette .math,
.video-cta .math {
  position: absolute;
  bottom: 15%;
  width: 100%;
  left: 0;
}
.featurette .math img,
.video-cta .math img {
  position: absolute;
  bottom: 0;
}
@media (max-width: 991px) {
  .featurette .math img,
  .video-cta .math img {
    height: 15vw;
  }
}
.featurette .math .left,
.video-cta .math .left {
  left: -20%;
}
.featurette .math .right,
.video-cta .math .right {
  right: -1%;
}
.featurette h3,
.video-cta.gallery h3 {
  margin: 0 0 15px;
}
.featurette .container .row,
.video-cta.gallery .container .row {
  margin-bottom: 0;
}
.featurette .container .row div[class*="col"],
.video-cta.gallery .container .row div[class*="col"] {
  margin-bottom: 0;
}
.featurette {
  width: 100%;
}
.featurette .pimcore_tag_block {
  width: 100%;
}
.featurette h1 {
  color: var(--cepheidBlue);
  margin-bottom: 15px;
}
@media (max-width: 991px) {
  .featurette .image.mobile-spacing {
    min-height: 50vw;
  }
}
.featurette .backgrounds,
.wysiwyg .backgrounds {
  z-index: -1;
}
.featurette .backgrounds,
.wysiwyg .backgrounds,
.featurette .backgrounds img,
.wysiwyg .backgrounds img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.featurette .backgrounds.contain img,
.wysiwyg .backgrounds.contain img {
  object-fit: contain;
  object-position: right bottom !important;
}
@media (min-width: 992px) {
  .featurette .backgrounds.object-left img,
  .wysiwyg .backgrounds.object-left img,
  .featurette .backgrounds img:only-child,
  .wysiwyg .backgrounds img:only-child {
    object-position: center;
  }
  .featurette .backgrounds.object-right img:not(:only-child),
  .wysiwyg .backgrounds.object-right img:not(:only-child) {
    object-position: center;
  }
}
@media (max-width: 991px) {
  .featurette .backgrounds .desktop-bg:not(:only-child),
  .wysiwyg .backgrounds .desktop-bg:not(:only-child) {
    display: none;
  }
  .featurette .backgrounds.object-left img,
  .wysiwyg .backgrounds.object-left img,
  .featurette .backgrounds img:only-child,
  .wysiwyg .backgrounds img:only-child {
    object-position: center bottom;
  }
  .featurette .backgrounds.object-right img:not(:only-child),
  .wysiwyg .backgrounds.object-right img:not(:only-child) {
    object-position: center bottom;
  }
}
@media (min-width: 992px) {
  .featurette .backgrounds .mobile-bg:not(:only-child),
  .wysiwyg .backgrounds .mobile-bg:not(:only-child) {
    display: none;
  }
}
/* purgecss end ignore */

.flexible-list .flexible-list__container {
  width: 730px;
  display: flex;
  text-align: center;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .flexible-list .flexible-list__container {
    width: unset;
  }
}
@media (max-width: 576px) {
  .flexible-list .flexible-list__container {
    margin-top: 50px;
  }
}
.flexible-list .flexible-list__container .flexible-list__container_row {
  flex-direction: column;
}
.flexible-list .flexible-list__col {
  text-align: center;
  position: relative;
  padding: 40px 30px;
  margin: 80px 0;
}
.flexible-list .flexible-list__col img {
  position: absolute;
  margin: 0 auto;
  top: -10%;
  transform: translate(-50%, -50%);
}
.flexible-list .flexible-list__horizontal-list {
  width: unset;
}
.flexible-list .flexible-list__horizontal-list .flexible-list__container_row {
  flex-direction: row;
}
.flexible-list .flexible-list__horizontal-list .flexible-list__col {
  margin: 110px 20px;
}
@media (max-width: 576px) {
  .flexible-list .flexible-list__horizontal-list .flexible-list__col {
    margin: 55px 20px;
  }
}
.flexible-list .flexible-list__horizontal-list .flexible-list__col img {
  top: -5%;
}

.iframe-container {
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
select.selectpicker {
  display: block!important;
}

/* purgecss start ignore */
.icons-list {
  background-size: cover;
}
.icons-list .icons-list__text {
  margin-top: 15px;
}
.icons-list h2 {
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
}
.icons-list h2 strong {
  font-size: unset;
  color: unset;
  line-height: unset;
}
.icons-list h2:after,
.icons-list h2:before {
  content: '';
  width: 150px;
  height: 2px;
  background: var(--white);
  margin: 0 45px;
}
@media (max-width: 500px) {
  .icons-list h2:after,
  .icons-list h2:before {
    margin: 0 25px;
  }
}
.icons-list .icons-list__container .row {
  text-align: center;
  align-items: flex-end;
  margin-top: 20px;
}
@media (max-width: 576px) {
  .icons-list .icons-list__container .row {
    align-items: flex-start;
  }
}
.icons-list .icons-list__container .row .icons-list__col {
  border-right: solid 1px var(--white);
}
@media (max-width: 576px) {
  .icons-list .icons-list__container .row .icons-list__col {
    border-right: none;
  }
}
.icons-list .icons-list__container .row .icons-list__col:last-child {
  border-right: none;
}
.icons-list .icons-list__flex-containers-parent {
  display: flex;
}
.icons-list .icons-list__flex-containers-parent .icons-list__remove-borders {
  border-right: none !important;
}
.icons-list .icons-list__flex-containers-parent .icons-list__flex-containers-child {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 80px;
}
@media (max-width: 770px) {
  .icons-list .icons-list__flex-containers-parent .icons-list__flex-containers-child {
    margin-bottom: 150px;
  }
}
@media (max-width: 576px) {
  .icons-list .icons-list__flex-containers-parent .icons-list__flex-containers-child {
    margin-bottom: 50px;
    justify-content: flex-start;
  }
}
.icons-list .icons-list__flex-containers-parent .icons-list__flex-containers-child img {
  padding-right: 15px;
  height: 78px;
}
.icons-list .icons-list__flex-containers {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  height: 75px;
  justify-content: start;
}
.icons-list .icons-list__flex-containers p {
  text-align: left;
  margin: 0;
}
/* purgecss end ignore */

.impact-story-banner {
  padding: 20px;
  color: white;
  background: #333;
  width: 400px;
  height: 500px;
  position: relative;
  max-width: calc(100vw - 50px);
  margin: auto;
  cursor: pointer;
}
@media (max-width: 767px) {
  .impact-story-banner {
    height: 450px;
  }
}
.impact-story-banner a,
.impact-story-banner h2 {
  color: white;
}
.impact-story-banner .content {
  position: relative;
}
.impact-story-banner img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
  pointer-events: none;
}
.impact-story-banner #pimcore_editable_image {
  border: 3px solid white;
}
.impact-stories .career .impact-story-banner h2 {
  font-size: 34px;
  margin: 25px 0;
}
.impact-stories .career .impact-story-banner a.btn {
  min-width: 150px;
  justify-content: center;
  font-size: var(--mobileFontSize);
}

.masthead:not(.svg) {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 400px;
}
@media (max-width: 767px) {
  .masthead:not(.svg) {
    min-height: 200px;
  }
}
.masthead {
  overflow: hidden;
  background: var(--cepheidBlue);
  position: relative;
}
.masthead .masthead-bg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
  top: 0;
}
.masthead div.svg {
  width: 100%;
  position: relative;
  z-index: 2;
}
.masthead div.svg img.masthead-bg {
  position: relative;
  opacity: 0;
}
.masthead div.svg img,
.masthead div.svg svg {
  width: 100%;
  height: 100%;
  opacity: 0;
}
.masthead div.svg svg {
  animation: 1s fadeIn forwards;
}
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
.masthead.adjusted .masthead-bg img {
  opacity: 1;
}
.masthead .icon {
  margin: 0 auto 30px;
  display: block;
}
.masthead h3:last-of-type {
  margin-bottom: 0;
}
.masthead > .container {
  position: relative;
  width: 100%;
  color: var(--white);
  text-align: center;
  z-index: 1;
  padding-top: 60px;
  padding-bottom: 60px;
  min-height: 400px;
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .masthead > .container {
    min-height: 200px;
    padding-top: 20px;
  }
  .masthead > .container.center-mobile {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.masthead > .container.padded {
  padding-top: 150px;
  padding-bottom: 150px;
}
@media (max-width: 767px) {
  .masthead > .container.padded {
    padding-top: 20px;
  }
}
.masthead > .container.darken {
  position: unset;
}
.masthead > .container.darken:before {
  content: "";
  background: var(--grey);
  opacity: 0.62;
  width: 100vw;
  height: 100%;
  position: absolute;
  z-index: -1;
  left: 50%;
  transform: translate(-50%);
  top: 0;
}
.masthead > .container > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.masthead > .container > div a.cta-btn:hover {
  color: white;
}
.masthead > .container .pimcore_tag_link_text a {
  color: white;
}
.masthead.svg > .container {
  display: none;
}
.masthead,
.featurette {
  overflow: hidden;
}
.masthead .featurette,
.featurette .featurette {
  overflow: visible;
}
.masthead .masthead-bg img,
.featurette .masthead-bg img {
  transform-origin: top;
}
.masthead .masthead-bg.position-left-top img,
.featurette .masthead-bg.position-left-top img {
  object-position: left top !important;
}
.masthead .masthead-bg.position-left-center img,
.featurette .masthead-bg.position-left-center img {
  object-position: left center !important;
}
.masthead .masthead-bg.position-left-bottom img,
.featurette .masthead-bg.position-left-bottom img {
  object-position: left bottom !important;
}
.masthead .masthead-bg.position-right-top img,
.featurette .masthead-bg.position-right-top img {
  object-position: right top !important;
}
.masthead .masthead-bg.position-right-center img,
.featurette .masthead-bg.position-right-center img {
  object-position: right center !important;
}
.masthead .masthead-bg.position-right-bottom img,
.featurette .masthead-bg.position-right-bottom img {
  object-position: right bottom !important;
}
.masthead .masthead-bg.position-center-top img,
.featurette .masthead-bg.position-center-top img {
  object-position: center top !important;
}
.masthead .masthead-bg.position-center-bottom img,
.featurette .masthead-bg.position-center-bottom img {
  object-position: center bottom !important;
}

.navigation-based-block .container {
  padding: 0 30px;
}
.navigation-based-block .column-container {
  padding-left: 0;
  padding-right: 0;
}
.navigation-based-block .column-container a {
  color: var(--grey);
}
.navigation-based-block .column-container .navigation-based-block-title {
  padding-top: 8px;
  padding-bottom: 8px;
  border-top: black solid 6px;
  margin-bottom: 10px;
  min-width: 200px;
}
.navigation-based-block .column-container:nth-of-type(1) .navigation-based-block-title {
  border-top-color: #8DC63F;
}
.navigation-based-block .column-container:nth-of-type(1) .navigation-based-block-title a {
  color: #8DC63F;
}
.navigation-based-block .column-container:nth-of-type(2) .navigation-based-block-title {
  border-top-color: #15BEF0;
}
.navigation-based-block .column-container:nth-of-type(2) .navigation-based-block-title a {
  color: #15BEF0;
}
.navigation-based-block .column-container:nth-of-type(3) .navigation-based-block-title {
  border-top-color: #FDB71A;
}
.navigation-based-block .column-container:nth-of-type(3) .navigation-based-block-title a {
  color: #FDB71A;
}
.navigation-based-block .column-container:nth-of-type(4) .navigation-based-block-title {
  border-top-color: #DF6020;
}
.navigation-based-block .column-container:nth-of-type(4) .navigation-based-block-title a {
  color: #DF6020;
}
.navigation-based-block .column-container:nth-of-type(5) .navigation-based-block-title {
  border-top-color: #7979B9;
}
.navigation-based-block .column-container:nth-of-type(5) .navigation-based-block-title a {
  color: #7979B9;
}
.navigation-based-block .column-container .navigation-based-block-item {
  padding: 4px 15px 4px 0;
}

.news-events .news-events-layout {
  display: grid;
  grid-auto-flow: dense;
  column-gap: 25px;
}
.news-events .news-events-layout > * {
  grid-column: 1/2;
}
@media (min-width: 992px) {
  .news-events .news-events-layout {
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
  }
  .news-events .news-events-layout h2:last-of-type,
  .news-events .news-events-layout h2:last-of-type ~ * {
    grid-column: 2/3;
  }
}
@media (max-width: 991px) {
  .news-events .news-events-layout > h2:last-of-type {
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .news-events .news-events-layout > h2:last-of-type {
    margin-top: 30px;
  }
}
.news-events .feed-items {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}
@media (max-width: 767px) {
  .news-events .feed-items {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 991px) {
  .news-events .feed-items {
    grid-gap: 25px;
  }
}
.news-events .feed-item {
  padding: 30px 20px;
  background: var(--lightGrey);
  display: flex;
  align-items: center;
  cursor: pointer;
}
.news-events .feed-item h4 {
  margin-bottom: 0;
}
.news-events .feed-item .date,
.news-events .feed-item .img {
  width: 120px;
  min-width: 120px;
  margin-right: 20px;
  text-align: center;
}
.news-events .feed-item .date img,
.news-events .feed-item .img img {
  max-width: 100%;
  object-fit: contain;
  height: auto;
}
.news-events .feed-item .date .day,
.news-events .feed-item .img .day {
  font: 300 42px/36px var(--defaultFonts);
}
@media (max-width: 991px) {
  .news-events .feed-item .date .day,
  .news-events .feed-item .img .day {
    font-size: 28px;
  }
}
#events-list-past .feed-item {
  cursor: unset;
}

.poc-sticky-nav-container nav.poc-sticky-nav {
  background: var(--cepheidBlue);
  position: sticky;
  top: 80px;
  z-index: 4;
}
@media (max-width: 767px) {
  .poc-sticky-nav-container nav.poc-sticky-nav {
    top: 70px;
  }
}
.poc-sticky-nav-container nav.poc-sticky-nav .poc-sticky-editmode-link a {
  color: #fff;
}
.poc-sticky-nav-container nav.poc-sticky-nav .row,
.poc-sticky-nav-container nav.poc-sticky-nav .col {
  margin-top: 0;
  margin-bottom: 0;
}
.poc-sticky-nav-container nav.poc-sticky-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  text-align: center;
  border-bottom: none;
}
@media (max-width: 767px) {
  .poc-sticky-nav-container nav.poc-sticky-nav ul {
    justify-content: space-between;
  }
}
@media (max-width: 480px) {
  .poc-sticky-nav-container nav.poc-sticky-nav ul {
    font-size: 10px;
    line-height: 12px;
    font-weight: bold;
  }
}
.poc-sticky-nav-container nav.poc-sticky-nav ul li {
  margin-right: 60px;
}
.poc-sticky-nav-container nav.poc-sticky-nav ul li:last-of-type {
  margin-right: 0;
}
@media (max-width: 991px) {
  .poc-sticky-nav-container nav.poc-sticky-nav ul li {
    margin-right: 30px;
  }
}
@media (max-width: 480px) {
  .poc-sticky-nav-container nav.poc-sticky-nav ul li {
    margin-right: 15px;
  }
}
.poc-sticky-nav-container nav.poc-sticky-nav ul li a {
  display: inline-block;
  color: white;
  padding: 30px 0;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border-bottom: 0px solid var(--yellow);
  transition: all 0.3s;
  margin: 0;
  overflow: visible;
}
@media (max-width: 480px) {
  .poc-sticky-nav-container nav.poc-sticky-nav ul li a {
    padding: 15px 0;
  }
}
.poc-sticky-nav-container nav.poc-sticky-nav ul li a.active {
  padding-bottom: 20px;
  background: none;
  color: white;
  border: none;
  border-bottom: 10px solid var(--yellow);
}
@media (max-width: 480px) {
  .poc-sticky-nav-container nav.poc-sticky-nav ul li a.active {
    padding-bottom: 10px;
    border-bottom: 5px solid var(--yellow);
  }
}

.preview-images .products div[class*="col"]:not(.btns),
.template-preview-images .products div[class*="col"]:not(.btns),
.preview-images .preview-images div[class*="col"]:not(.btns),
.template-preview-images .preview-images div[class*="col"]:not(.btns) {
  border-right: 1px solid var(--mediumGrey);
}
.preview-images .products div[class*="col"]:not(.btns):last-of-type,
.template-preview-images .products div[class*="col"]:not(.btns):last-of-type,
.preview-images .preview-images div[class*="col"]:not(.btns):last-of-type,
.template-preview-images .preview-images div[class*="col"]:not(.btns):last-of-type {
  border: none;
}
@media (min-width: 1074px) {
  .preview-images .products div[class*="col"]:not(.btns):nth-of-type(4n),
  .template-preview-images .products div[class*="col"]:not(.btns):nth-of-type(4n),
  .preview-images .preview-images div[class*="col"]:not(.btns):nth-of-type(4n),
  .template-preview-images .preview-images div[class*="col"]:not(.btns):nth-of-type(4n) {
    border: none;
  }
}
@media (max-width: 1073px) and (min-width: 813px) {
  .preview-images .products div[class*="col"]:not(.btns):nth-of-type(3n),
  .template-preview-images .products div[class*="col"]:not(.btns):nth-of-type(3n),
  .preview-images .preview-images div[class*="col"]:not(.btns):nth-of-type(3n),
  .template-preview-images .preview-images div[class*="col"]:not(.btns):nth-of-type(3n) {
    border: none;
  }
}
@media (max-width: 812px) and (min-width: 520px) {
  .preview-images .products div[class*="col"]:not(.btns):nth-of-type(2n),
  .template-preview-images .products div[class*="col"]:not(.btns):nth-of-type(2n),
  .preview-images .preview-images div[class*="col"]:not(.btns):nth-of-type(2n),
  .template-preview-images .preview-images div[class*="col"]:not(.btns):nth-of-type(2n) {
    border: none;
  }
}
@media (max-width: 519px) {
  .preview-images .products div[class*="col"]:not(.btns),
  .template-preview-images .products div[class*="col"]:not(.btns),
  .preview-images .preview-images div[class*="col"]:not(.btns),
  .template-preview-images .preview-images div[class*="col"]:not(.btns) {
    border: none;
  }
}
.preview-images .products div[class*="col"]:last-child .preview-item,
.template-preview-images .products div[class*="col"]:last-child .preview-item,
.preview-images .preview-images div[class*="col"]:last-child .preview-item,
.template-preview-images .preview-images div[class*="col"]:last-child .preview-item {
  border-bottom: none;
}
.preview-images .preview-item,
.template-preview-images .preview-item {
  display: block;
  width: 230px;
  min-height: 150px;
  text-align: center;
  color: var(--grey);
  position: relative;
  padding-bottom: 50px;
  margin: auto;
  transition: all 0.3s;
  background: white;
}
.preview-images .preview-item .pimcore_tag_image,
.template-preview-images .preview-item .pimcore_tag_image {
  z-index: 2;
}
@media (max-width: 767px) {
  .preview-images .preview-item,
  .template-preview-images .preview-item {
    padding-bottom: 0;
    margin-bottom: 50px;
  }
}
@media (max-width: 519px) {
  .preview-images .preview-item,
  .template-preview-images .preview-item {
    border-bottom: 1px solid var(--mediumGrey);
  }
}
.preview-images .preview-item strong,
.template-preview-images .preview-item strong {
  margin-bottom: 5px;
  display: block;
}
.preview-images .preview-item .image,
.template-preview-images .preview-item .image {
  position: relative;
}
.preview-images .preview-item img,
.template-preview-images .preview-item img {
  display: block;
  margin: auto;
  width: 100%;
  height: 208px;
  object-fit: contain;
  position: relative;
}
.preview-images .preview-item img.play,
.template-preview-images .preview-item img.play {
  content: "";
  position: absolute;
  width: 74px;
  height: 74px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (min-width: 768px) {
  .preview-images .preview-item .text,
  .template-preview-images .preview-item .text {
    position: absolute;
    bottom: 0;
    background: rgba(255, 255, 255, 0.65);
    width: 100%;
  }
}
.preview-images .preview-item .text p:after,
.template-preview-images .preview-item .text p:after {
  background: url("../icons/arrow-2.svg") no-repeat center;
}
.preview-images .col:only-child .preview-item,
.template-preview-images .col:only-child .preview-item {
  width: 100%;
}
.preview-images .col:only-child .preview-item img,
.template-preview-images .col:only-child .preview-item img {
  height: 350px;
}
.preview-images .carousel .preview-item,
.template-preview-images .carousel .preview-item {
  border-bottom: none;
}
.preview-images .large div[class*="col"]:not(.btns),
.template-preview-images .large div[class*="col"]:not(.btns) {
  border: none;
}
.preview-images .large .preview-item,
.template-preview-images .large .preview-item {
  width: 100%;
}
@media (min-width: 768px) {
  .preview-images .large .preview-item,
  .template-preview-images .large .preview-item {
    background: linear-gradient(to bottom, transparent, #eee);
  }
}
.preview-images .large .preview-item .text,
.template-preview-images .large .preview-item .text {
  padding: 20px;
  transition: all 0.3s;
  background: white;
}
.preview-images .large .preview-item:hover .text,
.template-preview-images .large .preview-item:hover .text {
  background: rgba(255, 255, 255, 0.65);
}
.preview-images .large .preview-item img,
.template-preview-images .large .preview-item img {
  height: 250px;
}
.preview-images .large .preview-item .software-badge,
.template-preview-images .large .preview-item .software-badge {
  width: 80px;
  height: auto;
  position: absolute;
  z-index: 10;
  bottom: 71px;
  right: 0px;
}
@media (max-width: 768px) {
  .preview-images .large .preview-item .software-badge,
  .template-preview-images .large .preview-item .software-badge {
    bottom: 65px;
  }
}
.product-row img {
  width: 185px;
  max-width: 100%;
  display: block;
  margin: auto;
}

/* purgecss start ignore */
.area-resources.template-default .col-md-auto .preview-item {
  max-width: 350px;
}
.area-resources.template-default .preview-item {
  width: 100%;
  max-width: 100%;
  padding: 20px;
  height: 250px;
  position: relative;
  display: flex;
  align-items: center;
  background: var(--lightGrey);
  transition: background 0.3s;
  text-align: center;
  color: var(--grey);
  margin-top: 50px;
  overflow: visible;
}
@media (max-width: 767px) {
  .area-resources.template-default .preview-item {
    height: auto;
    padding: 45px 30px 30px;
  }
}
.area-resources.template-default .preview-item:hover {
  background: var(--mediumGrey);
}
.area-resources.template-default .preview-item .text {
  width: 100%;
}
.area-resources.template-default .preview-item h4 {
  margin: 0;
}
.area-resources.template-default .preview-item h6 {
  margin: 0;
}
.area-resources.template-default .preview-item p + p {
  display: none;
}
.area-resources.template-default .preview-item > img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 80px;
  width: 80px;
  object-fit: contain;
}
@media (max-width: 767px) {
  .area-resources.template-default .preview-item > img {
    height: 70px;
    width: 70px;
  }
}
.area-resources.template-default.edit .preview-item:hover {
  background: var(--lightGrey);
}
.area-resources.template-default.white-theme {
  background: var(--lightGrey);
}
.area-resources.template-default.white-theme .preview-item {
  background: white;
}
.area-resources.template-default.white-theme .preview-item:hover {
  background: var(--mediumGrey);
}
.area-resources.template-default.white-theme.edit .preview-item:hover {
  background: white;
}
.area-resources.template-default.white-drop-shadow-theme {
  background: var(--lightGrey);
}
.area-resources.template-default.white-drop-shadow-theme .preview-item {
  background: white;
  align-items: flex-start;
  padding-top: 50px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
}
@media (min-width: 768px) {
  .area-resources.template-default.white-drop-shadow-theme:not(.edit) .preview-item:hover {
    background: #fff;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  }
  .area-resources.template-default.white-drop-shadow-theme:not(.edit) .preview-item:hover h6 {
    opacity: 1;
  }
}
.area-resources.template-default.white-drop-shadow-theme .preview-item p {
  max-height: 100px;
}
.area-resources.template-default.white-drop-shadow-theme .wysiwyg ul {
  margin: 5px 0;
}
.preview-item p {
  margin: 0;
  transition: all 0.3s;
  font-weight: 400;
  position: relative;
  overflow: hidden;
}
@media (min-width: 767px) {
  .preview-item p {
    max-height: 0;
  }
}
.preview-item p + span.arrow {
  content: "";
  height: 0px;
  background: url("../icons/arrow-3.svg") no-repeat center;
  opacity: 0;
  transition: all 0.3s;
  display: block;
  margin: auto;
  width: 100%;
}
#support-section .area-resources.template-default:not(.edit) .preview-item {
  align-items: flex-start;
  padding-top: 50px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
}
@media (min-width: 768px) {
  #support-section .area-resources.template-default:not(.edit) .preview-item:hover {
    background: #fff;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  }
}
#support-section .preview-item p {
  max-height: 100px;
}
#support-section .wysiwyg ul {
  margin: 5px 0;
}
@media (min-width: 768px) {
  .area-resources.template-default:not(.edit) .preview-item:hover {
    background: linear-gradient(to bottom, #fff 75%, #eee);
  }
  .area-resources.template-default:not(.edit) .preview-item:hover h6 {
    opacity: 0;
  }
  .area-resources.template-default:not(.edit) .preview-item:hover p {
    max-height: 100px;
  }
  .area-resources.template-default:not(.edit) .preview-item:hover p + span.arrow {
    opacity: 1;
    margin-top: 5px;
    height: 12px;
  }
}
.inserts {
  margin-top: 30px;
}
@media (min-width: 768px) {
  .impact-download {
    text-align: right;
  }
}
.impact-briefs h4:only-child {
  margin: 0;
}
.impact-briefs .description {
  max-height: 0px;
  overflow: hidden;
  transition: all 0.5s;
}
.impact-briefs .description.open {
  max-height: 500px;
}
.impact-briefs svg,
.impact-briefs rect {
  transition: all 0.5s;
}
@media (max-width: 767px) {
  .impact-briefs .impact-download a.btn {
    width: 100%;
    justify-content: center;
  }
}
.impact-briefs .read-more.open svg {
  transform: rotate(90deg);
}
.impact-briefs .read-more.open svg rect:first-of-type {
  opacity: 0;
}
.impact-briefs hr {
  margin: 0;
}
.template-object-cards {
  overflow: hidden;
  background: var(--cepheidBlue);
  position: relative;
  min-height: 70vh;
}
.template-object-cards .object-cards-bg img {
  transform-origin: top;
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s ease-in;
  top: 0;
}
.template-object-cards .object-cards-bg.position-left-top img {
  object-position: left top !important;
}
.template-object-cards .object-cards-bg.position-left-center img {
  object-position: left center !important;
}
.template-object-cards .object-cards-bg.position-left-bottom img {
  object-position: left bottom !important;
}
.template-object-cards .object-cards-bg.position-right-top img {
  object-position: right top !important;
}
.template-object-cards .object-cards-bg.position-right-center img {
  object-position: right center !important;
}
.template-object-cards .object-cards-bg.position-right-bottom img {
  object-position: right bottom !important;
}
.template-object-cards .object-cards-bg.position-center-top img {
  object-position: center top !important;
}
.template-object-cards .object-cards-bg.position-center-bottom img {
  object-position: center bottom !important;
}
.template-object-cards.adjusted .object-cards-bg img {
  opacity: 1;
}
@media (max-width: 1199px) {
  .template-object-cards .object-cards > .row {
    justify-content: center;
  }
}
.template-object-cards .object-cards .col {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 8px;
  padding: 32px;
  border-radius: 8px;
  background-color: #fff;
  margin-right: 15px;
  margin-left: 15px;
  box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  transition: all 0.25s;
  max-width: 350px;
  min-width: 350px;
}
.template-object-cards .object-cards .col:hover {
  box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.3);
}
@media (max-width: 767px) {
  .template-object-cards .object-cards .col {
    margin-bottom: 30px;
  }
}
.template-object-cards .object-cards .col > a {
  color: var(--grey);
  text-decoration: none;
  font-size: 1rem;
}
.template-object-cards .object-cards .col > a.preview-item p.clamped-6 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
}
@media (min-width: 767px) {
  .template-object-cards .object-cards .col > a.preview-item p.clamped-6 {
    max-height: none;
  }
}
.template-object-cards .object-cards .col > a::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 49.99%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
}
.template-object-cards .object-cards .col > a.is-new::before {
  background: url("../icons/new-product.svg") no-repeat;
  background-position: -130px -123px;
  background-size: auto;
}
.template-object-cards .object-cards .col > a span.learn-more {
  display: inline-block;
  border-bottom: 1px solid transparent;
  color: var(--cepheidBlue);
  font-weight: 600;
  text-decoration: none;
}
.template-object-cards .object-cards .col > a span.learn-more:hover {
  border-bottom-color: var(--cepheidBlue);
  color: var(--cepheidBlue);
}
.template-object-cards .object-cards .col.two-col-layout {
  flex: 1 1 45%;
}
@media (min-width: 1200px) {
  .template-object-cards .object-cards .col.two-col-layout {
    max-width: 540px;
    min-width: 540px;
  }
}
.area-resources .simple-list > hr {
  margin-top: 50px;
}
.area-resources .simple-list .simple-list__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.area-resources .simple-list .simple-list__container .simple-list__text {
  width: 70%;
}
.area-resources .simple-list .simple-list__container .simple-list__text h3 {
  margin-bottom: 0;
}
.area-resources .simple-list .simple-list__container .simple-list__download {
  width: 20%;
}
@media (max-width: 1200px) {
  .area-resources .simple-list .simple-list__container .simple-list__text {
    width: 60%;
  }
  .area-resources .simple-list .simple-list__container .simple-list__download {
    width: 30%;
  }
}
@media (max-width: 770px) {
  .area-resources .simple-list .simple-list__container .simple-list__text {
    width: 90%;
  }
  .area-resources .simple-list .simple-list__container .simple-list__download {
    width: unset;
  }
}
/* purgecss end ignore */

.video-cta .videos a.video-cta {
  color: white;
  display: block;
  padding-bottom: 10px;
  width: 100%;
  margin: auto;
}
.video-cta .videos a.video-cta .title {
  height: 90px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}
.video-cta .videos a.video-cta .title h3 {
  margin-bottom: 0;
}
.video-cta .videos a.video-cta .image {
  position: relative;
  width: 100%;
}
.video-cta .videos a.video-cta .image .thumbnail {
  width: 100%;
  height: 200px;
  object-fit: cover;
  min-height: 0;
}
.video-cta .videos a.video-cta:hover .image .thumbnail {
  transform: scale(1.1);
}
.video-cta .videos .image {
  overflow: hidden;
}
.video-cta .videos .image .play {
  position: absolute;
  width: 100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 767px) {
  .video-cta .videos .image .play {
    width: 70px;
  }
}
.video-cta .videos .image .thumbnail {
  transition: all 0.3s;
}
.video-cta .videos .image .thumbnail:hover {
  transform: scale(1.1);
}
.video-cta .videos div[class*="col"]:nth-child(4n + 1) a.video-cta {
  background: linear-gradient(180deg, var(--cepheidBlue) 10.42%, var(--lightBlue) 100%);
}
.video-cta .videos div[class*="col"]:nth-child(4n + 2) a.video-cta {
  background: linear-gradient(180deg, var(--green) 0%, #68883d 100%);
}
.video-cta .videos div[class*="col"]:nth-child(4n + 3) a.video-cta {
  background: linear-gradient(180deg, var(--grey) 10.42%, #c4c4c4 100%);
}
.video-cta .videos div[class*="col"]:nth-child(4n + 4) a.video-cta {
  background: var(--blueGradient);
}
.video-cta.single-cta img {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
}

.snippet-brick iframe {
  width: 100%;
}

.area-social-links {
  margin: 30px 0;
  display: inline-grid;
  grid-gap: 30px;
  grid-auto-flow: column;
}
.area-social-links a {
  position: relative;
}
.area-social-links a:before,
.area-social-links a:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  width: 0;
  height: 0;
  border: 0px solid var(--white);
}
.area-social-links a:before {
  transition-delay: 0.075s;
}
.area-social-links a img {
  display: block;
  height: 15px;
}
.area-social-links a:hover:before {
  width: 50px;
  height: 50px;
  border: 1px solid white;
}
.area-social-links a:hover:after {
  width: 40px;
  height: 40px;
  border: 2px solid white;
}
@media (max-width: 767px) {
  .area-social-links {
    margin: 25px 0;
  }
}

.support-map .map-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 67%;
}
.support-map .map-container .mapgl {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.support-map .map-container .mapboxgl-control-container,
.support-map .map-container .popups {
  display: none;
}

/* purgecss start ignore */
.custom-table .custom-table__header-container {
  margin-top: 15px;
}
.custom-table .custom-table__header {
  color: var(--white);
  padding: 5px;
}
.custom-table .custom-table__header.grey {
  background-color: var(--grey);
}
.custom-table .custom-table__header.blue {
  background-color: var(--cepheidBlue);
}
.custom-table .custom-table__header.green {
  background-color: var(--green);
}
.custom-table .custom-table__container {
  display: grid;
  grid-template-columns: 1fr 4fr;
}
.custom-table .custom-table__container .custom-table__content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 50px;
}
@media (max-width: 992px) {
  .custom-table .custom-table__container .custom-table__content {
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
}
.custom-table .custom-table__container .custom-table__content .custom-table__content-area {
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-gap: 15px;
  position: relative;
  top: 30%;
}
.custom-table .custom-table__container .custom-table__content .custom-table__content-area p {
  margin: 0;
}
@media (max-width: 992px) {
  .custom-table .custom-table__container .custom-table__content .custom-table__content-area {
    grid-template-columns: 1fr;
    top: 10%;
  }
}
/* purgecss end ignore */

.three-icons {
  text-align: center;
}
.three-icons img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.three-icons .pimcore_tag_image,
.three-icons img {
  display: block;
  margin: 0 auto 15px;
}
.three-icons h4 {
  font-size: 18px;
  line-height: 22px;
}

/**
 * Horizontal timeline
 */
.timeline {
  background: url("backgrounds/timeline-bg.png");
  background-size: cover;
  position: sticky;
  top: 80px;
  color: white;
  overflow: hidden;
  pointer-events: none;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
@media (max-width: 991px) {
  .timeline {
    top: 70px;
    height: calc(100vh - 70px);
  }
}
@media (max-width: 767px) {
  .timeline {
    top: 64px;
    height: calc(100vh - 64px);
  }
}
.timeline .disclaimer .sml-txt {
  color: white;
}
.timeline .disclaimer .sml-txt p {
  margin: 0;
}
.pimcore_editable .timeline,
.timeline.locked {
  pointer-events: auto;
}
.timeline component {
  display: none;
}
.timeline .prog-bar {
  content: "";
  display: block;
  height: 8px;
  background: var(--yellow);
  position: absolute;
  top: 0;
  left: 0;
}
.timeline .stopwatch {
  position: absolute;
  bottom: -50px;
  left: 0;
}
@media (max-width: 767px) {
  .timeline .stopwatch {
    left: -50px;
  }
}
.timeline .stopwatch svg {
  height: 600px;
}
@media (max-width: 767px) {
  .timeline .stopwatch svg {
    height: 50vh;
    width: 40vh;
  }
}
.timeline .stopwatch svg path {
  transform-origin: 251px 358px;
}
.timeline .stopwatch svg path.minutes {
  transform: rotate(calc((var(--progress) / 100) * 2160deg));
}
.timeline .stopwatch svg path.hours {
  transform: rotate(calc((var(--progress) / 100) * 180deg + 60deg));
}
.timeline h2 {
  text-align: left;
  color: white;
}
.timeline h2,
.timeline h3 {
  transition: all 0.4s;
  opacity: 0;
}
.timeline h2.visible,
.timeline h3.visible {
  opacity: 1;
}
.timeline .items {
  overflow: hidden;
  margin-top: 30px;
  width: 100%;
  flex-grow: 1;
}
.timeline .items .container > .row {
  margin-left: -30px;
  margin-right: -30px;
}
.timeline .items .col,
.timeline .items .col > .pimcore_editable {
  display: grid;
  grid-template-columns: 255px;
  grid-auto-flow: column;
  grid-gap: 0;
  width: 1170px;
  max-width: 100vw;
  padding: 0 30px;
}
.timeline .items .item {
  --speed: 0.4s;
  width: 255px;
  overflow: hidden;
  padding-right: 30px;
}
@media (max-width: 767px) {
  .timeline .items .item {
    --speed: 0.2s;
  }
}
.timeline .items .item.arrow {
  overflow: visible;
}
.timeline .items .item h4 {
  color: var(--yellow);
  margin-top: 5px;
  position: relative;
  top: -5px;
  opacity: 0;
  transition: all var(--speed);
}
.timeline .items .item div[class*="-svg"].arrow-svg {
  opacity: 0;
  position: relative;
  left: -30px;
  transition: all var(--speed);
}
.timeline .items .item div[class*="-svg"].arrow-svg svg {
  height: 36px;
}
.timeline .items .item div[class*="-svg"].timeline-svg line {
  stroke-dasharray: 221px;
  stroke-dashoffset: 221px;
  transition: all var(--speed) calc(var(--speed) + 0.1s);
}
.timeline .items .item div[class*="-svg"].timeline-svg .outer {
  stroke-dasharray: 104px;
  stroke-dashoffset: 104px;
  transition: all var(--speed);
  transform-origin: 17.5px 17.5px;
  transform: rotateY(180deg);
}
.timeline .items .item div[class*="-svg"].timeline-svg .inner {
  transform: scale(0);
  transform-origin: 17.5px 17.5px;
  transition: all var(--speed) calc(var(--speed) / 3) cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.timeline .items .item .content {
  font: 16px/24px var(--defaultFonts);
  letter-spacing: -0.5px;
  opacity: 0;
  position: relative;
  top: 30px;
  transition: all var(--speed) calc(var(--speed) / 2);
}
.timeline .items .item .content img {
  display: block;
  max-width: 100%;
}
@media (max-height: 800px) {
  .timeline .items .item .content img {
    display: none;
  }
}
.timeline .items .item .content a {
  color: white;
  text-decoration: underline;
}
.timeline .items .item .content strong.header {
  letter-spacing: -0.5px;
  margin: 15px 0;
  display: block;
}
.timeline .items .item.visible h4,
.pimcore_editable .timeline .items .item h4 {
  opacity: 1;
  top: 0;
}
.timeline .items .item.visible .content,
.pimcore_editable .timeline .items .item .content {
  top: 0;
  opacity: 1;
}
.timeline .items .item.visible div[class*="-svg"].arrow-svg,
.pimcore_editable .timeline .items .item div[class*="-svg"].arrow-svg {
  left: -1px;
  opacity: 1;
}
.timeline .items .item.visible div[class*="-svg"].timeline-svg line,
.pimcore_editable .timeline .items .item div[class*="-svg"].timeline-svg line {
  stroke-dashoffset: 0px;
}
.timeline .items .item.visible div[class*="-svg"].timeline-svg .outer.bottom,
.pimcore_editable .timeline .items .item div[class*="-svg"].timeline-svg .outer.bottom {
  stroke-dashoffset: 52px;
}
.timeline .items .item.visible div[class*="-svg"].timeline-svg .outer.top,
.pimcore_editable .timeline .items .item div[class*="-svg"].timeline-svg .outer.top {
  stroke-dashoffset: 156px;
}
.timeline .items .item.visible div[class*="-svg"].timeline-svg .inner,
.pimcore_editable .timeline .items .item div[class*="-svg"].timeline-svg .inner {
  transform: scale(1);
}
/**
 * Vertical Timeline
 */
.vertical-timeline-container {
  background: linear-gradient(274.54deg, #0079C1 41.98%, #15BBED 135.54%);
  padding: 50px 0px 120px 0px;
  /**
     * Header
     */
  /**
     * Content
     */
}
.vertical-timeline-container .header-container h2 {
  color: #ffffff;
  margin-bottom: 75px;
}
.vertical-timeline-container .timeline-items-container {
  position: relative;
  z-index: 0;
}
.vertical-timeline-container .timeline-items-container:before {
  content: '';
  width: 2px;
  height: calc(100% - 35px);
  background-color: #BEC0BD;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%);
  z-index: -1;
}
.vertical-timeline-container .timeline-items-container:after {
  content: url("../icons/timeline-bottom-arrow.svg");
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 992px) {
  .vertical-timeline-container .timeline-items-container:after {
    left: 32px;
  }
}
@media (max-width: 767px) {
  .vertical-timeline-container .timeline-items-container:after {
    left: 32px;
  }
}
.vertical-timeline-container .timeline-items-container .scrolling-vertical-line {
  width: 8px;
  height: 0px;
  background-color: #7ECCF0;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%);
  z-index: -1;
  transition: height 0.6s;
}
@media (max-width: 992px) {
  .vertical-timeline-container .timeline-items-container {
    padding-top: 10px;
  }
  .vertical-timeline-container .timeline-items-container:before {
    content: '';
    width: 2px;
    height: calc(100% - 35px);
    background-color: #BEC0BD;
    position: absolute;
    top: 10px;
    left: 32px;
    transform: translate(-50%);
    z-index: -1;
  }
  .vertical-timeline-container .timeline-items-container .scrolling-vertical-line {
    width: 8px;
    height: 0px;
    background-color: #7ECCF0;
    position: absolute;
    top: 10px;
    left: 32px;
    transform: translate(-50%);
    z-index: -1;
    transition: height 0.6s;
  }
}
.vertical-timeline-container .timeline-items-container .timeline-item {
  display: flex;
  /**
             * Timeline editmode styles
             */
}
.vertical-timeline-container .timeline-items-container .timeline-item.item-editmode .item-col {
  flex-direction: column !important;
}
.vertical-timeline-container .timeline-items-container .timeline-item.item-editmode .item-col .item-media-container {
  position: static !important;
}
.vertical-timeline-container .timeline-items-container .timeline-item .item-col {
  display: flex;
  flex-basis: 50%;
  margin-bottom: 30px;
  position: relative;
}
.vertical-timeline-container .timeline-items-container .timeline-item .item-col .item-media-container {
  position: absolute;
  bottom: 10px;
}
@media (max-width: 992px) {
  .vertical-timeline-container .timeline-items-container .timeline-item .item-col .item-media-container {
    position: static;
  }
}
.vertical-timeline-container .timeline-items-container .timeline-item .item-col .item-media-container .item-image-container img {
  max-height: 200px;
}
.vertical-timeline-container .timeline-items-container .timeline-item .item-col .item-media-container .item-video-container .preview-item {
  display: block;
  width: 250px;
  min-height: 150px;
  position: relative;
}
.vertical-timeline-container .timeline-items-container .timeline-item .item-col .item-media-container .item-video-container .preview-item .pimcore_tag_image {
  z-index: 2;
}
.vertical-timeline-container .timeline-items-container .timeline-item .item-col .item-media-container .item-video-container .preview-item .image {
  position: relative;
}
.vertical-timeline-container .timeline-items-container .timeline-item .item-col .item-media-container .item-video-container .preview-item img {
  display: block;
  margin: auto;
  width: 100%;
  height: 208px;
  object-fit: contain;
  position: relative;
}
.vertical-timeline-container .timeline-items-container .timeline-item .item-col .item-media-container .item-video-container .preview-item img.play {
  position: absolute;
  width: 74px;
  height: 74px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 992px) {
  .vertical-timeline-container .timeline-items-container .timeline-item .item-col .item-media-container .item-video-container .preview-item {
    margin-left: 20px;
  }
}
@media (max-width: 767px) {
  .vertical-timeline-container .timeline-items-container .timeline-item .item-col .item-media-container .item-video-container .preview-item {
    width: 150px;
  }
  .vertical-timeline-container .timeline-items-container .timeline-item .item-col .item-media-container .item-video-container .preview-item img.play {
    width: 50px;
    height: 50px;
  }
}
.vertical-timeline-container .timeline-items-container .timeline-item .item-col .item-text-container {
  width: 100%;
}
.vertical-timeline-container .timeline-items-container .timeline-item .item-col .item-text-container .year-container {
  position: relative;
}
.vertical-timeline-container .timeline-items-container .timeline-item .item-col .item-text-container .year-container h2 {
  color: #FDB71A;
}
.vertical-timeline-container .timeline-items-container .timeline-item .item-col .item-text-container .year-container .timeline-indicator {
  position: absolute;
  top: 5px;
  background: #3480be;
  border-radius: 50%;
}
.vertical-timeline-container .timeline-items-container .timeline-item .item-col .item-text-container .body-container {
  color: #ffffff;
}
.vertical-timeline-container .timeline-items-container .timeline-item .item-col.item-col-left {
  padding-right: 48px;
  text-align: right;
  justify-content: flex-end;
}
.vertical-timeline-container .timeline-items-container .timeline-item .item-col.item-col-left .item-media-container {
  left: 0;
}
.vertical-timeline-container .timeline-items-container .timeline-item .item-col.item-col-left .item-text-container .year-container .timeline-indicator {
  right: -65px;
}
.vertical-timeline-container .timeline-items-container .timeline-item .item-col.item-col-right {
  padding-left: 48px;
  justify-content: flex-start;
}
.vertical-timeline-container .timeline-items-container .timeline-item .item-col.item-col-right .item-media-container {
  right: 0;
}
.vertical-timeline-container .timeline-items-container .timeline-item .item-col.item-col-right .item-text-container .year-container .timeline-indicator {
  left: -65px;
}
@media (max-width: 992px) {
  .vertical-timeline-container .timeline-items-container .timeline-item {
    justify-content: flex-start;
  }
  .vertical-timeline-container .timeline-items-container .timeline-item .hide-mobile {
    display: none;
  }
  .vertical-timeline-container .timeline-items-container .timeline-item .item-col {
    flex-basis: 100%;
  }
  .vertical-timeline-container .timeline-items-container .timeline-item .item-col.item-col-left,
  .vertical-timeline-container .timeline-items-container .timeline-item .item-col.item-col-right {
    padding-left: 50px;
    padding-right: 0px;
    text-align: left;
    justify-content: flex-start;
  }
  .vertical-timeline-container .timeline-items-container .timeline-item .item-col.item-col-left .item-text-container .year-container .timeline-indicator,
  .vertical-timeline-container .timeline-items-container .timeline-item .item-col.item-col-right .item-text-container .year-container .timeline-indicator {
    left: -65px;
    right: unset;
    top: -2px;
  }
}
@media (max-width: 767px) {
  .vertical-timeline-container .timeline-items-container .timeline-item .item-col.item-col-left .item-media-container .item-image-container img,
  .vertical-timeline-container .timeline-items-container .timeline-item .item-col.item-col-right .item-media-container .item-image-container img {
    max-width: 130px;
  }
  .vertical-timeline-container .timeline-items-container .timeline-item .item-col.item-col-left .item-text-container .year-container .timeline-indicator,
  .vertical-timeline-container .timeline-items-container .timeline-item .item-col.item-col-right .item-text-container .year-container .timeline-indicator {
    left: -50px;
  }
}
/**
 * Vertical Timeline Tests/Systems
 */
.timeline-tests-systems-container {
  background: linear-gradient(274.54deg, #0079C1 41.98%, #15BBED 135.54%);
  padding: 50px 0px 120px 0px;
}
.timeline-tests-systems-container .timeline-header h2 {
  color: #ffffff;
  margin-bottom: 50px;
}
.timeline-tests-systems-container .timeline-toggles {
  text-align: center;
  margin-bottom: 75px;
}
@media (max-width: 992px) {
  .timeline-tests-systems-container .timeline-toggles {
    margin-bottom: 20px;
  }
}
.timeline-tests-systems-container .timeline-toggles #systems-timeline-toggle {
  margin-right: 10px;
}
.timeline-tests-systems-container .timeline-toggles #tests-timeline-toggle {
  margin-left: 10px;
}
.timeline-tests-systems-container .timeline-toggles .btn.active {
  background-color: white;
  color: var(--cepheidBlue);
}

.wysiwyg {
  position: relative;
}
.wysiwyg p,
.wysiwyg ol,
.wysiwyg ul {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.wysiwyg.footnotes {
  background: rgba(75, 78, 80, 0.95);
  color: white;
  font: 300 14px/17px var(--defaultFonts);
  letter-spacing: -0.05px;
}
.wysiwyg.footnotes p {
  margin: 5px 0;
}
.wysiwyg.footnotes p:first-of-type {
  margin-top: 0;
}
@media (max-width: 767px) {
  .wysiwyg.footnotes {
    font-size: 10px;
    line-height: 17px;
  }
}
.wysiwyg.graph .container {
  max-width: 740px;
}
.wysiwyg.graph .container .row {
  padding: 30px 15px 0;
  margin: 0;
  border: 1px solid var(--mediumGrey);
}
.wysiwyg.graph h3 {
  color: var(--cepheidBlue);
}
.wysiwyg img,
.wysiwyg svg {
  max-width: 100%;
  height: auto;
  margin: auto;
  display: inherit;
  width: auto;
}
.wysiwyg .flex p {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.wysiwyg .flex p.no-wrap {
  flex-wrap: nowrap;
}
.wysiwyg .flex p img,
.wysiwyg .flex p svg {
  margin: 15px;
}
.wysiwyg h3 {
  margin-bottom: 15px;
}
.wysiwyg h3:last-child {
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .wysiwyg .image img {
    width: 100%;
  }
}
.wysiwyg section.video-brick {
  padding: 0;
}
.wysiwyg .quote .content {
  padding: 30px;
}
.wysiwyg h5:last-child,
.wysiwyg p:last-child {
  margin-bottom: 0;
}

:root {
  --grey: #4c4d4f;
  --lightGrey: #ecedec;
  --mediumGrey: #bec0bd;
  --cepheidBlue: #007ac2;
  --darkBlue: #007ac2;
  --lightBlue: #7eccf0;
  --green: #77b800;
  --yellow: #fdb71a;
  --white: #ffffff;
  --errorRed: #e81818;
  --yellowGradient: linear-gradient(#fdb71a, #f7941d);
  --greenGradient: linear-gradient(#77b800, #74a333);
  --greenGradientReverse: linear-gradient(180deg, #68883d 0%, #8ab64e 76.56%, #8ab64e 100%);
  --blueGradient: linear-gradient(#6cbced 7.81%, #007ac2 85.42%);
  --grayGradient: linear-gradient(180deg, #bcbebb 0%, #ebebe9 100%);
  --defaultFonts: "Helvetica Neue", Arial, "Lucida Grande", sans-serif;
  --desktopFontSize: 18px;
  --desktopLineHeight: 26px;
  --mobileFontSize: 15px;
  --mobileLineHeight: 20px;
  --sectionSpacing: 60px;
  --sectionSpacingMobile: 30px;
}
body {
  font-size: var(--desktopFontSize);
  line-height: var(--desktopLineHeight);
  font-family: var(--defaultFonts);
  color: var(--grey);
}
body #ccc,
body #ccc button {
  font-family: var(--defaultFonts) !important;
}
body #ccc svg,
body #ccc button svg {
  vertical-align: top;
}
body svg text {
  font-family: var(--defaultFonts);
}
body .light {
  font-weight: 300;
}
@media (max-width: 767px) {
  body .light {
    font-weight: 400;
  }
}
@media (max-width: 767px) {
  body .desktop-only {
    display: none;
  }
}
@media (min-width: 768px) {
  body .mobile-only {
    display: none;
  }
}
body.mobileOpen {
  overflow: hidden;
}
body hr {
  border-color: var(--mediumGrey);
  margin: 30px 0;
}
body main {
  min-height: calc(100vh - 410px);
}
body strong {
  font-weight: 600;
}
body p {
  margin: 15px 0;
}
body p:first-child {
  margin-top: 0;
}
body p:last-child {
  margin-bottom: 0;
}
body .wysiwyg table,
body .product-sections table {
  width: 100%;
  border: none;
  padding: 15px 0;
}
@media (max-width: 767px) {
  body .wysiwyg table,
  body .product-sections table {
    padding: 15px 25px;
  }
}
body .wysiwyg table thead,
body .product-sections table thead {
  background: var(--lightGrey);
  color: var(--cepheidBlue);
  border-bottom: 2px solid var(--mediumGrey);
}
body .wysiwyg table thead th,
body .product-sections table thead th {
  border: none;
  font-weight: 500;
  padding: 20px;
}
body .wysiwyg table tr td,
body .product-sections table tr td {
  border: none;
  border-bottom: 1px solid var(--mediumGrey);
  padding: 20px;
}
body .wysiwyg table.no-border,
body .product-sections table.no-border {
  padding: 0;
}
body .wysiwyg table.no-border tr td,
body .product-sections table.no-border tr td {
  border: none;
  padding: 0;
}
body .wysiwyg table.no-border tr td img,
body .product-sections table.no-border tr td img {
  margin: 15px 15px 15px 0;
  min-width: 85px;
  min-height: 85px;
}
body .bg-light-grey {
  background: var(--lightGrey);
}
body .bg-grey {
  background: var(--mediumGrey);
}
body .blue {
  background: var(--blueGradient);
}
body .white {
  background: var(--white);
}
body .alt-blue {
  background: var(--cepheidBlue) center / cover no-repeat url("backgrounds/gradient.png");
}
body .white {
  background: var(--white);
}
body .white-txt {
  color: white !important;
}
body .white-txt h2,
body .white-txt h3 {
  color: white;
}
body .white-txt span.breadcrumbs a,
body .white-txt span.breadcrumbs a:not([class]) {
  color: white;
}
body .green {
  background: var(--greenGradient);
}
body .green-reversed {
  background: var(--greenGradientReverse);
}
body .enlarged-images img {
  max-width: none;
}
@media (max-width: 767px) {
  body .enlarged-images img {
    max-width: 100%;
  }
}
body .deep-blue-txt {
  color: #196fb9;
}
body .deep-blue .large-margins {
  border-top: 20px #196fb9 solid;
  padding-top: 15px;
}
body .lit-blue-txt {
  color: #00BDF2;
}
body .lit-blue .large-margins {
  border-top: 20px #00BDF2 solid;
  padding-top: 15px;
}
body .deep-green-txt {
  color: #73A428;
}
body .deep-green .large-margins {
  border-top: 20px #73A428 solid;
  padding-top: 15px;
}
body .golden-txt {
  color: #FEB600 !important;
}
body .yellow-txt {
  color: #fdb71a !important;
}
body .golden .large-margins {
  border-top: 20px #FEB600 solid;
  padding-top: 15px;
}
body .orange-txt {
  color: #E16406;
}
body .orange .large-margins {
  border-top: 20px #E16406 solid;
  padding-top: 15px;
}
body .purple-txt {
  color: #6F5890;
}
body .purple .large-margins {
  border-top: 20px #6F5890 solid;
  padding-top: 15px;
}
body .gray:not(.area-resources) {
  background: var(--grayGradient);
}
body .yellow {
  background: var(--yellowGradient);
}
body .blue-txt {
  color: var(--cepheidBlue) !important;
}
body .blue-txt span.breadcrumbs a,
body .blue-txt span.breadcrumbs a:not([class]) {
  color: var(--cepheidBlue) !important;
}
body .grey-txt {
  color: var(--grey) !important;
}
body .grey-txt span.breadcrumbs a,
body .grey-txt span.breadcrumbs a:not([class]) {
  color: var(--grey) !important;
}
body .wysiwyg a:not(.btn):not(.small-btn),
body .gallery-content a:not(.btn):not(.small-btn):not(.x-btn),
body .news-item a,
body .form a:not(.dropdown-item),
body #product-information a:not(.btn):not(.small-btn),
body .contact-list a:not(.btn),
body .cta-section a:not(.btn):not(.small-btn),
body .blue-slide-link a:not(.btn):not(.small-btn),
body a.white {
  color: var(--cepheidBlue);
  padding: 0 3px;
  margin: -3px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  background: linear-gradient(to right, #007ac2) no-repeat right;
  background-size: 0% auto;
}
body .wysiwyg a:not(.btn):not(.small-btn),
body .gallery-content a:not(.btn):not(.small-btn):not(.x-btn),
body .news-item a,
body .form a:not(.dropdown-item),
body #product-information a:not(.btn):not(.small-btn),
body .contact-list a:not(.btn),
body .cta-section a:not(.btn):not(.small-btn),
body .blue-slide-link a:not(.btn):not(.small-btn),
body a.white,
body .wysiwyg a:not(.btn):not(.small-btn):before,
body .gallery-content a:not(.btn):not(.small-btn):not(.x-btn):before,
body .news-item a:before,
body .form a:not(.dropdown-item):before,
body #product-information a:not(.btn):not(.small-btn):before,
body .contact-list a:not(.btn):before,
body .cta-section a:not(.btn):not(.small-btn):before,
body .blue-slide-link a:not(.btn):not(.small-btn):before,
body a.white:before {
  transition: all 0.3s, background-position 0s;
}
body .wysiwyg a:not(.btn):not(.small-btn):hover,
body .gallery-content a:not(.btn):not(.small-btn):not(.x-btn):hover,
body .news-item a:hover,
body .form a:not(.dropdown-item):hover,
body #product-information a:not(.btn):not(.small-btn):hover,
body .contact-list a:not(.btn):hover,
body .cta-section a:not(.btn):not(.small-btn):hover,
body .blue-slide-link a:not(.btn):not(.small-btn):hover,
body a.white:hover,
body .wysiwyg a:not(.btn):not(.small-btn).news-item:hover body .wysiwyg a:not(.btn):not(.small-btn),
body .wysiwyg a:not(.btn):not(.small-btn).news-item:hover body .gallery-content a:not(.btn):not(.small-btn):not(.x-btn),
body .wysiwyg a:not(.btn):not(.small-btn).news-item:hover body .news-item a,
body .wysiwyg a:not(.btn):not(.small-btn).news-item:hover body .form a:not(.dropdown-item),
body .wysiwyg a:not(.btn):not(.small-btn).news-item:hover body #product-information a:not(.btn):not(.small-btn),
body .wysiwyg a:not(.btn):not(.small-btn).news-item:hover body .contact-list a:not(.btn),
body .wysiwyg a:not(.btn):not(.small-btn).news-item:hover body .cta-section a:not(.btn):not(.small-btn),
body .wysiwyg a:not(.btn):not(.small-btn).news-item:hover body .blue-slide-link a:not(.btn):not(.small-btn),
body .wysiwyg a:not(.btn):not(.small-btn).news-item:hover body a.white,
body .gallery-content a:not(.btn):not(.small-btn):not(.x-btn).news-item:hover body .wysiwyg a:not(.btn):not(.small-btn),
body .gallery-content a:not(.btn):not(.small-btn):not(.x-btn).news-item:hover body .gallery-content a:not(.btn):not(.small-btn):not(.x-btn),
body .gallery-content a:not(.btn):not(.small-btn):not(.x-btn).news-item:hover body .news-item a,
body .gallery-content a:not(.btn):not(.small-btn):not(.x-btn).news-item:hover body .form a:not(.dropdown-item),
body .gallery-content a:not(.btn):not(.small-btn):not(.x-btn).news-item:hover body #product-information a:not(.btn):not(.small-btn),
body .gallery-content a:not(.btn):not(.small-btn):not(.x-btn).news-item:hover body .contact-list a:not(.btn),
body .gallery-content a:not(.btn):not(.small-btn):not(.x-btn).news-item:hover body .cta-section a:not(.btn):not(.small-btn),
body .gallery-content a:not(.btn):not(.small-btn):not(.x-btn).news-item:hover body .blue-slide-link a:not(.btn):not(.small-btn),
body .gallery-content a:not(.btn):not(.small-btn):not(.x-btn).news-item:hover body a.white,
body .news-item a.news-item:hover body .wysiwyg a:not(.btn):not(.small-btn),
body .news-item a.news-item:hover body .gallery-content a:not(.btn):not(.small-btn):not(.x-btn),
body .news-item a.news-item:hover body .news-item a,
body .news-item a.news-item:hover body .form a:not(.dropdown-item),
body .news-item a.news-item:hover body #product-information a:not(.btn):not(.small-btn),
body .news-item a.news-item:hover body .contact-list a:not(.btn),
body .news-item a.news-item:hover body .cta-section a:not(.btn):not(.small-btn),
body .news-item a.news-item:hover body .blue-slide-link a:not(.btn):not(.small-btn),
body .news-item a.news-item:hover body a.white,
body .form a:not(.dropdown-item).news-item:hover body .wysiwyg a:not(.btn):not(.small-btn),
body .form a:not(.dropdown-item).news-item:hover body .gallery-content a:not(.btn):not(.small-btn):not(.x-btn),
body .form a:not(.dropdown-item).news-item:hover body .news-item a,
body .form a:not(.dropdown-item).news-item:hover body .form a:not(.dropdown-item),
body .form a:not(.dropdown-item).news-item:hover body #product-information a:not(.btn):not(.small-btn),
body .form a:not(.dropdown-item).news-item:hover body .contact-list a:not(.btn),
body .form a:not(.dropdown-item).news-item:hover body .cta-section a:not(.btn):not(.small-btn),
body .form a:not(.dropdown-item).news-item:hover body .blue-slide-link a:not(.btn):not(.small-btn),
body .form a:not(.dropdown-item).news-item:hover body a.white,
body #product-information a:not(.btn):not(.small-btn).news-item:hover body .wysiwyg a:not(.btn):not(.small-btn),
body #product-information a:not(.btn):not(.small-btn).news-item:hover body .gallery-content a:not(.btn):not(.small-btn):not(.x-btn),
body #product-information a:not(.btn):not(.small-btn).news-item:hover body .news-item a,
body #product-information a:not(.btn):not(.small-btn).news-item:hover body .form a:not(.dropdown-item),
body #product-information a:not(.btn):not(.small-btn).news-item:hover body #product-information a:not(.btn):not(.small-btn),
body #product-information a:not(.btn):not(.small-btn).news-item:hover body .contact-list a:not(.btn),
body #product-information a:not(.btn):not(.small-btn).news-item:hover body .cta-section a:not(.btn):not(.small-btn),
body #product-information a:not(.btn):not(.small-btn).news-item:hover body .blue-slide-link a:not(.btn):not(.small-btn),
body #product-information a:not(.btn):not(.small-btn).news-item:hover body a.white,
body .contact-list a:not(.btn).news-item:hover body .wysiwyg a:not(.btn):not(.small-btn),
body .contact-list a:not(.btn).news-item:hover body .gallery-content a:not(.btn):not(.small-btn):not(.x-btn),
body .contact-list a:not(.btn).news-item:hover body .news-item a,
body .contact-list a:not(.btn).news-item:hover body .form a:not(.dropdown-item),
body .contact-list a:not(.btn).news-item:hover body #product-information a:not(.btn):not(.small-btn),
body .contact-list a:not(.btn).news-item:hover body .contact-list a:not(.btn),
body .contact-list a:not(.btn).news-item:hover body .cta-section a:not(.btn):not(.small-btn),
body .contact-list a:not(.btn).news-item:hover body .blue-slide-link a:not(.btn):not(.small-btn),
body .contact-list a:not(.btn).news-item:hover body a.white,
body .cta-section a:not(.btn):not(.small-btn).news-item:hover body .wysiwyg a:not(.btn):not(.small-btn),
body .cta-section a:not(.btn):not(.small-btn).news-item:hover body .gallery-content a:not(.btn):not(.small-btn):not(.x-btn),
body .cta-section a:not(.btn):not(.small-btn).news-item:hover body .news-item a,
body .cta-section a:not(.btn):not(.small-btn).news-item:hover body .form a:not(.dropdown-item),
body .cta-section a:not(.btn):not(.small-btn).news-item:hover body #product-information a:not(.btn):not(.small-btn),
body .cta-section a:not(.btn):not(.small-btn).news-item:hover body .contact-list a:not(.btn),
body .cta-section a:not(.btn):not(.small-btn).news-item:hover body .cta-section a:not(.btn):not(.small-btn),
body .cta-section a:not(.btn):not(.small-btn).news-item:hover body .blue-slide-link a:not(.btn):not(.small-btn),
body .cta-section a:not(.btn):not(.small-btn).news-item:hover body a.white,
body .blue-slide-link a:not(.btn):not(.small-btn).news-item:hover body .wysiwyg a:not(.btn):not(.small-btn),
body .blue-slide-link a:not(.btn):not(.small-btn).news-item:hover body .gallery-content a:not(.btn):not(.small-btn):not(.x-btn),
body .blue-slide-link a:not(.btn):not(.small-btn).news-item:hover body .news-item a,
body .blue-slide-link a:not(.btn):not(.small-btn).news-item:hover body .form a:not(.dropdown-item),
body .blue-slide-link a:not(.btn):not(.small-btn).news-item:hover body #product-information a:not(.btn):not(.small-btn),
body .blue-slide-link a:not(.btn):not(.small-btn).news-item:hover body .contact-list a:not(.btn),
body .blue-slide-link a:not(.btn):not(.small-btn).news-item:hover body .cta-section a:not(.btn):not(.small-btn),
body .blue-slide-link a:not(.btn):not(.small-btn).news-item:hover body .blue-slide-link a:not(.btn):not(.small-btn),
body .blue-slide-link a:not(.btn):not(.small-btn).news-item:hover body a.white,
body a.white.news-item:hover body .wysiwyg a:not(.btn):not(.small-btn),
body a.white.news-item:hover body .gallery-content a:not(.btn):not(.small-btn):not(.x-btn),
body a.white.news-item:hover body .news-item a,
body a.white.news-item:hover body .form a:not(.dropdown-item),
body a.white.news-item:hover body #product-information a:not(.btn):not(.small-btn),
body a.white.news-item:hover body .contact-list a:not(.btn),
body a.white.news-item:hover body .cta-section a:not(.btn):not(.small-btn),
body a.white.news-item:hover body .blue-slide-link a:not(.btn):not(.small-btn),
body a.white.news-item:hover body a.white {
  color: white;
  background-size: 100% auto;
  background-position: left;
}
body .news-item a {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
}
body .news-item:hover a {
  color: white;
  background-size: 100% auto;
  background-position: left;
}
body .form a:not(.dropdown-item),
body a.white:not(.btn):not(.small-btn):not(.x-btn),
body .white a:not(.btn):not(.small-btn):not(.x-btn) {
  color: white;
  font-weight: bold;
  background-image: linear-gradient(to right, white);
  text-decoration: underline;
}
body .form a:not(.dropdown-item):hover,
body a.white:not(.btn):not(.small-btn):not(.x-btn):hover,
body .white a:not(.btn):not(.small-btn):not(.x-btn):hover {
  color: var(--darkBlue);
}
body [class*="clamped"],
body h4.clamped + p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
body [class*="clamped"].clamped-3,
body h4.clamped + p.clamped-3 {
  -webkit-line-clamp: 3;
}
body [class*="clamped"].clamped-4,
body h4.clamped + p.clamped-4 {
  -webkit-line-clamp: 4;
}
body [class*="clamped"].clamped-6,
body h4.clamped + p.clamped-6 {
  -webkit-line-clamp: 6;
}
body h1 {
  font: 300 50px/58px var(--defaultFonts);
  margin: 0 0 30px;
}
body h1:only-child {
  margin: 0;
}
body h2,
body .col-form-label {
  font: 300 37px/45px var(--defaultFonts);
  color: var(--cepheidBlue);
}
body h2 strong,
body .col-form-label strong {
  font-size: 26px;
  line-height: 34px;
  color: var(--grey);
}
@media (max-width: 767px) {
  body h2 strong,
  body .col-form-label strong {
    font-size: var(--mobileFontSize);
    line-height: var(--mobileLineHeight);
  }
}
body .col-form-label {
  color: white;
  padding: 0;
}
body .support-header {
  display: flex;
  align-items: center;
  margin-bottom: 30px !important;
}
body .support-header span {
  display: inline-block;
  margin: 0 30px;
}
@media (max-width: 767px) {
  body .support-header span {
    margin: 0 15px;
  }
}
body .support-header hr {
  flex-grow: 1;
  margin: 0;
  border-color: white;
}
body .support-subhead {
  display: flex;
  align-items: center;
  color: white;
  font: 300 20px/28px var(--defaultFonts);
  margin: -20px 0 40px 0;
  justify-content: center;
}
body h1 sup,
body h2 sup,
body h4 sup,
body p sup,
body li sup {
  font-size: 50%;
  top: -1em;
}
body h1 sup sup,
body h2 sup sup,
body h4 sup sup,
body p sup sup,
body li sup sup {
  font-size: unset;
  top: unset;
}
body h3 {
  font: 300 21px/25px var(--defaultFonts);
  margin: 0 0 30px;
}
@media (max-width: 767px) {
  body h3 {
    margin-bottom: 15px;
  }
  body h3:not(.medium) {
    font-weight: 400;
  }
}
body h3.reg {
  font-weight: 400;
}
body h3.medium {
  font-weight: 500;
  color: var(--cepheidBlue);
}
body h3.hr {
  background: white;
  display: inline-flex;
  align-items: center;
  padding: 0 30px;
  transition: all 0.7s;
  color: transparent;
}
body h3.hr:before,
body h3.hr:after {
  content: "";
  position: absolute;
  width: 0%;
  background: var(--mediumGrey);
  height: 1px;
  z-index: -1;
  transition: all 0.7s;
}
body h3.hr:before {
  right: 50%;
}
body h3.hr:after {
  left: 50%;
}
body h3.hr.visible {
  color: var(--grey);
}
body h3.hr.visible:before,
body h3.hr.visible:after {
  width: 50%;
}
body h4,
body .read-more {
  font: 500 21px/28px var(--defaultFonts);
  margin-bottom: 15px;
}
body h4.light,
body .read-more.light {
  margin: 0;
}
body h4.sml-txt,
body .read-more.sml-txt {
  color: var(--grey);
  margin: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s;
}
body h4.sml-txt:hover,
body .read-more.sml-txt:hover {
  opacity: 0.7;
}
body h4.sml-txt svg,
body .read-more.sml-txt svg {
  margin-right: 10px;
}
body h2,
body h4,
body .col-form-label {
  margin: 0 0 15px;
}
body h2.center {
  margin-bottom: var(--sectionSpacing);
}
@media (max-width: 767px) {
  body h2.center {
    margin-bottom: var(--sectionSpacingMobile);
  }
}
body h5 {
  font: 300 12px/16px var(--defaultFonts);
  margin: 0 0 30px;
  color: #828282;
}
body h5 span.breadcrumbs a {
  color: #828282;
}
@media (max-width: 767px) {
  body h5:not(.medium) {
    font-weight: 400;
  }
}
body h5.medium {
  font-size: var(--desktopFontSize);
  line-height: var(--desktopLineHeight);
  font-weight: 500;
  color: var(--cepheidBlue);
}
body table h5 {
  margin: 0;
}
body h6 {
  font: 500 12px/20px var(--defaultFonts);
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin: 0 0 15px;
}
body .campaign-form-container {
  position: sticky;
  top: 60px;
}
body .campaign-form-container .brick-area.form > .container > .row > .col {
  padding: 0;
}
body .wysiwyg ul,
body .featurette ul,
body .product-sections ul {
  margin: 15px 0;
  padding: 0 0 0 20px;
  list-style: none;
}
body .wysiwyg ul li,
body .featurette ul li,
body .product-sections ul li {
  position: relative;
}
body .wysiwyg ul li:before,
body .featurette ul li:before,
body .product-sections ul li:before {
  content: "•";
  color: var(--cepheidBlue);
  position: absolute;
  left: -20px;
  font-size: 24px;
}
@media (max-width: 767px) {
  body .wysiwyg ul li:before,
  body .featurette ul li:before,
  body .product-sections ul li:before {
    font-size: 18px;
  }
}
body .wysiwyg ul li ul,
body .featurette ul li ul,
body .product-sections ul li ul {
  margin: 0;
}
body .wysiwyg ul li ul li:before,
body .featurette ul li ul li:before,
body .product-sections ul li ul li:before {
  content: "-";
}
@media (max-width: 767px) {
  body .wysiwyg ul,
  body .featurette ul,
  body .product-sections ul {
    padding-left: 25px;
  }
}
body .wysiwyg .clear ul:not(.blue-list) li:before,
body .featurette .clear ul:not(.blue-list) li:before,
body .product-sections .clear ul:not(.blue-list) li:before {
  color: white;
}
body .wysiwyg .yellow-check-list,
body .featurette .yellow-check-list,
body .product-sections .yellow-check-list,
body .wysiwyg .yellow-white-check-list,
body .featurette .yellow-white-check-list,
body .product-sections .yellow-white-check-list {
  list-style: none;
}
body .wysiwyg .yellow-check-list li,
body .featurette .yellow-check-list li,
body .product-sections .yellow-check-list li,
body .wysiwyg .yellow-white-check-list li,
body .featurette .yellow-white-check-list li,
body .product-sections .yellow-white-check-list li {
  margin-bottom: 30px;
  padding-left: 35px;
  min-height: 54px;
}
body .wysiwyg .yellow-check-list li:before,
body .featurette .yellow-check-list li:before,
body .product-sections .yellow-check-list li:before {
  content: url('/static/img/yellow-check-box.svg');
}
body .wysiwyg .yellow-white-check-list li:before,
body .featurette .yellow-white-check-list li:before,
body .product-sections .yellow-white-check-list li:before {
  content: url('/static/img/yellow-white-check-box.svg');
}
body div.svg svg {
  max-width: 100%;
  height: auto;
}
body .sml-txt {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.4px;
  color: var(--mediumGrey);
}
@media (max-width: 767px) {
  body .sml-txt {
    font-size: 10px;
    line-height: 14px;
  }
}
body .offset-txt {
  position: relative;
  top: 30px;
}
body .flex-end {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
}
@media (max-width: 767px) {
  body {
    font-size: var(--mobileFontSize);
    line-height: var(--mobileLineHeight);
  }
  body h1,
  body h2,
  body .col-form-label {
    font-size: 25px;
    line-height: 30px;
  }
  body h3 {
    font-size: var(--mobileFontSize);
    line-height: var(--mobileLineHeight);
  }
  body h4 {
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
  }
  body h6 {
    font-weight: bold;
  }
}
body .training-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  grid-gap: 30px;
}
body section.brick-area,
body .padded {
  padding: var(--sectionSpacing) 0;
}
@media (max-width: 767px) {
  body section.brick-area,
  body .padded {
    padding: var(--sectionSpacingMobile) 0;
  }
}
body section.brick-area.no-bottom,
body .padded.no-bottom {
  padding-bottom: 0;
}
@media (min-width: 992px) {
  body section.brick-area.no-bottom.image div[class*="col"]:first-child,
  body .padded.no-bottom.image div[class*="col"]:first-child {
    padding-bottom: 60px;
  }
}
body section.brick-area.no-top,
body .padded.no-top {
  padding-top: 0;
}
@media (min-width: 992px) {
  body section.brick-area.no-top.image div[class*="col"]:first-child,
  body .padded.no-top.image div[class*="col"]:first-child {
    padding-top: 60px;
  }
}
body section.brick-area.gallery.multiple,
body .padded.gallery.multiple {
  padding-bottom: 0;
}
body section.brick-area section.brick-area,
body .padded section.brick-area {
  padding: 0;
  background: none;
}
body section.brick-area .brick-separate,
body .padded .brick-separate {
  padding-top: var(--sectionSpacing);
}
@media (max-width: 767px) {
  body section.brick-area .brick-separate,
  body .padded .brick-separate {
    padding-top: var(--sectionSpacingMobile);
  }
}
body section.brick-area.form,
body .padded.form,
body section.brick-area.form-section,
body .padded.form-section {
  background: #007ac2;
  background: var(--darkBlue);
  color: white;
}
body section.brick-area.form .required:after,
body .padded.form .required:after,
body section.brick-area.form-section .required:after,
body .padded.form-section .required:after {
  content: "*";
}
body section.brick-area.form .custom-checkbox,
body .padded.form .custom-checkbox,
body section.brick-area.form-section .custom-checkbox,
body .padded.form-section .custom-checkbox {
  margin: 0 0 1em;
}
body section.brick-area.form .col-form-label.required,
body .padded.form .col-form-label.required,
body section.brick-area.form-section .col-form-label.required,
body .padded.form-section .col-form-label.required,
body section.brick-area.form .form-builder-html-tag-element,
body .padded.form .form-builder-html-tag-element,
body section.brick-area.form-section .form-builder-html-tag-element,
body .padded.form-section .form-builder-html-tag-element {
  font-size: var(--desktopFontSize);
  font-weight: bold;
  margin: 1em 0 0;
}
body section.brick-area.form .form-builder-html-tag-element,
body .padded.form .form-builder-html-tag-element,
body section.brick-area.form-section .form-builder-html-tag-element,
body .padded.form-section .form-builder-html-tag-element {
  margin: 0;
}
body section.brick-area.form .custom-control.custom-checkbox:not(:first-of-type),
body .padded.form .custom-control.custom-checkbox:not(:first-of-type),
body section.brick-area.form-section .custom-control.custom-checkbox:not(:first-of-type),
body .padded.form-section .custom-control.custom-checkbox:not(:first-of-type) {
  margin: 1em 0;
}
body section.brick-area.form br,
body .padded.form br,
body section.brick-area.form-section br,
body .padded.form-section br {
  display: none;
}
body section.brick-area.form .row,
body .padded.form .row,
body section.brick-area.form-section .row,
body .padded.form-section .row,
body section.brick-area.form .form-group .form-group,
body .padded.form .form-group .form-group,
body section.brick-area.form-section .form-group .form-group,
body .padded.form-section .form-group .form-group,
body section.brick-area.form .row > div,
body .padded.form .row > div,
body section.brick-area.form-section .row > div,
body .padded.form-section .row > div,
body section.brick-area.form .form-group .form-group > div,
body .padded.form .form-group .form-group > div,
body section.brick-area.form-section .form-group .form-group > div,
body .padded.form-section .form-group .form-group > div {
  margin: 0;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  body section.brick-area.form .row,
  body .padded.form .row,
  body section.brick-area.form-section .row,
  body .padded.form-section .row,
  body section.brick-area.form .form-group .form-group,
  body .padded.form .form-group .form-group,
  body section.brick-area.form-section .form-group .form-group,
  body .padded.form-section .form-group .form-group,
  body section.brick-area.form .row > div,
  body .padded.form .row > div,
  body section.brick-area.form-section .row > div,
  body .padded.form-section .row > div,
  body section.brick-area.form .form-group .form-group > div,
  body .padded.form .form-group .form-group > div,
  body section.brick-area.form-section .form-group .form-group > div,
  body .padded.form-section .form-group .form-group > div {
    margin-right: 20px;
  }
}
body section.brick-area.form .row,
body .padded.form .row,
body section.brick-area.form-section .row,
body .padded.form-section .row {
  display: grid;
  grid-column-gap: 30px;
  grid-row-gap: 1em;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}
body .left {
  text-align: left;
}
body .center {
  text-align: center;
  justify-content: center;
}
body .rel {
  position: relative;
}
body .flex-right {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
body .container,
body .container-fluid {
  padding: 0 30px;
  max-width: 1170px;
}
@media (max-width: 767px) {
  body .container,
  body .container-fluid {
    padding: 0 15px;
  }
}
body .container .row,
body .container-fluid .row {
  flex: 1;
  margin-bottom: -30px;
}
body .container .row > div,
body .container-fluid .row > div {
  margin-bottom: 30px;
}
body .container .row.large-margins,
body .container-fluid .row.large-margins {
  margin-bottom: -60px;
}
body .container .row.large-margins > div,
body .container-fluid .row.large-margins > div {
  margin-bottom: 60px;
}
@media (min-width: 768px) {
  body .container .row.large-margins,
  body .container-fluid .row.large-margins {
    margin-bottom: -120px;
  }
  body .container .row.large-margins > div,
  body .container-fluid .row.large-margins > div {
    margin-bottom: 120px;
  }
}
body .container-fluid {
  max-width: none;
}
body .container-fluid.carousel-layout,
body .impact-stories .container-fluid {
  max-width: 1330px;
}
body .cta-row.icons .pimcore_tag_block {
  display: flex;
  flex-wrap: wrap;
}
body .back-to-top {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  z-index: 8;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
  transition: all 0.4s, visibility 0s 0.4s;
}
body .back-to-top.display {
  transition: all 0.4s;
  visibility: visible;
  opacity: 1;
}

[v-cloak] {
  display: none;
}
.arrow-box .content {
  padding: 60px 30px;
  position: relative;
  opacity: 0;
  top: 20px;
  transition: all 1s 0.5s;
}
.arrow-box svg {
  display: block;
  position: absolute;
  top: 0;
}
.arrow-box svg.arrow-svg {
  top: -30px;
  opacity: 0;
  transition: all 1s 0.5s;
}
.arrow-box svg rect {
  fill: transparent;
  stroke: var(--mediumGrey);
  stroke-width: 0px;
  transform: rotateX(180deg);
}
.cta-section .arrow-box svg rect {
  stroke: white;
}
.arrow-box svg rect.left {
  transform: rotateX(180deg) rotateY(180deg);
}
.arrow-box .visible svg rect,
.arrow-box.visible svg rect {
  transition: stroke-dashoffset 1.5s;
  stroke-width: 2px;
}
.arrow-box .visible svg.arrow-svg,
.arrow-box.visible svg.arrow-svg {
  top: -11px;
  opacity: 1;
}
.arrow-box .visible .content,
.arrow-box.visible .content {
  opacity: 1;
  top: 0;
}
.quote {
  font: 300 28px/40px var(--defaultFonts);
  text-align: center;
  max-width: 478px;
  position: relative;
}
@media (max-width: 767px) {
  .quote {
    font-size: var(--mobileFontSize);
    line-height: var(--mobileLineHeight);
  }
}
@media (max-width: 991px) {
  .quote {
    margin: auto;
  }
}
.quote strong {
  color: var(--green);
}
.quote em {
  font-size: var(--desktopFontSize);
  line-height: var(--desktopLineHeight);
}
.quotes .quote {
  max-width: 790px;
  margin: auto;
}
.quotes-large .quote {
  max-width: none;
}
section.greybackground {
  background: var(--lightGrey);
  color: var(--cepheidBlue);
  padding: var(--sectionSpacing) 0;
}
section.greybackground .greybackground {
  transition: all 1s;
}
section.greybackground .greybackground .arrow-box .quote {
  font: 300 21px/25px var(--defaultFonts);
  text-align: left;
  max-width: 100%;
  position: relative;
}
@media (max-width: 767px) {
  section.greybackground .greybackground .arrow-box .quote {
    font-size: var(--mobileFontSize);
    line-height: var(--mobileLineHeight);
  }
}
@media (max-width: 991px) {
  section.greybackground .greybackground .arrow-box .quote {
    margin: auto;
  }
}
section.greybackground .greybackground .arrow-box .quote strong {
  color: var(--green);
}
section.greybackground .greybackground .arrow-box .quote em {
  font-size: var(--desktopFontSize);
  line-height: var(--desktopLineHeight);
}
section.greybackground .greybackground .arrow-box .quote .content {
  padding: 0;
  position: relative;
  opacity: 0;
  top: 20px;
  transition: all 1s 0.5s;
}
section.greybackground .greybackground .arrow-box .quote svg {
  display: block;
  position: absolute;
  top: 0;
}
section.greybackground .greybackground .arrow-box .quote svg.arrow-svg {
  top: -30px;
  opacity: 0;
  transition: all 1s 0.5s;
}
section.greybackground .greybackground .arrow-box .quote svg rect {
  fill: transparent;
  stroke: var(--mediumGrey);
  stroke-width: 0px;
  transform: rotateX(180deg);
}
.cta-section section.greybackground .greybackground .arrow-box .quote svg rect {
  stroke: white;
}
section.greybackground .greybackground .arrow-box .quote svg rect.left {
  transform: rotateX(180deg) rotateY(180deg);
}
section.greybackground .greybackground .arrow-box .quote .visible svg rect,
section.greybackground .greybackground .arrow-box .quote.visible svg rect {
  transition: stroke-dashoffset 1.5s;
  stroke-width: 0px;
}
section.greybackground .greybackground .arrow-box .quote .visible svg.arrow-svg,
section.greybackground .greybackground .arrow-box .quote.visible svg.arrow-svg {
  top: -11px;
  opacity: 0;
}
section.greybackground .greybackground .arrow-box .quote .visible .content,
section.greybackground .greybackground .arrow-box .quote.visible .content {
  opacity: 1;
  top: 0;
}

.bootstrap-select .filter-option-inner-inner {
  color: #495057;
}
.bootstrap-select a.dropdown-item {
  border-radius: 0;
}
.bootstrap-select .bs-select-all {
  border-right: 2px solid #fff !important;
}
.bootstrap-select .bs-select-all,
.bootstrap-select .bs-deselect-all {
  justify-content: center !important;
}
#tm-instrument-app .bootstrap-select > button,
#tm-assay-app .bootstrap-select > button,
#inserts-filter-app .bootstrap-select > button {
  background-color: #fff;
  border: 1px solid #ced4da;
}
#contact-list select,
.bs-select {
  background-color: #f8f9fa;
  border-color: #ddd;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  color: #495057;
  width: 100%;
}

/* purgecss start ignore */
div.btn-container {
  margin-top: var(--sectionSpacing);
  text-align: center;
}
@media (max-width: 767px) {
  div.btn-container {
    margin-top: var(--sectionSpacingMobile);
  }
}
a.small-btn {
  font-weight: 300;
}
a.small-btn p {
  display: inline;
}
a.product-cta,
a.small-btn {
  color: var(--grey);
  display: block;
  position: relative;
  transition: all 0.3s;
}
a.product-cta > div,
a.small-btn > div {
  display: inline-block;
}
a.product-cta:after,
a.small-btn:after {
  content: "";
  width: 12px;
  height: 26px;
  margin-left: 10px;
  background-size: contain;
  display: inline-block;
  background: url("../icons/arrow-2.svg") no-repeat center;
  position: absolute;
  transition: all 0.3s;
}
@media (max-width: 767px) {
  a.product-cta:after,
  a.small-btn:after {
    height: 15px;
  }
}
a.product-cta:hover,
a.small-btn:hover {
  opacity: 0.7;
}
a.product-cta:hover:after,
a.small-btn:hover:after {
  margin-left: 15px;
}
a.product-cta.download-btn,
a.small-btn.download-btn {
  padding-left: 30px;
}
a.product-cta.download-btn:after,
a.small-btn.download-btn:after {
  display: none;
}
a.product-cta.download-btn:before,
a.small-btn.download-btn:before {
  content: "";
  width: 20px;
  height: 100%;
  display: inline-block;
  background: url("../icons/download.svg") no-repeat center;
  background-size: contain;
  position: absolute;
  left: 0;
}
a,
button:not(.dropdown-toggle),
div.input-btn,
div.qq-upload-button,
a:hover,
button:not(.dropdown-toggle):hover,
div.input-btn:hover,
div.qq-upload-button:hover,
a:focus,
button:not(.dropdown-toggle):focus,
div.input-btn:focus,
div.qq-upload-button:focus {
  text-decoration: none;
}
a input.btn,
button:not(.dropdown-toggle) input.btn,
div.input-btn input.btn,
div.qq-upload-button input.btn {
  padding: 0;
  color: inherit;
}
a.qq-upload-button,
button:not(.dropdown-toggle).qq-upload-button,
div.input-btn.qq-upload-button,
div.qq-upload-button.qq-upload-button {
  width: auto;
  box-shadow: none !important;
}
a.btn,
button:not(.dropdown-toggle).btn,
div.input-btn.btn,
div.qq-upload-button.btn,
a.qq-upload-button,
button:not(.dropdown-toggle).qq-upload-button,
div.input-btn.qq-upload-button,
div.qq-upload-button.qq-upload-button {
  border-radius: 5px;
  color: white;
  overflow: hidden;
  position: relative;
  transition: all 0.2s;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 1;
  backdrop-filter: blur(3px);
  padding: 6px 40px;
  font: 300 21px/26px var(--defaultFonts);
  border: 1px solid var(--cepheidBlue);
  text-align: left;
  background: var(--lightBlue);
}
@media (max-width: 767px) {
  a.btn,
  button:not(.dropdown-toggle).btn,
  div.input-btn.btn,
  div.qq-upload-button.btn,
  a.qq-upload-button,
  button:not(.dropdown-toggle).qq-upload-button,
  div.input-btn.qq-upload-button,
  div.qq-upload-button.qq-upload-button {
    padding: 12px 25px;
    font-size: var(--mobileFontSize);
    line-height: var(--mobileLineHeight);
  }
}
a.btn:before,
button:not(.dropdown-toggle).btn:before,
div.input-btn.btn:before,
div.qq-upload-button.btn:before,
a.qq-upload-button:before,
button:not(.dropdown-toggle).qq-upload-button:before,
div.input-btn.qq-upload-button:before,
div.qq-upload-button.qq-upload-button:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--cepheidBlue);
  z-index: -1;
  transition: all 0.2s;
  animation: btnHoverOff 0.2s forwards linear;
}
html.no-touchevents a.btn:hover,
html.no-touchevents button:not(.dropdown-toggle).btn:hover,
html.no-touchevents div.input-btn.btn:hover,
html.no-touchevents div.qq-upload-button.btn:hover,
html.no-touchevents a.qq-upload-button:hover,
html.no-touchevents button:not(.dropdown-toggle).qq-upload-button:hover,
html.no-touchevents div.input-btn.qq-upload-button:hover,
html.no-touchevents div.qq-upload-button.qq-upload-button:hover,
html.no-touchevents a.btn.hover,
html.no-touchevents button:not(.dropdown-toggle).btn.hover,
html.no-touchevents div.input-btn.btn.hover,
html.no-touchevents div.qq-upload-button.btn.hover,
html.no-touchevents a.qq-upload-button.hover,
html.no-touchevents button:not(.dropdown-toggle).qq-upload-button.hover,
html.no-touchevents div.input-btn.qq-upload-button.hover,
html.no-touchevents div.qq-upload-button.qq-upload-button.hover {
  color: white;
}
html.no-touchevents a.btn:hover:before,
html.no-touchevents button:not(.dropdown-toggle).btn:hover:before,
html.no-touchevents div.input-btn.btn:hover:before,
html.no-touchevents div.qq-upload-button.btn:hover:before,
html.no-touchevents a.qq-upload-button:hover:before,
html.no-touchevents button:not(.dropdown-toggle).qq-upload-button:hover:before,
html.no-touchevents div.input-btn.qq-upload-button:hover:before,
html.no-touchevents div.qq-upload-button.qq-upload-button:hover:before,
html.no-touchevents a.btn.hover:before,
html.no-touchevents button:not(.dropdown-toggle).btn.hover:before,
html.no-touchevents div.input-btn.btn.hover:before,
html.no-touchevents div.qq-upload-button.btn.hover:before,
html.no-touchevents a.qq-upload-button.hover:before,
html.no-touchevents button:not(.dropdown-toggle).qq-upload-button.hover:before,
html.no-touchevents div.input-btn.qq-upload-button.hover:before,
html.no-touchevents div.qq-upload-button.qq-upload-button.hover:before {
  animation: btnHoverOn 0.2s forwards linear;
}
a.btn:hover,
button:not(.dropdown-toggle).btn:hover,
div.input-btn.btn:hover,
div.qq-upload-button.btn:hover,
a.qq-upload-button:hover,
button:not(.dropdown-toggle).qq-upload-button:hover,
div.input-btn.qq-upload-button:hover,
div.qq-upload-button.qq-upload-button:hover,
a.btn.hover,
button:not(.dropdown-toggle).btn.hover,
div.input-btn.btn.hover,
div.qq-upload-button.btn.hover,
a.qq-upload-button.hover,
button:not(.dropdown-toggle).qq-upload-button.hover,
div.input-btn.qq-upload-button.hover,
div.qq-upload-button.qq-upload-button.hover {
  border-color: var(--lightBlue);
}
html.touchevents a.btn:hover,
html.touchevents button:not(.dropdown-toggle).btn:hover,
html.touchevents div.input-btn.btn:hover,
html.touchevents div.qq-upload-button.btn:hover,
html.touchevents a.qq-upload-button:hover,
html.touchevents button:not(.dropdown-toggle).qq-upload-button:hover,
html.touchevents div.input-btn.qq-upload-button:hover,
html.touchevents div.qq-upload-button.qq-upload-button:hover,
html.touchevents a.btn.hover,
html.touchevents button:not(.dropdown-toggle).btn.hover,
html.touchevents div.input-btn.btn.hover,
html.touchevents div.qq-upload-button.btn.hover,
html.touchevents a.qq-upload-button.hover,
html.touchevents button:not(.dropdown-toggle).qq-upload-button.hover,
html.touchevents div.input-btn.qq-upload-button.hover,
html.touchevents div.qq-upload-button.qq-upload-button.hover {
  color: white;
}
a.btn h4,
button:not(.dropdown-toggle).btn h4,
div.input-btn.btn h4,
div.qq-upload-button.btn h4,
a.qq-upload-button h4,
button:not(.dropdown-toggle).qq-upload-button h4,
div.input-btn.qq-upload-button h4,
div.qq-upload-button.qq-upload-button h4 {
  font-size: var(--desktopFontSize);
  line-height: var(--desktopLineHeight);
}
a.btn.event-btn,
button:not(.dropdown-toggle).btn.event-btn,
div.input-btn.btn.event-btn,
div.qq-upload-button.btn.event-btn,
a.qq-upload-button.event-btn,
button:not(.dropdown-toggle).qq-upload-button.event-btn,
div.input-btn.qq-upload-button.event-btn,
div.qq-upload-button.qq-upload-button.event-btn {
  padding: 6px 20px;
  font-size: var(--desktopFontSize);
  color: var(--grey);
  background: white;
  border-color: var(--mediumGrey);
  margin-top: 10px;
}
@media (max-width: 767px) {
  a.btn.event-btn,
  button:not(.dropdown-toggle).btn.event-btn,
  div.input-btn.btn.event-btn,
  div.qq-upload-button.btn.event-btn,
  a.qq-upload-button.event-btn,
  button:not(.dropdown-toggle).qq-upload-button.event-btn,
  div.input-btn.qq-upload-button.event-btn,
  div.qq-upload-button.qq-upload-button.event-btn {
    font-size: var(--mobileFontSize);
    line-height: var(--mobileLineHeight);
  }
}
a.btn.event-btn:before,
button:not(.dropdown-toggle).btn.event-btn:before,
div.input-btn.btn.event-btn:before,
div.qq-upload-button.btn.event-btn:before,
a.qq-upload-button.event-btn:before,
button:not(.dropdown-toggle).qq-upload-button.event-btn:before,
div.input-btn.qq-upload-button.event-btn:before,
div.qq-upload-button.qq-upload-button.event-btn:before {
  animation: btnHoverOn 0.2s forwards linear;
  background: var(--mediumGrey);
}
a.btn.event-btn:hover,
button:not(.dropdown-toggle).btn.event-btn:hover,
div.input-btn.btn.event-btn:hover,
div.qq-upload-button.btn.event-btn:hover,
a.qq-upload-button.event-btn:hover,
button:not(.dropdown-toggle).qq-upload-button.event-btn:hover,
div.input-btn.qq-upload-button.event-btn:hover,
div.qq-upload-button.qq-upload-button.event-btn:hover,
.feed-item:hover a.btn.event-btn,
.feed-item:hover button:not(.dropdown-toggle).btn.event-btn,
.feed-item:hover div.input-btn.btn.event-btn,
.feed-item:hover div.qq-upload-button.btn.event-btn,
.feed-item:hover a.qq-upload-button.event-btn,
.feed-item:hover button:not(.dropdown-toggle).qq-upload-button.event-btn,
.feed-item:hover div.input-btn.qq-upload-button.event-btn,
.feed-item:hover div.qq-upload-button.qq-upload-button.event-btn {
  color: white;
}
a.btn.event-btn:hover:before,
button:not(.dropdown-toggle).btn.event-btn:hover:before,
div.input-btn.btn.event-btn:hover:before,
div.qq-upload-button.btn.event-btn:hover:before,
a.qq-upload-button.event-btn:hover:before,
button:not(.dropdown-toggle).qq-upload-button.event-btn:hover:before,
div.input-btn.qq-upload-button.event-btn:hover:before,
div.qq-upload-button.qq-upload-button.event-btn:hover:before,
.feed-item:hover a.btn.event-btn:before,
.feed-item:hover button:not(.dropdown-toggle).btn.event-btn:before,
.feed-item:hover div.input-btn.btn.event-btn:before,
.feed-item:hover div.qq-upload-button.btn.event-btn:before,
.feed-item:hover a.qq-upload-button.event-btn:before,
.feed-item:hover button:not(.dropdown-toggle).qq-upload-button.event-btn:before,
.feed-item:hover div.input-btn.qq-upload-button.event-btn:before,
.feed-item:hover div.qq-upload-button.qq-upload-button.event-btn:before {
  animation: btnHoverOff 0.2s forwards linear !important;
}
a.btn.event-btn.blue,
button:not(.dropdown-toggle).btn.event-btn.blue,
div.input-btn.btn.event-btn.blue,
div.qq-upload-button.btn.event-btn.blue,
a.qq-upload-button.event-btn.blue,
button:not(.dropdown-toggle).qq-upload-button.event-btn.blue,
div.input-btn.qq-upload-button.event-btn.blue,
div.qq-upload-button.qq-upload-button.event-btn.blue {
  color: var(--cepheidBlue);
  border-color: var(--cepheidBlue);
}
a.btn.event-btn.blue:before,
button:not(.dropdown-toggle).btn.event-btn.blue:before,
div.input-btn.btn.event-btn.blue:before,
div.qq-upload-button.btn.event-btn.blue:before,
a.qq-upload-button.event-btn.blue:before,
button:not(.dropdown-toggle).qq-upload-button.event-btn.blue:before,
div.input-btn.qq-upload-button.event-btn.blue:before,
div.qq-upload-button.qq-upload-button.event-btn.blue:before {
  background: var(--cepheidBlue);
}
a.btn.event-btn.blue:hover,
button:not(.dropdown-toggle).btn.event-btn.blue:hover,
div.input-btn.btn.event-btn.blue:hover,
div.qq-upload-button.btn.event-btn.blue:hover,
a.qq-upload-button.event-btn.blue:hover,
button:not(.dropdown-toggle).qq-upload-button.event-btn.blue:hover,
div.input-btn.qq-upload-button.event-btn.blue:hover,
div.qq-upload-button.qq-upload-button.event-btn.blue:hover {
  color: white;
}
a.btn.white-btn,
button:not(.dropdown-toggle).btn.white-btn,
div.input-btn.btn.white-btn,
div.qq-upload-button.btn.white-btn,
a.qq-upload-button.white-btn,
button:not(.dropdown-toggle).qq-upload-button.white-btn,
div.input-btn.qq-upload-button.white-btn,
div.qq-upload-button.qq-upload-button.white-btn,
a.btn.btn-primary:not(.modal-btn),
button:not(.dropdown-toggle).btn.btn-primary:not(.modal-btn),
div.input-btn.btn.btn-primary:not(.modal-btn),
div.qq-upload-button.btn.btn-primary:not(.modal-btn),
a.qq-upload-button.btn-primary:not(.modal-btn),
button:not(.dropdown-toggle).qq-upload-button.btn-primary:not(.modal-btn),
div.input-btn.qq-upload-button.btn-primary:not(.modal-btn),
div.qq-upload-button.qq-upload-button.btn-primary:not(.modal-btn) {
  border-color: white;
  color: #007ac2;
  color: var(--cepheidBlue);
  background: none;
}
a.btn.white-btn:before,
button:not(.dropdown-toggle).btn.white-btn:before,
div.input-btn.btn.white-btn:before,
div.qq-upload-button.btn.white-btn:before,
a.qq-upload-button.white-btn:before,
button:not(.dropdown-toggle).qq-upload-button.white-btn:before,
div.input-btn.qq-upload-button.white-btn:before,
div.qq-upload-button.qq-upload-button.white-btn:before,
a.btn.btn-primary:not(.modal-btn):before,
button:not(.dropdown-toggle).btn.btn-primary:not(.modal-btn):before,
div.input-btn.btn.btn-primary:not(.modal-btn):before,
div.qq-upload-button.btn.btn-primary:not(.modal-btn):before,
a.qq-upload-button.btn-primary:not(.modal-btn):before,
button:not(.dropdown-toggle).qq-upload-button.btn-primary:not(.modal-btn):before,
div.input-btn.qq-upload-button.btn-primary:not(.modal-btn):before,
div.qq-upload-button.qq-upload-button.btn-primary:not(.modal-btn):before {
  background: white;
}
html.no-touchevents a.btn.white-btn:hover,
html.no-touchevents button:not(.dropdown-toggle).btn.white-btn:hover,
html.no-touchevents div.input-btn.btn.white-btn:hover,
html.no-touchevents div.qq-upload-button.btn.white-btn:hover,
html.no-touchevents a.qq-upload-button.white-btn:hover,
html.no-touchevents button:not(.dropdown-toggle).qq-upload-button.white-btn:hover,
html.no-touchevents div.input-btn.qq-upload-button.white-btn:hover,
html.no-touchevents div.qq-upload-button.qq-upload-button.white-btn:hover,
html.no-touchevents a.btn.btn-primary:not(.modal-btn):hover,
html.no-touchevents button:not(.dropdown-toggle).btn.btn-primary:not(.modal-btn):hover,
html.no-touchevents div.input-btn.btn.btn-primary:not(.modal-btn):hover,
html.no-touchevents div.qq-upload-button.btn.btn-primary:not(.modal-btn):hover,
html.no-touchevents a.qq-upload-button.btn-primary:not(.modal-btn):hover,
html.no-touchevents button:not(.dropdown-toggle).qq-upload-button.btn-primary:not(.modal-btn):hover,
html.no-touchevents div.input-btn.qq-upload-button.btn-primary:not(.modal-btn):hover,
html.no-touchevents div.qq-upload-button.qq-upload-button.btn-primary:not(.modal-btn):hover {
  color: white;
}
html.touchevents a.btn.white-btn:hover,
html.touchevents button:not(.dropdown-toggle).btn.white-btn:hover,
html.touchevents div.input-btn.btn.white-btn:hover,
html.touchevents div.qq-upload-button.btn.white-btn:hover,
html.touchevents a.qq-upload-button.white-btn:hover,
html.touchevents button:not(.dropdown-toggle).qq-upload-button.white-btn:hover,
html.touchevents div.input-btn.qq-upload-button.white-btn:hover,
html.touchevents div.qq-upload-button.qq-upload-button.white-btn:hover,
html.touchevents a.btn.btn-primary:not(.modal-btn):hover,
html.touchevents button:not(.dropdown-toggle).btn.btn-primary:not(.modal-btn):hover,
html.touchevents div.input-btn.btn.btn-primary:not(.modal-btn):hover,
html.touchevents div.qq-upload-button.btn.btn-primary:not(.modal-btn):hover,
html.touchevents a.qq-upload-button.btn-primary:not(.modal-btn):hover,
html.touchevents button:not(.dropdown-toggle).qq-upload-button.btn-primary:not(.modal-btn):hover,
html.touchevents div.input-btn.qq-upload-button.btn-primary:not(.modal-btn):hover,
html.touchevents div.qq-upload-button.qq-upload-button.btn-primary:not(.modal-btn):hover {
  color: var(--cepheidBlue);
}
a.btn.white-btn:hover,
button:not(.dropdown-toggle).btn.white-btn:hover,
div.input-btn.btn.white-btn:hover,
div.qq-upload-button.btn.white-btn:hover,
a.qq-upload-button.white-btn:hover,
button:not(.dropdown-toggle).qq-upload-button.white-btn:hover,
div.input-btn.qq-upload-button.white-btn:hover,
div.qq-upload-button.qq-upload-button.white-btn:hover,
a.btn.btn-primary:not(.modal-btn):hover,
button:not(.dropdown-toggle).btn.btn-primary:not(.modal-btn):hover,
div.input-btn.btn.btn-primary:not(.modal-btn):hover,
div.qq-upload-button.btn.btn-primary:not(.modal-btn):hover,
a.qq-upload-button.btn-primary:not(.modal-btn):hover,
button:not(.dropdown-toggle).qq-upload-button.btn-primary:not(.modal-btn):hover,
div.input-btn.qq-upload-button.btn-primary:not(.modal-btn):hover,
div.qq-upload-button.qq-upload-button.btn-primary:not(.modal-btn):hover {
  border-color: white;
}
.form-section a.btn.white-btn,
.form-section button:not(.dropdown-toggle).btn.white-btn,
.form-section div.input-btn.btn.white-btn,
.form-section div.qq-upload-button.btn.white-btn,
.form-section a.qq-upload-button.white-btn,
.form-section button:not(.dropdown-toggle).qq-upload-button.white-btn,
.form-section div.input-btn.qq-upload-button.white-btn,
.form-section div.qq-upload-button.qq-upload-button.white-btn,
.form-section a.btn.btn-primary:not(.modal-btn),
.form-section button:not(.dropdown-toggle).btn.btn-primary:not(.modal-btn),
.form-section div.input-btn.btn.btn-primary:not(.modal-btn),
.form-section div.qq-upload-button.btn.btn-primary:not(.modal-btn),
.form-section a.qq-upload-button.btn-primary:not(.modal-btn),
.form-section button:not(.dropdown-toggle).qq-upload-button.btn-primary:not(.modal-btn),
.form-section div.input-btn.qq-upload-button.btn-primary:not(.modal-btn),
.form-section div.qq-upload-button.qq-upload-button.btn-primary:not(.modal-btn) {
  border-color: var(--green);
  color: white;
}
.form-section a.btn.white-btn:before,
.form-section button:not(.dropdown-toggle).btn.white-btn:before,
.form-section div.input-btn.btn.white-btn:before,
.form-section div.qq-upload-button.btn.white-btn:before,
.form-section a.qq-upload-button.white-btn:before,
.form-section button:not(.dropdown-toggle).qq-upload-button.white-btn:before,
.form-section div.input-btn.qq-upload-button.white-btn:before,
.form-section div.qq-upload-button.qq-upload-button.white-btn:before,
.form-section a.btn.btn-primary:not(.modal-btn):before,
.form-section button:not(.dropdown-toggle).btn.btn-primary:not(.modal-btn):before,
.form-section div.input-btn.btn.btn-primary:not(.modal-btn):before,
.form-section div.qq-upload-button.btn.btn-primary:not(.modal-btn):before,
.form-section a.qq-upload-button.btn-primary:not(.modal-btn):before,
.form-section button:not(.dropdown-toggle).qq-upload-button.btn-primary:not(.modal-btn):before,
.form-section div.input-btn.qq-upload-button.btn-primary:not(.modal-btn):before,
.form-section div.qq-upload-button.qq-upload-button.btn-primary:not(.modal-btn):before {
  background: var(--green);
}
.form-section a.btn.white-btn:hover,
.form-section button:not(.dropdown-toggle).btn.white-btn:hover,
.form-section div.input-btn.btn.white-btn:hover,
.form-section div.qq-upload-button.btn.white-btn:hover,
.form-section a.qq-upload-button.white-btn:hover,
.form-section button:not(.dropdown-toggle).qq-upload-button.white-btn:hover,
.form-section div.input-btn.qq-upload-button.white-btn:hover,
.form-section div.qq-upload-button.qq-upload-button.white-btn:hover,
.form-section a.btn.btn-primary:not(.modal-btn):hover,
.form-section button:not(.dropdown-toggle).btn.btn-primary:not(.modal-btn):hover,
.form-section div.input-btn.btn.btn-primary:not(.modal-btn):hover,
.form-section div.qq-upload-button.btn.btn-primary:not(.modal-btn):hover,
.form-section a.qq-upload-button.btn-primary:not(.modal-btn):hover,
.form-section button:not(.dropdown-toggle).qq-upload-button.btn-primary:not(.modal-btn):hover,
.form-section div.input-btn.qq-upload-button.btn-primary:not(.modal-btn):hover,
.form-section div.qq-upload-button.qq-upload-button.btn-primary:not(.modal-btn):hover {
  border-color: var(--green);
}
a.btn.white-btn.invert-btn,
button:not(.dropdown-toggle).btn.white-btn.invert-btn,
div.input-btn.btn.white-btn.invert-btn,
div.qq-upload-button.btn.white-btn.invert-btn,
a.qq-upload-button.white-btn.invert-btn,
button:not(.dropdown-toggle).qq-upload-button.white-btn.invert-btn,
div.input-btn.qq-upload-button.white-btn.invert-btn,
div.qq-upload-button.qq-upload-button.white-btn.invert-btn,
a.btn.btn-primary:not(.modal-btn).invert-btn,
button:not(.dropdown-toggle).btn.btn-primary:not(.modal-btn).invert-btn,
div.input-btn.btn.btn-primary:not(.modal-btn).invert-btn,
div.qq-upload-button.btn.btn-primary:not(.modal-btn).invert-btn,
a.qq-upload-button.btn-primary:not(.modal-btn).invert-btn,
button:not(.dropdown-toggle).qq-upload-button.btn-primary:not(.modal-btn).invert-btn,
div.input-btn.qq-upload-button.btn-primary:not(.modal-btn).invert-btn,
div.qq-upload-button.qq-upload-button.btn-primary:not(.modal-btn).invert-btn {
  color: white;
}
@media (min-width: 768px) {
  a.btn.white-btn.invert-btn,
  button:not(.dropdown-toggle).btn.white-btn.invert-btn,
  div.input-btn.btn.white-btn.invert-btn,
  div.qq-upload-button.btn.white-btn.invert-btn,
  a.qq-upload-button.white-btn.invert-btn,
  button:not(.dropdown-toggle).qq-upload-button.white-btn.invert-btn,
  div.input-btn.qq-upload-button.white-btn.invert-btn,
  div.qq-upload-button.qq-upload-button.white-btn.invert-btn,
  a.btn.btn-primary:not(.modal-btn).invert-btn,
  button:not(.dropdown-toggle).btn.btn-primary:not(.modal-btn).invert-btn,
  div.input-btn.btn.btn-primary:not(.modal-btn).invert-btn,
  div.qq-upload-button.btn.btn-primary:not(.modal-btn).invert-btn,
  a.qq-upload-button.btn-primary:not(.modal-btn).invert-btn,
  button:not(.dropdown-toggle).qq-upload-button.btn-primary:not(.modal-btn).invert-btn,
  div.input-btn.qq-upload-button.btn-primary:not(.modal-btn).invert-btn,
  div.qq-upload-button.qq-upload-button.btn-primary:not(.modal-btn).invert-btn {
    padding: 6px 20px;
    font-size: var(--desktopFontSize);
  }
}
a.btn.white-btn.invert-btn:before,
button:not(.dropdown-toggle).btn.white-btn.invert-btn:before,
div.input-btn.btn.white-btn.invert-btn:before,
div.qq-upload-button.btn.white-btn.invert-btn:before,
a.qq-upload-button.white-btn.invert-btn:before,
button:not(.dropdown-toggle).qq-upload-button.white-btn.invert-btn:before,
div.input-btn.qq-upload-button.white-btn.invert-btn:before,
div.qq-upload-button.qq-upload-button.white-btn.invert-btn:before,
a.btn.btn-primary:not(.modal-btn).invert-btn:before,
button:not(.dropdown-toggle).btn.btn-primary:not(.modal-btn).invert-btn:before,
div.input-btn.btn.btn-primary:not(.modal-btn).invert-btn:before,
div.qq-upload-button.btn.btn-primary:not(.modal-btn).invert-btn:before,
a.qq-upload-button.btn-primary:not(.modal-btn).invert-btn:before,
button:not(.dropdown-toggle).qq-upload-button.btn-primary:not(.modal-btn).invert-btn:before,
div.input-btn.qq-upload-button.btn-primary:not(.modal-btn).invert-btn:before,
div.qq-upload-button.qq-upload-button.btn-primary:not(.modal-btn).invert-btn:before {
  animation: btnHoverOn 0.2s forwards linear;
}
html.no-touchevents a.btn.white-btn.invert-btn:hover,
html.no-touchevents button:not(.dropdown-toggle).btn.white-btn.invert-btn:hover,
html.no-touchevents div.input-btn.btn.white-btn.invert-btn:hover,
html.no-touchevents div.qq-upload-button.btn.white-btn.invert-btn:hover,
html.no-touchevents a.qq-upload-button.white-btn.invert-btn:hover,
html.no-touchevents button:not(.dropdown-toggle).qq-upload-button.white-btn.invert-btn:hover,
html.no-touchevents div.input-btn.qq-upload-button.white-btn.invert-btn:hover,
html.no-touchevents div.qq-upload-button.qq-upload-button.white-btn.invert-btn:hover,
html.no-touchevents a.btn.btn-primary:not(.modal-btn).invert-btn:hover,
html.no-touchevents button:not(.dropdown-toggle).btn.btn-primary:not(.modal-btn).invert-btn:hover,
html.no-touchevents div.input-btn.btn.btn-primary:not(.modal-btn).invert-btn:hover,
html.no-touchevents div.qq-upload-button.btn.btn-primary:not(.modal-btn).invert-btn:hover,
html.no-touchevents a.qq-upload-button.btn-primary:not(.modal-btn).invert-btn:hover,
html.no-touchevents button:not(.dropdown-toggle).qq-upload-button.btn-primary:not(.modal-btn).invert-btn:hover,
html.no-touchevents div.input-btn.qq-upload-button.btn-primary:not(.modal-btn).invert-btn:hover,
html.no-touchevents div.qq-upload-button.qq-upload-button.btn-primary:not(.modal-btn).invert-btn:hover,
html.no-touchevents .impact-story-banner:hover a.btn.white-btn.invert-btn,
html.no-touchevents .impact-story-banner:hover button:not(.dropdown-toggle).btn.white-btn.invert-btn,
html.no-touchevents .impact-story-banner:hover div.input-btn.btn.white-btn.invert-btn,
html.no-touchevents .impact-story-banner:hover div.qq-upload-button.btn.white-btn.invert-btn,
html.no-touchevents .impact-story-banner:hover a.qq-upload-button.white-btn.invert-btn,
html.no-touchevents .impact-story-banner:hover button:not(.dropdown-toggle).qq-upload-button.white-btn.invert-btn,
html.no-touchevents .impact-story-banner:hover div.input-btn.qq-upload-button.white-btn.invert-btn,
html.no-touchevents .impact-story-banner:hover div.qq-upload-button.qq-upload-button.white-btn.invert-btn,
html.no-touchevents .impact-story-banner:hover a.btn.btn-primary:not(.modal-btn).invert-btn,
html.no-touchevents .impact-story-banner:hover button:not(.dropdown-toggle).btn.btn-primary:not(.modal-btn).invert-btn,
html.no-touchevents .impact-story-banner:hover div.input-btn.btn.btn-primary:not(.modal-btn).invert-btn,
html.no-touchevents .impact-story-banner:hover div.qq-upload-button.btn.btn-primary:not(.modal-btn).invert-btn,
html.no-touchevents .impact-story-banner:hover a.qq-upload-button.btn-primary:not(.modal-btn).invert-btn,
html.no-touchevents .impact-story-banner:hover button:not(.dropdown-toggle).qq-upload-button.btn-primary:not(.modal-btn).invert-btn,
html.no-touchevents .impact-story-banner:hover div.input-btn.qq-upload-button.btn-primary:not(.modal-btn).invert-btn,
html.no-touchevents .impact-story-banner:hover div.qq-upload-button.qq-upload-button.btn-primary:not(.modal-btn).invert-btn {
  color: var(--grey);
}
html.no-touchevents a.btn.white-btn.invert-btn:hover:before,
html.no-touchevents button:not(.dropdown-toggle).btn.white-btn.invert-btn:hover:before,
html.no-touchevents div.input-btn.btn.white-btn.invert-btn:hover:before,
html.no-touchevents div.qq-upload-button.btn.white-btn.invert-btn:hover:before,
html.no-touchevents a.qq-upload-button.white-btn.invert-btn:hover:before,
html.no-touchevents button:not(.dropdown-toggle).qq-upload-button.white-btn.invert-btn:hover:before,
html.no-touchevents div.input-btn.qq-upload-button.white-btn.invert-btn:hover:before,
html.no-touchevents div.qq-upload-button.qq-upload-button.white-btn.invert-btn:hover:before,
html.no-touchevents a.btn.btn-primary:not(.modal-btn).invert-btn:hover:before,
html.no-touchevents button:not(.dropdown-toggle).btn.btn-primary:not(.modal-btn).invert-btn:hover:before,
html.no-touchevents div.input-btn.btn.btn-primary:not(.modal-btn).invert-btn:hover:before,
html.no-touchevents div.qq-upload-button.btn.btn-primary:not(.modal-btn).invert-btn:hover:before,
html.no-touchevents a.qq-upload-button.btn-primary:not(.modal-btn).invert-btn:hover:before,
html.no-touchevents button:not(.dropdown-toggle).qq-upload-button.btn-primary:not(.modal-btn).invert-btn:hover:before,
html.no-touchevents div.input-btn.qq-upload-button.btn-primary:not(.modal-btn).invert-btn:hover:before,
html.no-touchevents div.qq-upload-button.qq-upload-button.btn-primary:not(.modal-btn).invert-btn:hover:before,
html.no-touchevents .impact-story-banner:hover a.btn.white-btn.invert-btn:before,
html.no-touchevents .impact-story-banner:hover button:not(.dropdown-toggle).btn.white-btn.invert-btn:before,
html.no-touchevents .impact-story-banner:hover div.input-btn.btn.white-btn.invert-btn:before,
html.no-touchevents .impact-story-banner:hover div.qq-upload-button.btn.white-btn.invert-btn:before,
html.no-touchevents .impact-story-banner:hover a.qq-upload-button.white-btn.invert-btn:before,
html.no-touchevents .impact-story-banner:hover button:not(.dropdown-toggle).qq-upload-button.white-btn.invert-btn:before,
html.no-touchevents .impact-story-banner:hover div.input-btn.qq-upload-button.white-btn.invert-btn:before,
html.no-touchevents .impact-story-banner:hover div.qq-upload-button.qq-upload-button.white-btn.invert-btn:before,
html.no-touchevents .impact-story-banner:hover a.btn.btn-primary:not(.modal-btn).invert-btn:before,
html.no-touchevents .impact-story-banner:hover button:not(.dropdown-toggle).btn.btn-primary:not(.modal-btn).invert-btn:before,
html.no-touchevents .impact-story-banner:hover div.input-btn.btn.btn-primary:not(.modal-btn).invert-btn:before,
html.no-touchevents .impact-story-banner:hover div.qq-upload-button.btn.btn-primary:not(.modal-btn).invert-btn:before,
html.no-touchevents .impact-story-banner:hover a.qq-upload-button.btn-primary:not(.modal-btn).invert-btn:before,
html.no-touchevents .impact-story-banner:hover button:not(.dropdown-toggle).qq-upload-button.btn-primary:not(.modal-btn).invert-btn:before,
html.no-touchevents .impact-story-banner:hover div.input-btn.qq-upload-button.btn-primary:not(.modal-btn).invert-btn:before,
html.no-touchevents .impact-story-banner:hover div.qq-upload-button.qq-upload-button.btn-primary:not(.modal-btn).invert-btn:before {
  animation: btnHoverOff 0.2s forwards linear;
}
html.touchevents a.btn.white-btn.invert-btn:hover,
html.touchevents button:not(.dropdown-toggle).btn.white-btn.invert-btn:hover,
html.touchevents div.input-btn.btn.white-btn.invert-btn:hover,
html.touchevents div.qq-upload-button.btn.white-btn.invert-btn:hover,
html.touchevents a.qq-upload-button.white-btn.invert-btn:hover,
html.touchevents button:not(.dropdown-toggle).qq-upload-button.white-btn.invert-btn:hover,
html.touchevents div.input-btn.qq-upload-button.white-btn.invert-btn:hover,
html.touchevents div.qq-upload-button.qq-upload-button.white-btn.invert-btn:hover,
html.touchevents a.btn.btn-primary:not(.modal-btn).invert-btn:hover,
html.touchevents button:not(.dropdown-toggle).btn.btn-primary:not(.modal-btn).invert-btn:hover,
html.touchevents div.input-btn.btn.btn-primary:not(.modal-btn).invert-btn:hover,
html.touchevents div.qq-upload-button.btn.btn-primary:not(.modal-btn).invert-btn:hover,
html.touchevents a.qq-upload-button.btn-primary:not(.modal-btn).invert-btn:hover,
html.touchevents button:not(.dropdown-toggle).qq-upload-button.btn-primary:not(.modal-btn).invert-btn:hover,
html.touchevents div.input-btn.qq-upload-button.btn-primary:not(.modal-btn).invert-btn:hover,
html.touchevents div.qq-upload-button.qq-upload-button.btn-primary:not(.modal-btn).invert-btn:hover,
html.touchevents .impact-story-banner:hover a.btn.white-btn.invert-btn,
html.touchevents .impact-story-banner:hover button:not(.dropdown-toggle).btn.white-btn.invert-btn,
html.touchevents .impact-story-banner:hover div.input-btn.btn.white-btn.invert-btn,
html.touchevents .impact-story-banner:hover div.qq-upload-button.btn.white-btn.invert-btn,
html.touchevents .impact-story-banner:hover a.qq-upload-button.white-btn.invert-btn,
html.touchevents .impact-story-banner:hover button:not(.dropdown-toggle).qq-upload-button.white-btn.invert-btn,
html.touchevents .impact-story-banner:hover div.input-btn.qq-upload-button.white-btn.invert-btn,
html.touchevents .impact-story-banner:hover div.qq-upload-button.qq-upload-button.white-btn.invert-btn,
html.touchevents .impact-story-banner:hover a.btn.btn-primary:not(.modal-btn).invert-btn,
html.touchevents .impact-story-banner:hover button:not(.dropdown-toggle).btn.btn-primary:not(.modal-btn).invert-btn,
html.touchevents .impact-story-banner:hover div.input-btn.btn.btn-primary:not(.modal-btn).invert-btn,
html.touchevents .impact-story-banner:hover div.qq-upload-button.btn.btn-primary:not(.modal-btn).invert-btn,
html.touchevents .impact-story-banner:hover a.qq-upload-button.btn-primary:not(.modal-btn).invert-btn,
html.touchevents .impact-story-banner:hover button:not(.dropdown-toggle).qq-upload-button.btn-primary:not(.modal-btn).invert-btn,
html.touchevents .impact-story-banner:hover div.input-btn.qq-upload-button.btn-primary:not(.modal-btn).invert-btn,
html.touchevents .impact-story-banner:hover div.qq-upload-button.qq-upload-button.btn-primary:not(.modal-btn).invert-btn {
  color: white;
}
a.btn.request-btn,
button:not(.dropdown-toggle).btn.request-btn,
div.input-btn.btn.request-btn,
div.qq-upload-button.btn.request-btn,
a.qq-upload-button.request-btn,
button:not(.dropdown-toggle).qq-upload-button.request-btn,
div.input-btn.qq-upload-button.request-btn,
div.qq-upload-button.qq-upload-button.request-btn {
  padding: 1px 29px;
  font: 300 16px/26px var(--defaultFonts);
  border: 1px solid var(--green);
  background: none;
}
@media (max-width: 767px) {
  a.btn.request-btn,
  button:not(.dropdown-toggle).btn.request-btn,
  div.input-btn.btn.request-btn,
  div.qq-upload-button.btn.request-btn,
  a.qq-upload-button.request-btn,
  button:not(.dropdown-toggle).qq-upload-button.request-btn,
  div.input-btn.qq-upload-button.request-btn,
  div.qq-upload-button.qq-upload-button.request-btn {
    padding: 1px 11px;
  }
}
@media (max-width: 991px) {
  a.btn.request-btn,
  button:not(.dropdown-toggle).btn.request-btn,
  div.input-btn.btn.request-btn,
  div.qq-upload-button.btn.request-btn,
  a.qq-upload-button.request-btn,
  button:not(.dropdown-toggle).qq-upload-button.request-btn,
  div.input-btn.qq-upload-button.request-btn,
  div.qq-upload-button.qq-upload-button.request-btn {
    order: 1;
  }
}
a.btn.request-btn:before,
button:not(.dropdown-toggle).btn.request-btn:before,
div.input-btn.btn.request-btn:before,
div.qq-upload-button.btn.request-btn:before,
a.qq-upload-button.request-btn:before,
button:not(.dropdown-toggle).qq-upload-button.request-btn:before,
div.input-btn.qq-upload-button.request-btn:before,
div.qq-upload-button.qq-upload-button.request-btn:before {
  background: var(--green);
}
a.btn.request-btn:hover,
button:not(.dropdown-toggle).btn.request-btn:hover,
div.input-btn.btn.request-btn:hover,
div.qq-upload-button.btn.request-btn:hover,
a.qq-upload-button.request-btn:hover,
button:not(.dropdown-toggle).qq-upload-button.request-btn:hover,
div.input-btn.qq-upload-button.request-btn:hover,
div.qq-upload-button.qq-upload-button.request-btn:hover {
  border-color: var(--green);
}
html.no-touchevents a.btn.request-btn:hover,
html.no-touchevents button:not(.dropdown-toggle).btn.request-btn:hover,
html.no-touchevents div.input-btn.btn.request-btn:hover,
html.no-touchevents div.qq-upload-button.btn.request-btn:hover,
html.no-touchevents a.qq-upload-button.request-btn:hover,
html.no-touchevents button:not(.dropdown-toggle).qq-upload-button.request-btn:hover,
html.no-touchevents div.input-btn.qq-upload-button.request-btn:hover,
html.no-touchevents div.qq-upload-button.qq-upload-button.request-btn:hover {
  color: var(--green);
}
a.btn.square-btn,
button:not(.dropdown-toggle).btn.square-btn,
div.input-btn.btn.square-btn,
div.qq-upload-button.btn.square-btn,
a.qq-upload-button.square-btn,
button:not(.dropdown-toggle).qq-upload-button.square-btn,
div.input-btn.qq-upload-button.square-btn,
div.qq-upload-button.qq-upload-button.square-btn {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--mediumGrey);
  background: var(--cepheidBlue);
  color: var(--grey);
  border-radius: 0;
  padding: 15px 25px;
}
@media (max-width: 767px) {
  a.btn.square-btn,
  button:not(.dropdown-toggle).btn.square-btn,
  div.input-btn.btn.square-btn,
  div.qq-upload-button.btn.square-btn,
  a.qq-upload-button.square-btn,
  button:not(.dropdown-toggle).qq-upload-button.square-btn,
  div.input-btn.qq-upload-button.square-btn,
  div.qq-upload-button.qq-upload-button.square-btn {
    padding: 10px 20px;
  }
}
a.btn.square-btn .svg,
button:not(.dropdown-toggle).btn.square-btn .svg,
div.input-btn.btn.square-btn .svg,
div.qq-upload-button.btn.square-btn .svg,
a.qq-upload-button.square-btn .svg,
button:not(.dropdown-toggle).qq-upload-button.square-btn .svg,
div.input-btn.qq-upload-button.square-btn .svg,
div.qq-upload-button.qq-upload-button.square-btn .svg {
  background: white;
  border-radius: 100%;
}
a.btn.square-btn .svg svg,
button:not(.dropdown-toggle).btn.square-btn .svg svg,
div.input-btn.btn.square-btn .svg svg,
div.qq-upload-button.btn.square-btn .svg svg,
a.qq-upload-button.square-btn .svg svg,
button:not(.dropdown-toggle).qq-upload-button.square-btn .svg svg,
div.input-btn.qq-upload-button.square-btn .svg svg,
div.qq-upload-button.qq-upload-button.square-btn .svg svg {
  width: 30px;
  height: 30px;
  padding: 5px;
  box-sizing: content-box;
}
a.btn.square-btn .svg svg path,
button:not(.dropdown-toggle).btn.square-btn .svg svg path,
div.input-btn.btn.square-btn .svg svg path,
div.qq-upload-button.btn.square-btn .svg svg path,
a.qq-upload-button.square-btn .svg svg path,
button:not(.dropdown-toggle).qq-upload-button.square-btn .svg svg path,
div.input-btn.qq-upload-button.square-btn .svg svg path,
div.qq-upload-button.qq-upload-button.square-btn .svg svg path {
  fill: var(--cepheidBlue);
  transition: all 0.2s;
}
a.btn.square-btn h3,
button:not(.dropdown-toggle).btn.square-btn h3,
div.input-btn.btn.square-btn h3,
div.qq-upload-button.btn.square-btn h3,
a.qq-upload-button.square-btn h3,
button:not(.dropdown-toggle).qq-upload-button.square-btn h3,
div.input-btn.qq-upload-button.square-btn h3,
div.qq-upload-button.qq-upload-button.square-btn h3 {
  margin: 0;
  width: 170px;
}
a.btn.square-btn:before,
button:not(.dropdown-toggle).btn.square-btn:before,
div.input-btn.btn.square-btn:before,
div.qq-upload-button.btn.square-btn:before,
a.qq-upload-button.square-btn:before,
button:not(.dropdown-toggle).qq-upload-button.square-btn:before,
div.input-btn.qq-upload-button.square-btn:before,
div.qq-upload-button.qq-upload-button.square-btn:before {
  background: white;
}
html.no-touchevents a.btn.square-btn:hover,
html.no-touchevents button:not(.dropdown-toggle).btn.square-btn:hover,
html.no-touchevents div.input-btn.btn.square-btn:hover,
html.no-touchevents div.qq-upload-button.btn.square-btn:hover,
html.no-touchevents a.qq-upload-button.square-btn:hover,
html.no-touchevents button:not(.dropdown-toggle).qq-upload-button.square-btn:hover,
html.no-touchevents div.input-btn.qq-upload-button.square-btn:hover,
html.no-touchevents div.qq-upload-button.qq-upload-button.square-btn:hover,
html.no-touchevents a.btn.square-btn.hover,
html.no-touchevents button:not(.dropdown-toggle).btn.square-btn.hover,
html.no-touchevents div.input-btn.btn.square-btn.hover,
html.no-touchevents div.qq-upload-button.btn.square-btn.hover,
html.no-touchevents a.qq-upload-button.square-btn.hover,
html.no-touchevents button:not(.dropdown-toggle).qq-upload-button.square-btn.hover,
html.no-touchevents div.input-btn.qq-upload-button.square-btn.hover,
html.no-touchevents div.qq-upload-button.qq-upload-button.square-btn.hover {
  color: white;
}
html.touchevents a.btn.square-btn:hover,
html.touchevents button:not(.dropdown-toggle).btn.square-btn:hover,
html.touchevents div.input-btn.btn.square-btn:hover,
html.touchevents div.qq-upload-button.btn.square-btn:hover,
html.touchevents a.qq-upload-button.square-btn:hover,
html.touchevents button:not(.dropdown-toggle).qq-upload-button.square-btn:hover,
html.touchevents div.input-btn.qq-upload-button.square-btn:hover,
html.touchevents div.qq-upload-button.qq-upload-button.square-btn:hover,
html.touchevents a.btn.square-btn.hover,
html.touchevents button:not(.dropdown-toggle).btn.square-btn.hover,
html.touchevents div.input-btn.btn.square-btn.hover,
html.touchevents div.qq-upload-button.btn.square-btn.hover,
html.touchevents a.qq-upload-button.square-btn.hover,
html.touchevents button:not(.dropdown-toggle).qq-upload-button.square-btn.hover,
html.touchevents div.input-btn.qq-upload-button.square-btn.hover,
html.touchevents div.qq-upload-button.qq-upload-button.square-btn.hover {
  color: var(--grey);
}
a.btn.square-btn:hover .svg svg path,
button:not(.dropdown-toggle).btn.square-btn:hover .svg svg path,
div.input-btn.btn.square-btn:hover .svg svg path,
div.qq-upload-button.btn.square-btn:hover .svg svg path,
a.qq-upload-button.square-btn:hover .svg svg path,
button:not(.dropdown-toggle).qq-upload-button.square-btn:hover .svg svg path,
div.input-btn.qq-upload-button.square-btn:hover .svg svg path,
div.qq-upload-button.qq-upload-button.square-btn:hover .svg svg path,
a.btn.square-btn.hover .svg svg path,
button:not(.dropdown-toggle).btn.square-btn.hover .svg svg path,
div.input-btn.btn.square-btn.hover .svg svg path,
div.qq-upload-button.btn.square-btn.hover .svg svg path,
a.qq-upload-button.square-btn.hover .svg svg path,
button:not(.dropdown-toggle).qq-upload-button.square-btn.hover .svg svg path,
div.input-btn.qq-upload-button.square-btn.hover .svg svg path,
div.qq-upload-button.qq-upload-button.square-btn.hover .svg svg path {
  fill: var(--yellow);
}
a.btn.video-btn,
button:not(.dropdown-toggle).btn.video-btn,
div.input-btn.btn.video-btn,
div.qq-upload-button.btn.video-btn,
a.qq-upload-button.video-btn,
button:not(.dropdown-toggle).qq-upload-button.video-btn,
div.input-btn.qq-upload-button.video-btn,
div.qq-upload-button.qq-upload-button.video-btn,
a.btn.download-btn,
button:not(.dropdown-toggle).btn.download-btn,
div.input-btn.btn.download-btn,
div.qq-upload-button.btn.download-btn,
a.qq-upload-button.download-btn,
button:not(.dropdown-toggle).qq-upload-button.download-btn,
div.input-btn.qq-upload-button.download-btn,
div.qq-upload-button.qq-upload-button.download-btn,
a.btn.document-btn,
button:not(.dropdown-toggle).btn.document-btn,
div.input-btn.btn.document-btn,
div.qq-upload-button.btn.document-btn,
a.qq-upload-button.document-btn,
button:not(.dropdown-toggle).qq-upload-button.document-btn,
div.input-btn.qq-upload-button.document-btn,
div.qq-upload-button.qq-upload-button.document-btn {
  flex-direction: row-reverse;
  justify-content: flex-end;
}
a.btn.video-btn:after,
button:not(.dropdown-toggle).btn.video-btn:after,
div.input-btn.btn.video-btn:after,
div.qq-upload-button.btn.video-btn:after,
a.qq-upload-button.video-btn:after,
button:not(.dropdown-toggle).qq-upload-button.video-btn:after,
div.input-btn.qq-upload-button.video-btn:after,
div.qq-upload-button.qq-upload-button.video-btn:after,
a.btn.download-btn:after,
button:not(.dropdown-toggle).btn.download-btn:after,
div.input-btn.btn.download-btn:after,
div.qq-upload-button.btn.download-btn:after,
a.qq-upload-button.download-btn:after,
button:not(.dropdown-toggle).qq-upload-button.download-btn:after,
div.input-btn.qq-upload-button.download-btn:after,
div.qq-upload-button.qq-upload-button.download-btn:after,
a.btn.document-btn:after,
button:not(.dropdown-toggle).btn.document-btn:after,
div.input-btn.btn.document-btn:after,
div.qq-upload-button.btn.document-btn:after,
a.qq-upload-button.document-btn:after,
button:not(.dropdown-toggle).qq-upload-button.document-btn:after,
div.input-btn.qq-upload-button.document-btn:after,
div.qq-upload-button.qq-upload-button.document-btn:after {
  content: "";
  display: inline-block;
  width: 40px;
  min-width: 40px;
  height: 40px;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 20px;
}
a.btn.video-btn .document-btn-title,
button:not(.dropdown-toggle).btn.video-btn .document-btn-title,
div.input-btn.btn.video-btn .document-btn-title,
div.qq-upload-button.btn.video-btn .document-btn-title,
a.qq-upload-button.video-btn .document-btn-title,
button:not(.dropdown-toggle).qq-upload-button.video-btn .document-btn-title,
div.input-btn.qq-upload-button.video-btn .document-btn-title,
div.qq-upload-button.qq-upload-button.video-btn .document-btn-title,
a.btn.download-btn .document-btn-title,
button:not(.dropdown-toggle).btn.download-btn .document-btn-title,
div.input-btn.btn.download-btn .document-btn-title,
div.qq-upload-button.btn.download-btn .document-btn-title,
a.qq-upload-button.download-btn .document-btn-title,
button:not(.dropdown-toggle).qq-upload-button.download-btn .document-btn-title,
div.input-btn.qq-upload-button.download-btn .document-btn-title,
div.qq-upload-button.qq-upload-button.download-btn .document-btn-title,
a.btn.document-btn .document-btn-title,
button:not(.dropdown-toggle).btn.document-btn .document-btn-title,
div.input-btn.btn.document-btn .document-btn-title,
div.qq-upload-button.btn.document-btn .document-btn-title,
a.qq-upload-button.document-btn .document-btn-title,
button:not(.dropdown-toggle).qq-upload-button.document-btn .document-btn-title,
div.input-btn.qq-upload-button.document-btn .document-btn-title,
div.qq-upload-button.qq-upload-button.document-btn .document-btn-title {
  font-size: 0.8rem;
  font-weight: 200;
}
@media (max-width: 767px) {
  a.btn.video-btn:after,
  button:not(.dropdown-toggle).btn.video-btn:after,
  div.input-btn.btn.video-btn:after,
  div.qq-upload-button.btn.video-btn:after,
  a.qq-upload-button.video-btn:after,
  button:not(.dropdown-toggle).qq-upload-button.video-btn:after,
  div.input-btn.qq-upload-button.video-btn:after,
  div.qq-upload-button.qq-upload-button.video-btn:after,
  a.btn.download-btn:after,
  button:not(.dropdown-toggle).btn.download-btn:after,
  div.input-btn.btn.download-btn:after,
  div.qq-upload-button.btn.download-btn:after,
  a.qq-upload-button.download-btn:after,
  button:not(.dropdown-toggle).qq-upload-button.download-btn:after,
  div.input-btn.qq-upload-button.download-btn:after,
  div.qq-upload-button.qq-upload-button.download-btn:after,
  a.btn.document-btn:after,
  button:not(.dropdown-toggle).btn.document-btn:after,
  div.input-btn.btn.document-btn:after,
  div.qq-upload-button.btn.document-btn:after,
  a.qq-upload-button.document-btn:after,
  button:not(.dropdown-toggle).qq-upload-button.document-btn:after,
  div.input-btn.qq-upload-button.document-btn:after,
  div.qq-upload-button.qq-upload-button.document-btn:after {
    width: 19px;
    min-width: 19px;
    height: 19px;
    margin-right: 10px;
  }
}
a.btn.video-btn:after,
button:not(.dropdown-toggle).btn.video-btn:after,
div.input-btn.btn.video-btn:after,
div.qq-upload-button.btn.video-btn:after,
a.qq-upload-button.video-btn:after,
button:not(.dropdown-toggle).qq-upload-button.video-btn:after,
div.input-btn.qq-upload-button.video-btn:after,
div.qq-upload-button.qq-upload-button.video-btn:after {
  background-image: url(../icons/play.svg);
}
@media (min-width: 768px) {
  a.btn.ordering-btn,
  button:not(.dropdown-toggle).btn.ordering-btn,
  div.input-btn.btn.ordering-btn,
  div.qq-upload-button.btn.ordering-btn,
  a.qq-upload-button.ordering-btn,
  button:not(.dropdown-toggle).qq-upload-button.ordering-btn,
  div.input-btn.qq-upload-button.ordering-btn,
  div.qq-upload-button.qq-upload-button.ordering-btn {
    font-size: 18px;
  }
}
a.btn.goto-store-btn,
button:not(.dropdown-toggle).btn.goto-store-btn,
div.input-btn.btn.goto-store-btn,
div.qq-upload-button.btn.goto-store-btn,
a.qq-upload-button.goto-store-btn,
button:not(.dropdown-toggle).qq-upload-button.goto-store-btn,
div.input-btn.qq-upload-button.goto-store-btn,
div.qq-upload-button.qq-upload-button.goto-store-btn {
  flex-direction: row-reverse;
  justify-content: flex-end;
  font-size: 18px;
}
a.btn.goto-store-btn:after,
button:not(.dropdown-toggle).btn.goto-store-btn:after,
div.input-btn.btn.goto-store-btn:after,
div.qq-upload-button.btn.goto-store-btn:after,
a.qq-upload-button.goto-store-btn:after,
button:not(.dropdown-toggle).qq-upload-button.goto-store-btn:after,
div.input-btn.qq-upload-button.goto-store-btn:after,
div.qq-upload-button.qq-upload-button.goto-store-btn:after {
  content: "";
  display: inline-block;
  width: 20px;
  min-width: 20px;
  height: 20px;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 10px;
}
a.btn.goto-store-btn:after,
button:not(.dropdown-toggle).btn.goto-store-btn:after,
div.input-btn.btn.goto-store-btn:after,
div.qq-upload-button.btn.goto-store-btn:after,
a.qq-upload-button.goto-store-btn:after,
button:not(.dropdown-toggle).qq-upload-button.goto-store-btn:after,
div.input-btn.qq-upload-button.goto-store-btn:after,
div.qq-upload-button.qq-upload-button.goto-store-btn:after {
  background-image: url(../icons/cart.svg);
}
@media (min-width: 768px) {
  a.btn.goto-store-btn,
  button:not(.dropdown-toggle).btn.goto-store-btn,
  div.input-btn.btn.goto-store-btn,
  div.qq-upload-button.btn.goto-store-btn,
  a.qq-upload-button.goto-store-btn,
  button:not(.dropdown-toggle).qq-upload-button.goto-store-btn,
  div.input-btn.qq-upload-button.goto-store-btn,
  div.qq-upload-button.qq-upload-button.goto-store-btn {
    padding: 6px 20px;
  }
}
@media (max-width: 767px) {
  a.btn.goto-store-btn:after,
  button:not(.dropdown-toggle).btn.goto-store-btn:after,
  div.input-btn.btn.goto-store-btn:after,
  div.qq-upload-button.btn.goto-store-btn:after,
  a.qq-upload-button.goto-store-btn:after,
  button:not(.dropdown-toggle).qq-upload-button.goto-store-btn:after,
  div.input-btn.qq-upload-button.goto-store-btn:after,
  div.qq-upload-button.qq-upload-button.goto-store-btn:after {
    width: 25px;
    min-width: 25px;
    height: 25px;
  }
}
a.btn.download-btn:after,
button:not(.dropdown-toggle).btn.download-btn:after,
div.input-btn.btn.download-btn:after,
div.qq-upload-button.btn.download-btn:after,
a.qq-upload-button.download-btn:after,
button:not(.dropdown-toggle).qq-upload-button.download-btn:after,
div.input-btn.qq-upload-button.download-btn:after,
div.qq-upload-button.qq-upload-button.download-btn:after,
a.btn.document-btn:after,
button:not(.dropdown-toggle).btn.document-btn:after,
div.input-btn.btn.document-btn:after,
div.qq-upload-button.btn.document-btn:after,
a.qq-upload-button.document-btn:after,
button:not(.dropdown-toggle).qq-upload-button.document-btn:after,
div.input-btn.qq-upload-button.document-btn:after,
div.qq-upload-button.qq-upload-button.document-btn:after {
  width: 25px;
  min-width: 25px;
}
@media (max-width: 767px) {
  a.btn.download-btn:after,
  button:not(.dropdown-toggle).btn.download-btn:after,
  div.input-btn.btn.download-btn:after,
  div.qq-upload-button.btn.download-btn:after,
  a.qq-upload-button.download-btn:after,
  button:not(.dropdown-toggle).qq-upload-button.download-btn:after,
  div.input-btn.qq-upload-button.download-btn:after,
  div.qq-upload-button.qq-upload-button.download-btn:after,
  a.btn.document-btn:after,
  button:not(.dropdown-toggle).btn.document-btn:after,
  div.input-btn.btn.document-btn:after,
  div.qq-upload-button.btn.document-btn:after,
  a.qq-upload-button.document-btn:after,
  button:not(.dropdown-toggle).qq-upload-button.document-btn:after,
  div.input-btn.qq-upload-button.document-btn:after,
  div.qq-upload-button.qq-upload-button.document-btn:after {
    width: 15px;
    min-width: 15px;
    margin-right: 10px;
  }
}
a.btn.download-btn:after,
button:not(.dropdown-toggle).btn.download-btn:after,
div.input-btn.btn.download-btn:after,
div.qq-upload-button.btn.download-btn:after,
a.qq-upload-button.download-btn:after,
button:not(.dropdown-toggle).qq-upload-button.download-btn:after,
div.input-btn.qq-upload-button.download-btn:after,
div.qq-upload-button.qq-upload-button.download-btn:after {
  background-image: url(../icons/download.svg);
}
a.btn.document-btn:after,
button:not(.dropdown-toggle).btn.document-btn:after,
div.input-btn.btn.document-btn:after,
div.qq-upload-button.btn.document-btn:after,
a.qq-upload-button.document-btn:after,
button:not(.dropdown-toggle).qq-upload-button.document-btn:after,
div.input-btn.qq-upload-button.document-btn:after,
div.qq-upload-button.qq-upload-button.document-btn:after {
  background-image: url(../icons/arrow-cta.svg);
  background-size: auto 22px;
  background-position: right center;
}
@media (max-width: 767px) {
  a.btn.document-btn:after,
  button:not(.dropdown-toggle).btn.document-btn:after,
  div.input-btn.btn.document-btn:after,
  div.qq-upload-button.btn.document-btn:after,
  a.qq-upload-button.document-btn:after,
  button:not(.dropdown-toggle).qq-upload-button.document-btn:after,
  div.input-btn.qq-upload-button.document-btn:after,
  div.qq-upload-button.qq-upload-button.document-btn:after {
    background-size: contain;
  }
}
@keyframes btnHoverOn {
  from {
    left: 0%;
  }
  to {
    left: 100%;
  }
}
@keyframes btnHoverOff {
  from {
    left: -130%;
  }
  to {
    left: 0%;
  }
}
.buttons {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fill, 285px);
  justify-content: center;
  overflow: hidden;
}
.buttons.anim a {
  opacity: 0;
  transition: all 0.5s;
}
.buttons.anim a:first-of-type {
  left: -100px;
}
.buttons.anim a:last-of-type {
  left: 100px;
}
.buttons.anim.visible a {
  left: 0;
  opacity: 1;
}
.button-layout a {
  height: 100%;
  width: 100%;
}
.object-Files a.square-btn,
.template-buttons a.square-btn {
  width: 255px;
  height: 100%;
}
/* purgecss end ignore */

.card-snippet {
  max-width: 300px;
  min-height: 420px;
  height: 100%;
  background: white;
  padding: 30px;
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
  margin: auto;
}
.card-snippet img {
  max-width: 100%;
  height: auto;
}
.card-snippet .counter {
  background: var(--cepheidBlue);
  color: white;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4em;
}
.card-snippet.outline {
  background: none;
  color: white;
  box-shadow: none;
  border: 1px solid white;
  min-height: 170px;
}
.card-snippet.outline .counter {
  background: white;
  color: var(--cepheidBlue);
}

.carousel {
  overflow: hidden;
}
.carousel component {
  display: none;
}
.carousel.quotes .arrow-box {
  padding-top: 15px;
}
.carousel .markers {
  display: grid;
  grid-template-columns: auto;
  grid-auto-flow: column;
  grid-gap: 10px;
  justify-content: center;
  margin-top: 30px;
  position: relative;
  z-index: 3;
}
@media (max-width: 767px) {
  .carousel .markers {
    margin-top: 20px;
  }
}
.carousel .markers.light .marker {
  background: var(--mediumGrey);
}
.carousel .markers.compressed {
  margin: 20px;
}
.carousel .markers .marker {
  height: 20px;
  width: 20px;
  background: var(--grey);
  cursor: pointer;
}
@media (max-width: 767px) {
  .carousel .markers .marker {
    width: 10px;
    height: 10px;
  }
}
.carousel .markers .marker.active {
  background: var(--yellow);
}
.carousel.gallery-multiple {
  margin-bottom: -60px;
}
@media (max-width: 767px) {
  .carousel.gallery-multiple {
    padding-bottom: 30px;
  }
}

footer {
  padding: 40px 0 32px;
  background: var(--grey);
  color: var(--white);
  font-weight: 200;
}
footer .legal {
  font: 300 12px/26px var(--defaultFonts);
  color: var(--mediumGrey);
}
footer h6 {
  font-size: 10px;
}
footer a.white:not(.btn):not(.small-btn):not(.x-btn) {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}
footer a.white:not(.btn):not(.small-btn):not(.x-btn):hover {
  color: var(--grey);
}
footer ul {
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-rows: repeat(5, auto);
  grid-auto-flow: column;
  margin: 0;
}
footer ul a {
  font-size: 16px;
  color: var(--white);
  padding: 5px;
  margin: -5px;
  position: relative;
  z-index: 1;
  transition: color 0.3s;
}
footer ul a:before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  right: 0;
  background: white;
  z-index: -1;
  animation: footerLinkBlur 0.3s;
}
footer ul a:hover {
  color: var(--grey);
}
footer ul a:hover:before {
  left: 0;
  animation: footerLinkFocus 0.3s forwards;
}
footer .legal-links-footer-container ul {
  grid-template-rows: repeat(6, auto);
}
@media (max-width: 767px) {
  footer {
    padding: 0 0 25px;
    text-align: center;
  }
  footer ul,
  footer h6,
  footer .logo,
  footer .quick-links {
    display: none;
  }
}
@keyframes footerLinkFocus {
  to {
    width: 100%;
  }
}
@keyframes footerLinkBlur {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}

.product-header {
  min-height: 400px;
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .product-header {
    min-height: 200px;
  }
}
.product-header h2,
.product-header p {
  margin: 30px 0;
  color: var(--grey);
  font-size: 20px;
  line-height: 25px;
}
@media (max-width: 767px) {
  .product-header h2,
  .product-header p {
    font-size: 16px;
    line-height: 18px;
  }
}
.product-header h1:not([class]) {
  color: var(--cepheidBlue);
}
@media (max-width: 767px) {
  .product-header h5 {
    display: none;
  }
}
.product-header img {
  height: 354px;
  max-width: 100%;
  object-fit: contain;
  display: block;
  margin: auto;
}
.product-header.bg {
  color: white;
  background-size: cover;
}
.product-header.bg h1:not([class]),
.product-header.bg h5:not([class]) {
  color: white;
}
.product-header.bg a {
  color: white;
}
.product-header h1 strong,
.product-header h1 b {
  font-weight: 400;
}
.ordering-info {
  background: var(--lightGrey);
  padding: 30px;
  width: 350px;
  max-width: 100%;
  height: 100%;
}
.ordering-info h4 {
  font-size: 18px;
  font-weight: bold;
}
.ordering-info-header {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ordering-info-header h2 {
  margin: 0;
}
.ordering-info-header .how-to-order a {
  display: inline-flex;
  align-items: center;
  color: var(--mediumGrey);
}
.ordering-info-header .how-to-order a img {
  margin-right: 5px;
}
.product-sections #product-information img,
.sticky-nav-container #product-information img {
  height: auto;
  max-width: 100%;
}
@media (max-width: 767px) {
  .product-sections #product-information hr,
  .sticky-nav-container #product-information hr {
    margin: 15px 0;
  }
}
.product-sections nav.product-sections-nav,
.sticky-nav-container nav.product-sections-nav,
.product-sections nav.sticky-nav,
.sticky-nav-container nav.sticky-nav {
  background: var(--cepheidBlue);
  position: sticky;
  top: 80px;
  z-index: 4;
}
@media (max-width: 767px) {
  .product-sections nav.product-sections-nav,
  .sticky-nav-container nav.product-sections-nav,
  .product-sections nav.sticky-nav,
  .sticky-nav-container nav.sticky-nav {
    top: 70px;
  }
}
.product-sections nav.product-sections-nav .row,
.sticky-nav-container nav.product-sections-nav .row,
.product-sections nav.sticky-nav .row,
.sticky-nav-container nav.sticky-nav .row,
.product-sections nav.product-sections-nav .col,
.sticky-nav-container nav.product-sections-nav .col,
.product-sections nav.sticky-nav .col,
.sticky-nav-container nav.sticky-nav .col {
  margin-top: 0;
  margin-bottom: 0;
}
.product-sections nav.product-sections-nav ul,
.sticky-nav-container nav.product-sections-nav ul,
.product-sections nav.sticky-nav ul,
.sticky-nav-container nav.sticky-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  text-align: center;
  border-bottom: none;
}
@media (max-width: 767px) {
  .product-sections nav.product-sections-nav ul,
  .sticky-nav-container nav.product-sections-nav ul,
  .product-sections nav.sticky-nav ul,
  .sticky-nav-container nav.sticky-nav ul {
    justify-content: space-between;
  }
}
@media (max-width: 480px) {
  .product-sections nav.product-sections-nav ul,
  .sticky-nav-container nav.product-sections-nav ul,
  .product-sections nav.sticky-nav ul,
  .sticky-nav-container nav.sticky-nav ul {
    font-size: 10px;
    line-height: 12px;
    font-weight: bold;
  }
}
.product-sections nav.product-sections-nav ul li,
.sticky-nav-container nav.product-sections-nav ul li,
.product-sections nav.sticky-nav ul li,
.sticky-nav-container nav.sticky-nav ul li {
  margin-right: 60px;
}
.product-sections nav.product-sections-nav ul li:last-of-type,
.sticky-nav-container nav.product-sections-nav ul li:last-of-type,
.product-sections nav.sticky-nav ul li:last-of-type,
.sticky-nav-container nav.sticky-nav ul li:last-of-type {
  margin-right: 0;
}
@media (max-width: 991px) {
  .product-sections nav.product-sections-nav ul li,
  .sticky-nav-container nav.product-sections-nav ul li,
  .product-sections nav.sticky-nav ul li,
  .sticky-nav-container nav.sticky-nav ul li {
    margin-right: 30px;
  }
}
@media (max-width: 480px) {
  .product-sections nav.product-sections-nav ul li,
  .sticky-nav-container nav.product-sections-nav ul li,
  .product-sections nav.sticky-nav ul li,
  .sticky-nav-container nav.sticky-nav ul li {
    margin-right: 15px;
  }
}
.product-sections nav.product-sections-nav ul li a,
.sticky-nav-container nav.product-sections-nav ul li a,
.product-sections nav.sticky-nav ul li a,
.sticky-nav-container nav.sticky-nav ul li a {
  display: inline-block;
  color: white;
  padding: 30px 0;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border-bottom: 0px solid var(--yellow);
  transition: all 0.3s;
  margin: 0;
  overflow: visible;
}
@media (max-width: 480px) {
  .product-sections nav.product-sections-nav ul li a,
  .sticky-nav-container nav.product-sections-nav ul li a,
  .product-sections nav.sticky-nav ul li a,
  .sticky-nav-container nav.sticky-nav ul li a {
    padding: 15px 0;
  }
}
.product-sections nav.product-sections-nav ul li a.active,
.sticky-nav-container nav.product-sections-nav ul li a.active,
.product-sections nav.sticky-nav ul li a.active,
.sticky-nav-container nav.sticky-nav ul li a.active {
  padding-bottom: 20px;
  background: none;
  color: white;
  border: none;
  border-bottom: 10px solid var(--yellow);
}
@media (max-width: 480px) {
  .product-sections nav.product-sections-nav ul li a.active,
  .sticky-nav-container nav.product-sections-nav ul li a.active,
  .product-sections nav.sticky-nav ul li a.active,
  .sticky-nav-container nav.sticky-nav ul li a.active {
    padding-bottom: 10px;
    border-bottom: 5px solid var(--yellow);
  }
}
.product-row h3 {
  margin: 15px 0;
  font-weight: 400;
  font-size: var(--desktopFontSize);
  line-height: var(--desktopLineHeight);
  font-family: var(--defaultFonts);
  color: var(--grey);
}
.product-variants {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: 115px;
}
.product-variants > h4 {
  display: none;
  color: var(--cepheidBlue);
  font-weight: normal;
  margin: 0;
  align-items: center;
  text-transform: uppercase;
}
.product-variants > h4 hr {
  flex-grow: 1;
  margin-left: 15px !important;
}
.product-variants h5:first-child {
  grid-column: 2/3;
}
.product-variants hr {
  grid-column: span 3;
  margin: 0;
  border-top-width: 2px;
}
.product-variants .dimensions,
.product-variants .ordering {
  padding: 20px 0;
}
.product-variants .img {
  padding: 20px;
  background: var(--lightGrey);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.product-variants .img h3 {
  margin: 0;
}
.product-variants .img img {
  margin: 0 auto 10px;
  display: block;
}
@media (max-width: 991px) {
  .product-variants {
    grid-gap: 20px 15px;
    grid-auto-flow: dense;
    grid-template-columns: 1fr 1fr;
  }
  .product-variants > h4.column-header {
    display: flex;
    grid-column: span 2;
  }
  .product-variants > h5,
  .product-variants > hr {
    display: none;
  }
  .product-variants .dimensions,
  .product-variants .ordering {
    padding: 0;
  }
}
@media (max-width: 500px) {
  .product-variants {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  }
  .product-variants [item] {
    grid-column-start: 1 !important;
  }
  .product-variants > h4 {
    grid-column: 1;
    display: flex;
  }
}

@media (max-width: 991px) {
  .support-buttons img {
    margin: auto;
    display: block;
  }
}
.support-buttons .image-container img {
  max-width: 100%;
}
.support-buttons h2 {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .support-buttons hr {
    height: 100%;
    display: block;
    border-right: 1px solid var(--mediumGrey);
    margin: 0 auto;
    width: 0;
  }
}
.support-buttons .support-btns {
  display: grid;
  grid-gap: 30px;
  grid-auto-flow: row;
}
.support-buttons .support-btns a.btn.document-btn {
  justify-content: center;
}
.support-buttons .support-btns a.btn.bold {
  font-weight: bold;
  color: var(--cepheidBlue);
}
.support-buttons .support-btns a.btn.left-align {
  justify-content: start;
}
.support-documents-buttons,
.mini-nav {
  background: #14bef0;
}
.support-documents-buttons .document-buttons a,
.mini-nav .document-buttons a,
.support-documents-buttons .document-buttons a p,
.mini-nav .document-buttons a p {
  width: 100%;
  text-align: center;
}
.support-documents-buttons .pimcore_tag_block,
.mini-nav .pimcore_tag_block {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.support-documents-buttons .pimcore_tag_block div[class*="col"],
.mini-nav .pimcore_tag_block div[class*="col"] {
  max-width: none !important;
  width: 100% !important;
}
.support-documents-buttons .pimcore_tag_block .pimcore_tag_wysiwyg,
.mini-nav .pimcore_tag_block .pimcore_tag_wysiwyg {
  width: 100%;
}
.mini-nav .editmode-custom-id label {
  flex-basis: 300px;
}
.support-document-links .pimcore_tag_block > [data-real-name="documents"] {
  border: 1px solid var(--mediumGrey);
  padding: 30px;
}
.support-document-links .pimcore_tag_block > [data-real-name="products"] {
  width: 33%;
}
.support-document-links .pimcore_tag_block .pimcore_tag_block {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 24px) {
  header .login-my-cepheid {
    order: 0;
    width: 32px;
    height: 32px;
    margin: 0 16px 0 5px;
    position: relative;
  }
  header .login-my-cepheid-headline {
    border-bottom: 2px solid var(--lightGrey);
    text-align: center;
    color: #0E0F11;
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    margin: 10px 0;
    padding-bottom: 10px;
    animation: fadeIn 0.8s ease-in-out;
  }
  header .login-my-cepheid-links {
    padding: 0;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  header .login-my-cepheid-links * {
    color: #0057A8;
    font-size: 12px;
  }
  header .login-my-cepheid-links-item {
    list-style: none;
    padding: 0 15px;
    margin: 15px 0 0 0;
    animation: fadeIn 0.8s ease-in-out;
  }
  header .login-my-cepheid-links-item__icon {
    width: 15px;
    height: 15px;
    margin: 0 12px 0 0;
  }
  header .login-my-cepheid-links-item__link {
    text-decoration: none;
    display: inline-block;
    width: 100%;
  }
  header .login-my-cepheid-links-item:hover {
    cursor: pointer;
    background-color: #E5F5FF;
    border-radius: 15px;
  }
  header .login-my-cepheid-container {
    background-color: var(--white);
    max-width: 171px;
    width: 171px;
    position: absolute;
    right: -70px;
    top: 40px;
    padding: 12px;
    -webkit-box-shadow: 2px 1px 9px 7px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 2px 1px 9px 7px rgba(0, 0, 0, 0.12);
    box-shadow: 2px 1px 9px 7px rgba(0, 0, 0, 0.12);
    border-radius: 5%;
    z-index: 2;
  }
  header .login-my-cepheid-container.open {
    animation: slideDown 0.3s ease-in-out;
  }
  header .login-my-cepheid-container svg {
    top: -19px;
    left: 35.5%;
    position: absolute;
  }
  header .login-my-cepheid-container .triangle {
    fill: #fff;
    stroke: #fff;
    stroke-width: 100;
  }
  header .login-my-cepheid-icon {
    border-radius: 50%;
    text-align: center;
    width: 32px;
    height: 32px;
    position: relative;
  }
  header .login-my-cepheid-icon:hover {
    cursor: pointer;
    background-color: #E5F5FF;
  }
  header .login-my-cepheid-icon:before {
    content: url('/static/icons/login.svg');
    position: absolute;
    left: 0;
    right: 0;
    top: 6px;
  }
  header .login-my-cepheid-icon.open {
    background-color: #E5F5FF;
    transition: 1s all;
  }
}
@media (min-width: 992px) {
  header .login-my-cepheid {
    order: unset;
    margin: 0 30px 0 0;
    width: 20px;
    height: 20px;
  }
  header .login-my-cepheid-container {
    right: -83px;
  }
  header .login-my-cepheid-container svg {
    top: -20px;
    left: 34.5%;
  }
  header .login-my-cepheid-links-item {
    width: unset;
  }
  header .login-my-cepheid-links-item__icon {
    width: 15px;
    height: 15px;
  }
  header .login-my-cepheid-icon {
    padding-top: 5px;
    height: 30px;
  }
}
@keyframes slideDown {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 205px;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: 992px) {
  #nav > button,
  .mobile-footer,
  .more-icon {
    display: none;
  }
}
@media (max-width: 991px) {
  #nav > button {
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
    margin-left: 20px;
    position: relative;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
  }
  #nav > button path {
    transition: all 0.3s;
  }
  #nav > button span {
    display: block;
    width: 20px;
    position: absolute;
  }
  #nav > button:not(.mobileOpen) span.close-icon path:first-of-type {
    transform: translate(-100%, -100%);
  }
  #nav > button:not(.mobileOpen) span.close-icon path:last-of-type {
    transform: translate(100%, -100%);
  }
  #nav > button.mobileOpen span.open-icon path:nth-of-type(odd) {
    transform: translateX(100%);
  }
  #nav > button.mobileOpen span.open-icon path:nth-of-type(even) {
    transform: translateX(-100%);
  }
  #nav > button.mobileOpen span.close-icon {
    display: block;
  }
  #nav {
    order: 2;
  }
  #nav ul {
    margin: 0;
    list-style: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    padding: 30px 60px;
    z-index: -1;
    visibility: hidden;
    transition: visibility 0s 0.5s;
  }
  body.mobileOpen #nav ul {
    height: calc(100vh - 80px);
  }
  #nav ul > li {
    position: relative;
    left: 70vw;
    opacity: 0;
    transition: all 0.4s;
    transition-delay: calc((var(--delay) + 1) * 0.04s);
    margin: 10px 0;
  }
  #nav ul.active-view {
    transition: visibility 0s;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  #nav ul.active-view > li {
    left: 0vw;
  }
  #nav ul.prev-view > li {
    left: -80vw;
  }
  #nav ul.active-view,
  #nav ul.prev-view {
    z-index: 4;
  }
  #nav ul.active-view > li,
  #nav ul.prev-view > li {
    opacity: 1;
  }
  #nav ul a {
    color: white;
    font: 300 20px/30px var(--defaultFonts);
    letter-spacing: 0.5px;
    transition: all 0.2s;
  }
  #nav ul a:hover {
    color: var(--yellow);
  }
  #nav ul li.active:not(.active-trail) > a {
    color: var(--yellow);
  }
  #nav ul li.mobile-header {
    position: relative;
    transition-delay: 0s;
  }
  #nav ul li.mobile-header a {
    font-size: 18px;
    font-weight: bold;
  }
  #nav ul li.mobile-header ~ li a {
    font-size: 16px;
  }
  #nav > ul {
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s, visibility 0s 0.5s;
    z-index: 4;
  }
  #nav > ul:before {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--grey);
    opacity: 0.95;
    z-index: -1;
    pointer-events: none;
  }
  #nav > ul.active-view {
    transition: all 0.5s, visibility 0s 0.5s;
  }
  #nav > ul > li {
    transition: all 0.4s, top 0.4s;
    transition-delay: calc((var(--delay) + 1) * 0.04s), 0.4s;
  }
  #nav > ul.active-view > li,
  #nav > ul.prev-view > li {
    opacity: 0;
    top: 70px;
    margin: 30px 0;
  }
  #nav button.mobile-back {
    position: absolute;
    left: 10px;
    line-height: 1;
    top: 44px;
    margin-left: 0;
    width: auto;
  }
  #nav .more-icon {
    z-index: 5;
    position: fixed;
    bottom: 100px;
    right: 15px;
  }
  #nav button.mobile-back,
  #nav .more-icon {
    visibility: hidden;
    transition: all 0.5s, visibility 0s 0.5s;
    opacity: 0;
  }
  #nav.mobileOpen ul.active-view {
    visibility: visible;
  }
  #nav.mobileOpen > ul {
    transition: all 0.5s;
    visibility: visible;
    opacity: 1;
  }
  #nav.mobileOpen > ul.active-view {
    transition: all 0.5s;
  }
  #nav.mobileOpen > ul > li {
    opacity: 1;
    top: 0px;
    transition-delay: calc((var(--delay) + 1) * 0.04s);
  }
  #nav.mobileOpen > button.mobile-back.visible,
  #nav.mobileOpen > .more-icon.visible {
    transition: all 0.5s;
    visibility: visible;
    opacity: 1;
  }
  .mobile-footer {
    position: fixed;
    bottom: -80px;
    left: 0;
    width: 100%;
    height: 80px;
    background: var(--white);
    transition: bottom 0.5s;
    z-index: 6;
  }
  .mobile-footer .col {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr 1fr 20px;
    grid-gap: 0px;
    height: 80px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .mobile-footer .col a {
    font: 12px/15px var(--defaultFonts);
    color: var(--grey);
    transition: all 0.3s calc(var(--delay) * 0.04s);
    display: block;
  }
  body.search-open .mobile-footer .col a {
    margin-top: -20px;
    opacity: 0;
    transition: all 0.3s calc(1 / var(--delay) * 0.3s);
    pointer-events: none;
  }
  body.mobileOpen .mobile-footer {
    bottom: 0px;
  }
}
@media (max-width: 991px) and (max-width: 370px) {
  .mobile-footer .col {
    grid-gap: 5px;
  }
}

@media (min-width: 992px) {
  header #nav {
    flex-grow: 1;
  }
  header #nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  header #nav ul li.mobile-header {
    display: none;
  }
  header #nav > ul {
    display: flex;
    justify-content: space-between;
    font: 400 16px/30px var(--defaultFonts);
  }
  header #nav > ul > li {
    transition: all 0.3s calc(var(--delay) * 0.04s);
    display: block;
  }
  header #nav > ul > li.hidden {
    display: none;
  }
  header #nav > ul > li > a {
    position: relative;
    display: block;
  }
  header #nav > ul > li > a,
  header #nav > ul > li > a:hover {
    color: var(--grey);
  }
  header #nav > ul > li > a:before {
    content: "";
    border: 0px solid transparent;
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.3s;
    opacity: 0.95;
  }
  header #nav > ul > li .container {
    position: absolute;
    left: 50%;
    top: 100%;
    width: calc(100% - 60px);
    transform: translateX(-50%);
    font-size: 14px;
    line-height: 24px;
  }
  header #nav > ul > li .container > .row {
    margin-bottom: 0;
  }
  header #nav > ul > li .container > .row > .col {
    margin-bottom: 0;
  }
  header #nav > ul > li .container > .row > .col > ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    transition: all 0.4s;
    max-height: 0;
    overflow: hidden;
    box-sizing: content-box;
  }
  header #nav > ul > li .container > .row > .col > ul:before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    width: 100vw;
    height: 100%;
    background: var(--grey);
    z-index: -1;
    opacity: 0.95;
  }
  header #nav > ul > li .container > .row > .col > ul a {
    color: var(--white);
    transition: color 0.2s;
  }
  header #nav > ul > li .container > .row > .col > ul a:hover {
    color: var(--yellow);
  }
  header #nav > ul > li .container > .row > .col > ul li.active:not(.active-trail) > a {
    color: var(--yellow);
  }
  header #nav > ul > li .container > .row > .col > ul > li {
    opacity: 0;
    position: relative;
    top: -30px;
    transition: all 0.3s;
  }
  header #nav > ul > li .container > .row > .col > ul > li > a {
    font-size: 16px;
    font-weight: bold;
  }
  header #nav > ul > li .container > .row > .col > ul > li ul a {
    font-weight: 400;
  }
  header #nav > ul > li.hover > a,
  header #nav > ul > li.active > a {
    color: var(--cepheidBlue);
  }
  header #nav > ul > li.hover > a:before {
    border: 12px solid transparent;
    border-bottom-color: var(--grey);
  }
  header #nav > ul > li.hover .container > .row > .col > ul {
    max-height: calc(100vh - 125px);
    padding-top: 30px;
    margin-bottom: 30px;
  }
  header #nav > ul > li.hover .container > .row > .col > ul > li {
    transition: all 0.3s calc((var(--delay) * 0.075s) + 0.15s);
    top: 0;
    opacity: 1;
  }
  header form.open ~ #nav > ul > li {
    margin-top: -20px;
    opacity: 0;
    transition: all 0.3s calc(1 / var(--delay) * 0.3s);
  }
}
@media (min-width: 992px) and (max-width: 820px) {
  header #nav > ul {
    font-size: 14px;
  }
}

.search {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  margin: 0 30px;
  transition: all 0.3s, visibility 0s 0.3s;
}
@media (max-width: 991px) {
  .search {
    margin: 0;
    position: fixed;
    z-index: 7;
    bottom: -40px;
    transform: translateY(50%);
    opacity: 0;
    visibility: hidden;
  }
  body.mobileOpen .search {
    transition: all 0.5s;
    opacity: 1;
    visibility: visible;
    bottom: 40px;
  }
}
.search button {
  background: none;
  border: none;
  padding: 0;
  position: relative;
  cursor: pointer;
}
.search button svg {
  display: block;
}
.search button svg path {
  transition: all 0.3s;
  stroke: none;
}
.search input {
  position: absolute;
  color: var(--grey);
  right: calc(100% + 30px);
  border: none;
  border-bottom: 0px solid var(--grey);
  background: none;
  width: 0px;
  line-height: 30px;
  transition: width 0.3s, opacity 0s 0.3s, border-bottom-width 0s 0.3s;
  opacity: 0;
  outline: none;
}
.search.open input {
  width: 305px;
  max-width: 60vw;
  border-bottom-width: 2px;
  transition: width 0.3s;
  opacity: 1;
}

header {
  background: var(--white);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 11;
  transition: all 0.3s;
}
header .container,
header div[class*="col"] {
  position: static;
}
header .row {
  margin-bottom: 0 !important;
}
header .row > div {
  margin: 0 !important;
}
header .logo img {
  width: auto;
  max-width: 100%;
  transition: all 0.3s;
  max-height: 80px;
}
header div.navigation {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
header div.navigation.hidden {
  opacity: 0;
  pointer-events: none;
}
header div.navigation nav li a em {
  pointer-events: none;
}
header .profile-language {
  background: var(--lightGrey);
  border-radius: 0 0 5px 5px;
  padding: 9px 15px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  transition: all 0.3s;
}
header .menu {
  padding: 25px 0;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}
@media (max-width: 991px) {
  header .menu {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}
header.scroll {
  box-shadow: 0px 1px 1px -1px var(--grey);
}
header.scroll .logo img {
  max-height: 47px;
}
header.scroll .profile-language {
  margin-top: -45px;
  overflow: hidden;
}
@media (max-width: 991px) {
  header .logo img {
    max-height: 60px;
  }
  header .profile-language {
    font-size: 11px;
  }
  header .menu {
    padding: 20px 0;
  }
  header.scroll .profile-language {
    margin-top: -44px;
  }
}
@media (max-width: 375px) {
  header .profile-language {
    padding: 7px 10px;
  }
  header.scroll .profile-language {
    margin-top: -29px;
  }
}

/* purgecss start ignore */
#language-selector {
  position: relative;
}
#language-selector button {
  padding: 0;
  background: none;
  border: none;
  color: var(--grey);
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
}
#language-selector button:after {
  content: "";
  display: block;
  border: 5px solid transparent;
  border-top-color: var(--yellow);
  border-bottom: none;
  transition: all 0.15s;
  transform-origin: center;
  margin-left: 15px;
}
#language-selector button:hover:after,
#language-selector button.open:after {
  border-top-color: var(--grey);
}
@media (max-width: 375px) {
  #language-selector button:after {
    margin-left: 5px;
  }
}
#language-selector .dropdown {
  position: absolute;
  top: calc(100% + 15px);
  left: 50%;
  transform: translateX(-50%);
  background: var(--white);
  border-radius: 5px;
  filter: drop-shadow(0 0px 1px var(--lightGrey));
  z-index: 6;
}
#language-selector .dropdown:before {
  content: "";
  border: 15px solid transparent;
  border-bottom-color: white;
  position: absolute;
  bottom: 100%;
  left: calc(50% - 15px);
}
#language-selector .dropdown a {
  display: block;
  white-space: nowrap;
  padding: 10px 15px;
  color: var(--grey);
}
#language-selector .dropdown a:hover {
  background: var(--lightGrey);
}
#language-selector .dropdown a:first-child {
  border-radius: 5px 5px 0 0;
}
#language-selector .dropdown a:last-child {
  border-radius: 0 0 5px 5px;
}
#language-selector .dropdown.show-scroll {
  overflow-y: scroll;
  max-height: 500px;
}
#language-selector .dropdown.show-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
#language-selector .dropdown.show-scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
#language-selector .v-enter-active,
#language-selector .v-leave-active {
  transition: all 0.3s;
}
#language-selector .v-enter,
#language-selector .v-leave-to {
  opacity: 0;
  top: calc(100% + 30px);
}
/* purgecss end ignore */

#profile-selector {
  display: inline-flex;
  margin-right: 15px;
}
@media (max-width: 991px) {
  #profile-selector {
    display: none;
  }
}
#profile-selector .profile-icons {
  display: grid;
  grid-gap: 10px;
  grid-auto-flow: column;
  margin-left: 10px;
}
#profile-selector a svg {
  pointer-events: none;
}
#profile-selector a path {
  transition: fill 0.3s;
}
#profile-selector a.current path {
  fill: var(--yellow);
}
#profile-selector .current-profile {
  min-height: 27px;
  margin-left: 10px;
  min-width: 180px;
  border-bottom: 1px solid var(--mediumGrey);
  overflow: hidden;
  display: grid;
}
#profile-selector .current-profile > div {
  grid-column: 1/2;
  grid-row: 1/2;
  transition: all 0.3s;
  opacity: 0;
  cursor: default;
}
#profile-selector .current-profile .current {
  opacity: 1;
}
