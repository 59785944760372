/* purgecss start ignore */
div.btn-container {
    margin-top: var(--sectionSpacing);
    text-align: center;

    @media (max-width: 767px) {
        margin-top: var(--sectionSpacingMobile);
    }
}

a.small-btn {
    font-weight: 300;

    p {
        display: inline;
    }
}

a.product-cta,
a.small-btn {
    color: var(--grey);
    display: block;
    position: relative;
    transition: all 0.3s;

    > div {
        display: inline-block;
    }

    &:after {
        content: "";
        width: 12px;
        height: 26px;
        margin-left: 10px;
        background-size: contain;
        display: inline-block;
        background: url("../icons/arrow-2.svg") no-repeat center;
        position: absolute;
        transition: all 0.3s;

        @media (max-width: 767px) {
            height: 15px;
        }
    }

    &:hover {
        opacity: 0.7;

        &:after {
            margin-left: 15px;
        }
    }

    &.download-btn {
        padding-left: 30px;

        &:after {
            display: none;
        }

        &:before {
            content: "";
            width: 20px;
            height: 100%;
            background-size: contain;
            display: inline-block;
            background: url("../icons/download.svg") no-repeat center;
            background-size: contain;
            position: absolute;
            left: 0;
        }
    }
}

a,
button:not(.dropdown-toggle),
div.input-btn,
div.qq-upload-button {
    &,
    &:hover,
    &:focus {
        text-decoration: none;
    }

    input.btn {
        padding: 0;
        color: inherit;
    }

    &.qq-upload-button {
        width: auto;
        box-shadow: none !important;
    }

    &.btn,
    &.qq-upload-button {
        border-radius: 5px;
        color: white;
        overflow: hidden;
        position: relative;
        transition: all 0.2s;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        z-index: 1;
        backdrop-filter: blur(3px);
        padding: 6px 40px;
        font: 300 21px/26px var(--defaultFonts);
        border: 1px solid var(--cepheidBlue);
        text-align: left;
        background: var(--lightBlue);

        @media (max-width: 767px) {
            padding: 12px 25px;
            font-size: var(--mobileFontSize);
            line-height: var(--mobileLineHeight);
        }

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: var(--cepheidBlue);
            z-index: -1;
            transition: all 0.2s;
            animation: btnHoverOff 0.2s forwards linear;
        }

        html.no-touchevents & {
            &:hover,
            &.hover {
                color: white;

                &:before {
                    animation: btnHoverOn 0.2s forwards linear;
                }
            }
        }

        &:hover,
        &.hover {
            border-color: var(--lightBlue);
        }

        html.touchevents & {
            &:hover,
            &.hover {
                color: white;
            }
        }

        h4 {
            font-size: var(--desktopFontSize);
            line-height: var(--desktopLineHeight);
        }

        &.event-btn {
            padding: 6px 20px;
            font-size: var(--desktopFontSize);
            color: var(--grey);
            background: white;
            border-color: var(--mediumGrey);
            margin-top: 10px;

            @media (max-width: 767px) {
                font-size: var(--mobileFontSize);
                line-height: var(--mobileLineHeight);
            }

            &:before {
                animation: btnHoverOn 0.2s forwards linear;
                background: var(--mediumGrey);
            }

            &:hover,
            .feed-item:hover & {
                color: white;

                &:before {
                    animation: btnHoverOff 0.2s forwards linear !important;
                }
            }

            &.blue {
                color: var(--cepheidBlue);
                border-color: var(--cepheidBlue);

                &:before {
                    background: var(--cepheidBlue);
                }

                &:hover {
                    color: white;
                }
            }
        }

        &.white-btn,
        &.btn-primary:not(.modal-btn) {
            border-color: white;
            color: #007ac2;
            color: var(--cepheidBlue);
            background: none;

            &:before {
                background: white;
            }

            html.no-touchevents &:hover {
                color: white;
            }

            html.touchevents &:hover {
                color: var(--cepheidBlue);
            }

            &:hover {
                border-color: white;
            }

            .form-section & {
                border-color: var(--green);
                color: white;

                &:before {
                    background: var(--green);
                }

                &:hover {
                    border-color: var(--green);
                }
            }

            &.invert-btn {
                color: white;

                @media (min-width: 768px) {
                    padding: 6px 20px;
                    font-size: var(--desktopFontSize);
                }

                &:before {
                    animation: btnHoverOn 0.2s forwards linear;
                }

                html.no-touchevents &:hover,
                html.no-touchevents .impact-story-banner:hover & {
                    color: var(--grey);

                    &:before {
                        animation: btnHoverOff 0.2s forwards linear;
                    }
                }

                html.touchevents &:hover,
                html.touchevents .impact-story-banner:hover & {
                    color: white;
                }
            }
        }

        &.request-btn {
            padding: 1px 29px;
            font: 300 16px/26px var(--defaultFonts);
            border: 1px solid var(--green);
            background: none;

            @media (max-width: 767px) {
                padding: 1px 11px;
            }

            @media (max-width: 991px) {
                order: 1;
            }

            &:before {
                background: var(--green);
            }

            &:hover {
                border-color: var(--green);
            }

            html.no-touchevents &:hover {
                color: var(--green);
            }
        }

        &.square-btn {
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            border: 1px solid var(--mediumGrey);
            background: var(--cepheidBlue);
            color: var(--grey);
            border-radius: 0;
            padding: 15px 25px;

            @media (max-width: 767px) {
                padding: 10px 20px;
            }

            .svg {
                background: white;
                border-radius: 100%;

                svg {
                    width: 30px;
                    height: 30px;
                    padding: 5px;
                    box-sizing: content-box;

                    path {
                        fill: var(--cepheidBlue);
                        transition: all 0.2s;
                    }
                }
            }

            h3 {
                margin: 0;
                width: 170px;
            }

            &:before {
                background: white;
            }

            html.no-touchevents & {
                &:hover,
                &.hover {
                    color: white;
                }
            }

            html.touchevents & {
                &:hover,
                &.hover {
                    color: var(--grey);
                }
            }

            &:hover,
            &.hover {
                .svg svg path {
                    fill: var(--yellow);
                }
            }
        }

        &.video-btn,
        &.download-btn,
        &.document-btn {
            flex-direction: row-reverse;
            justify-content: flex-end;

            &:after {
                content: "";
                display: inline-block;
                width: 40px;
                min-width: 40px;
                height: 40px;
                background-size: 100%;
                background-position: center;
                background-repeat: no-repeat;
                margin-right: 20px;
            }

            .document-btn-title {
                font-size: 0.8rem;
                font-weight: 200;
            }

            @media (max-width: 767px) {
                &:after {
                    width: 19px;
                    min-width: 19px;
                    height: 19px;
                    margin-right: 10px;
                }
            }
        }

        &.video-btn:after {
            background-image: url(../icons/play.svg);
        }

        &.ordering-btn {
            
            @media (min-width: 768px) {
                font-size: 18px;
            }
        }

        &.goto-store-btn {
            flex-direction: row-reverse;
            justify-content: flex-end;
            font-size: 18px;
            
            &:after {
                content: "";
                display: inline-block;
                width: 20px;
                min-width: 20px;
                height: 20px;
                background-size: 100%;
                background-position: center;
                background-repeat: no-repeat;
                margin-right: 10px;
            }

            &:after {
                background-image: url(../icons/cart.svg);
            }

            @media (min-width: 768px) {
                padding: 6px 20px;
            }

            @media (max-width: 767px) {
                &:after {
                    width: 25px;
                    min-width: 25px;
                    height: 25px;
                }
            }
        }

        &.download-btn:after,
        &.document-btn:after {
            width: 25px;
            min-width: 25px;

            @media (max-width: 767px) {
                width: 15px;
                min-width: 15px;
                margin-right: 10px;
            }
        }

        &.download-btn:after {
            background-image: url(../icons/download.svg);
        }

        &.document-btn:after {
            background-image: url(../icons/arrow-cta.svg);
            background-size: auto 22px;
            background-position: right center;

            @media (max-width: 767px) {
                background-size: contain;
            }
        }
    }

    @keyframes btnHoverOn {
        from {
            left: 0%;
        }
        to {
            left: 100%;
        }
    }

    @keyframes btnHoverOff {
        from {
            left: -130%;
        }
        to {
            left: 0%;
        }
    }
}

.buttons {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fill, 285px);
    justify-content: center;
    overflow: hidden;

    &.anim {
        a {
            opacity: 0;
            transition: all 0.5s;
        }

        a:first-of-type {
            left: -100px;
        }

        a:last-of-type {
            left: 100px;
        }

        &.visible a {
            left: 0;
            opacity: 1;
        }
    }
}

.button-layout a {
    height: 100%;
    width: 100%;
}

.object-Files a.square-btn,
.template-buttons a.square-btn {
    width: 255px;
    height: 100%;
}
/* purgecss end ignore */