.card-snippet {
    max-width: 300px;
    min-height: 420px;
    height: 100%;
    background: white;
    padding: 30px;
    box-sizing: border-box;
    position: relative;
    box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
    margin: auto;

    img {
        max-width: 100%;
        height: auto;
    }

    .counter {
        background: var(--cepheidBlue);
        color: white;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 5px;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        line-height: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.4em;
    }

    &.outline {
        background: none;
        color: white;
        box-shadow: none;
        border: 1px solid white;
        min-height: 170px;

        .counter {
            background: white;
            color: var(--cepheidBlue);
        }
    }
}
