.flexible-list {

    .flexible-list__container {
        width: 730px;
        display: flex;
        text-align: center;
        margin: 0 auto;

        @media (max-width: 768px) {
            width: unset;
        }

        @media (max-width: 576px) {
            margin-top: 50px;
        }

        .flexible-list__container_row {
            flex-direction: column;
        }
    }

    .flexible-list__col {
        text-align: center;
        position: relative;
        padding: 40px 30px;
        margin: 80px 0;

        img {
            position: absolute;
            margin: 0 auto;
            top: -10%;
            transform: translate(-50%, -50%);
        }
    }

    // horizontal styles

    .flexible-list__horizontal-list {
        width: unset;

        .flexible-list__container_row {
            flex-direction: row;
        }

        .flexible-list__col {
            margin: 110px 20px;

            @media (max-width: 576px) {
                margin: 55px 20px;
            }

            img {
                top: -5%;
            }
        }
    }
}