.impact-story-banner {
    padding: 20px;
    color: white;
    background: #333;
    width: 400px;
    height: 500px;
    position: relative;
    max-width: calc(100vw - 50px);
    margin: auto;
    cursor: pointer;

    @media (max-width: 767px) {
        height: 450px;
    }

    a,
    h2 {
        color: white;
    }

    .content {
        position: relative;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: bottom;
        pointer-events: none;
    }

    #pimcore_editable_image {
        border: 3px solid white;
    }

    .impact-stories .career & {
        h2 {
            font-size: 34px;
            margin: 25px 0;
        }

        a.btn {
            min-width: 150px;
            justify-content: center;
            font-size: var(--mobileFontSize);
        }
    }
}
