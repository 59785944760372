body {
    main {
        .bootstrap-select button {
            background-color: #dae0e5;
            border-color: #d3d9df;
        }

        #inserts-filter-app,
        #msds-list {
            padding-top: var(--sectionSpacing);
            padding-bottom: var(--sectionSpacing);

            .header-wrapper {
                background: var(--lightGrey);
                color: var(--cepheidBlue);
                border-bottom: 2px solid var(--mediumGrey);
                font-weight: 500;
            }

            .inserts-wrapper,
            .msds-wrapper {
                &>div:nth-child(even) {
                    background: var(--lightGrey);
                }
            }

            .catalog-num>p,
            .document-num>p {
                margin-top: 0;
                font-size: 18px;

                &>span {
                    font-size: 14px !important;
                    line-height: 17px;
                    letter-spacing: 0.4px;
                    color: var(--mediumGrey) !important;
                }
            }

            .document-num {
                & p {
                    font-size: 18px;
                }
            }
        }

        .tc-grey {
            color: var(--grey);
        }

        .campaign-container .container {
            padding: 0;
        }

        // Grid items
        .grid-container {
            display: grid;
            grid-template-columns: auto auto auto;
            padding: 10px;
        }

        .grid-item {
            padding: 20px;
            font-size: 30px;
        }
    }

    .page-alert {
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px 0 !important;

        #alert-close {
            color: #fff;
            font-weight: 200;
            position: absolute;
            right: 0;
            margin-right: 20px;
            line-height: 0;
        }

        h3 {
            display: inline;
            line-height: 1em;
            margin: 0 7px 0 0;
        }

        a {
            transition: all 1s;
            color: #fff;
            display: inline;
            line-height: 1em;
            font-size: 1.1em;
        }

        a:hover {
            text-decoration: underline;
        }

        img {
            height: 30px;
            width: 30px;
            object-fit: contain;
            margin-right: 10px;
        }
    }

    .spinner-grow {
        color: #007ac2;
    }

    .border-cepheid-blue {
        border-color: #007ac2 !important;
    }

    .mdsRegionalIFU {
        cursor: pointer;
        font-weight: 500;
        font-size: 18px;

        span {
            &.active {
                svg {
                    transform: rotate(90deg);
                    transition: all .4s;

                    rect:first-of-type {
                        opacity: 0;
                    }
                }
            }

            svg {
                cursor: pointer;
                transition: all .4s;
            }
        }
    }

    .isIFU {
        .document-num {
            height: 100px;
        }

        .slide {
            transition: .5s cubic-bezier(0.5, 1, 0.5, 1);
            transform: translateY(0);
            width: 100%;
            height: 0;
            overflow: hidden;
        }


        .slide-up {
            transform: translateY();
        }

        .slider {

            .info-wrapper {
                overflow: hidden;
                min-width: 100vw;

                @media (min-width: 767px) {
                    min-width: calc(66vw - 10px);
                }

                @media (min-width: 1024px) {
                    min-width: 760px;
                }

                .slide-assets {
                    width: 100%;
                    display: flex;
                    gap: 5%;

                    &-fileTitle,
                    &-filePart {
                        width: 50%;
                    }
                }
            }
        }
    }
}

/**
 * Background helper classes
 */

/* purgecss start ignore */

.blue-gradient-bg {
    background: linear-gradient(180deg, #0078C1 10.42%, #6CBCED 100%);
}

.green-gradient-bg {
    background: linear-gradient(180deg, #77B800 0%, #74A333 100%);
}

.grey-gradient-bg {
    background: linear-gradient(180deg, #4C4D4F 10.42%, #C4C4C4 100%);
}

.orange-gradient-bg {
    background: linear-gradient(180deg, #FDB71A 0%, #F7941D 100%);
}

// unset strong styles

.unset-style strong {
    font-size: unset;
    color: unset;
    line-height: unset;
}

.bolded-blue span {
    color: #15BEF0;
    text-transform: uppercase;
    font-weight: bold;
}

// for support subdomains
.profile-language, .request-btn, form#siteSearch {
    display: none !important;
}


span.new-file {
    color: var(--cepheidBlue);
    font-weight: 500;
    text-transform: uppercase;
}

a.supportProductBoldTitle.dropdown-item {
    font-weight: 800;
}

.edocPage {

    .preview-images .preview-item {
        padding-bottom: unset;
    }

    .preview-images .preview-item .text {
        position: unset;
    }
}