.support-buttons {
    @media (max-width: 991px) {
        img {
            margin: auto;
            display: block;
        }
    }

    .image-container {
        img {
            max-width: 100%;
        }
    }

    h2 {
        margin-bottom: 30px;
    }

    @media (min-width: 768px) {
        hr {
            height: 100%;
            display: block;
            border-right: 1px solid var(--mediumGrey);
            margin: 0 auto;
            width: 0;
        }
    }

    .support-btns {
        display: grid;
        grid-gap: 30px;
        grid-auto-flow: row;

        a.btn.document-btn {
            justify-content: center;
        }

        a.btn.bold {
            font-weight: bold;
            color: var(--cepheidBlue);
        }

        a.btn.left-align {
            justify-content: start;
        }
    }
}

.support-documents-buttons,
.mini-nav {
    background: #14bef0;

    .document-buttons {
        a {

            &,
            p {
                width: 100%;
                text-align: center;
            }
        }
    }

    .pimcore_tag_block {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        div[class*="col"] {
            max-width: none !important;
            width: 100% !important;
        }

        .pimcore_tag_wysiwyg {
            width: 100%;
        }
    }
}

.mini-nav {
    .editmode-custom-id {
        label {
            flex-basis: 300px;
        }
    }
}

.support-document-links {
    .pimcore_tag_block>[data-real-name="documents"] {
        border: 1px solid var(--mediumGrey);
        padding: 30px;
    }

    .pimcore_tag_block>[data-real-name="products"] {
        width: 33%;
    }

    .pimcore_tag_block .pimcore_tag_block {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
}