.country-map .map-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 67%;

    .mapgl {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    .mapboxgl-control-container,
    .popups {
        display: none;
    }
}
