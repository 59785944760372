.wysiwyg {
    position: relative;

    // break long links to handle overflow
    p,
    ol,
    ul {
        overflow-wrap: break-word;
        word-wrap: break-word;

        -ms-word-break: break-all;
        word-break: break-all;
        word-break: break-word;

        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
    }

    &.footnotes {
        background: rgba(#4b4e50, 0.95);
        color: white;
        font: 300 14px/17px var(--defaultFonts);
        letter-spacing: -0.05px;

        p {
            margin: 5px 0;

            &:first-of-type {
                margin-top: 0;
            }
        }

        @media (max-width: 767px) {
            font-size: 10px;
            line-height: 17px;
        }
    }

    &.graph {
        .container {
            max-width: 740px;

            .row {
                padding: 30px 15px 0;
                margin: 0;
                border: 1px solid var(--mediumGrey);
            }
        }

        h3 {
            color: var(--cepheidBlue);
        }
    }

    img,
    svg {
        max-width: 100%;
        height: auto;
        margin: auto;
        display: inherit;
        width: auto;
    }

    .flex p {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        &.no-wrap {
            flex-wrap: nowrap;
        }

        img,
        svg {
            margin: 15px;
        }
    }

    h3 {
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media (min-width: 992px) {
        .image img {
            width: 100%;
        }
    }

    section.video-brick {
        padding: 0;
    }

    .quote .content {
        padding: 30px;
    }

    h5:last-child,
    p:last-child {
        margin-bottom: 0;
    }
}