.three-icons {
    text-align: center;

    img {
        width: 100px;
        height: 100px;
        object-fit: contain;
    }

    .pimcore_tag_image,
    img {
        display: block;
        margin: 0 auto 15px;
    }

    h4 {
        font-size: 18px;
        line-height: 22px;
    }
}
