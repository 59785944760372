.news-events {
    .news-events-layout {
        display: grid;
        grid-auto-flow: dense;
        column-gap: 25px;

        > * {
            grid-column: ~"1/2";
        }

        @media (min-width: 992px) {
            grid-template-columns: 1fr 1fr;
            column-gap: 30px;

            h2:last-of-type {
                &,
                & ~ * {
                    grid-column: ~"2/3";
                }
            }
        }

        @media (max-width: 991px) {
            > h2:last-of-type {
                margin-top: 60px;
            }
        }

        @media (max-width: 767px) {
            > h2:last-of-type {
                margin-top: 30px;
            }
        }
    }

    .feed-items {
        display: grid;
        grid-gap: 30px;
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));

        @media (max-width: 767px) {
            grid-template-columns: 1fr;
        }

        @media (max-width: 991px) {
            grid-gap: 25px;
        }
    }

    .feed-item {
        padding: 30px 20px;
        background: var(--lightGrey);
        display: flex;
        align-items: center;
        cursor: pointer;

        h4 {
            margin-bottom: 0;
        }

        .date,
        .img {
            width: 120px;
            min-width: 120px;
            margin-right: 20px;
            text-align: center;

            img {
                max-width: 100%;
                object-fit: contain;
                height: auto;
            }

            .day {
                font: 300 42px/36px var(--defaultFonts);

                @media (max-width: 991px) {
                    font-size: 28px;
                }
            }
        }
    }
}

#events-list-past .feed-item {
    cursor: unset;
}
