.blue-box {
    background: var(--blueGradient);
    padding: 20px 15px;
    text-align: center;
    color: white;
    height: 100%;
    min-width: 170px;

    h1 {
        margin-bottom: 20px;

        &:after {
            content: "";
            height: 2px;
            width: 45px;
            background: var(--lightBlue);
            display: block;
            margin: 20px auto 0;
        }
    }

    h3 {
        margin-bottom: 0;
    }
}

.cta-row.default .pimcore_tag_block {
    display: grid;
    grid-template-columns: repeat(auto-fill, 1fr);
    grid-auto-flow: column;
}

.cta-row.educational .pimcore_tag_block {
    width: 100%;
}

.educational-site {
    padding-top: 30px;

    + .educational-site {
        border-top: 1px solid var(--mediumGrey);
    }

    img {
        max-width: 100%;
        height: auto;
    }

    a.btn.event-btn {
        color: var(--cepheidBlue);

        &:before {
            background: var(--cepheidBlue);
        }
    }
}

@media (max-width: 767px) {
    .educational-site {
        text-align: center;

        h2 {
            line-height: 0;
        }

        img {
            margin: auto;
        }
    }

    .cta-row h2 {
        text-align: center;
    }
}
