/**
 * Cta Cards Brick
 */

@media (min-width: 24px) {
	.cta-cards {
		padding: 30px 0px;
	}

	.cta-card {
		width: 100%;
		margin-top: 35px;
		margin-bottom: 30px;
		height: auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		&__header {
			height: 75px;
			display: flex;
			align-items: center;
			padding-left: 17px;
		}

		&__image {
			padding-bottom: 12px;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				max-width: 100%;
				max-height: 100%;
				object-fit: contain;
			}
		}
	}

	.cta-card-links {
		&__item {
			display: flex;
			align-items: center;
		}

		&__link {
			padding-left: 12px;

			a {
				text-decoration: underline;
				color: #414344;
			}
		}
	}
}

@media (min-width: 992px) {
	.cta-card {
		height: 340px;
	}
}

/* Editmode */

.cta-card-editmode {
	border: 1px dashed black;
	padding: 20px;
	margin-bottom: 20px;

	.cta-card {
		&__header {
			height: auto;
		}

		&__image {
			height: 150px;
			width: 150px;
		}
	}
}