//from the smallest device
@media (min-width: 24px) {
    header {
        & .login-my-cepheid {
            order: 0;
            width: 32px;
            height: 32px;
            margin: 0 16px 0 5px;
            position: relative;

            &-headline {
                border-bottom: 2px solid var(--lightGrey);
                text-align: center;
                color: #0E0F11;
                font-size: 12px;
                line-height: 14px;
                font-weight: 600;
                margin: 10px 0;
                padding-bottom: 10px;
                animation: fadeIn .8s ease-in-out;
            }

            &-links {
                padding: 0;
                margin: 0 auto;
                margin-bottom: 10px;

                & * {
                    color: #0057A8;
                    font-size: 12px;
                }

                &-item {
                    list-style: none;
                    padding: 0 15px;
                    margin: 15px 0 0 0;
                    animation: fadeIn .8s ease-in-out;

                    &__icon {
                        width: 15px;
                        height: 15px;
                        margin: 0 12px 0 0;
                    }

                    &__link {
                        text-decoration: none;
                        display: inline-block;
                        width: 100%;
                    }

                    &:hover {
                        cursor: pointer;
                        background-color: #E5F5FF;
                        border-radius: 15px;
                    }
                }
            }

            &-container {
                background-color: var(--white);
                max-width: 171px;
                width: 171px;
                position: absolute;
                right: -70px;
                top: 40px;
                padding: 12px;
                -webkit-box-shadow: 2px 1px 9px 7px rgba(0, 0, 0, 0.12);
                -moz-box-shadow: 2px 1px 9px 7px rgba(0, 0, 0, 0.12);
                box-shadow: 2px 1px 9px 7px rgba(0, 0, 0, 0.12);
                border-radius: 5%;
                z-index: 2;

                &.open {
                    animation: slideDown .3s ease-in-out;
                }

                svg {
                    top: -19px;
                    left: 35.5%;
                    position: absolute;
                }

                .triangle {
                    fill: #fff;
                    stroke: #fff;
                    stroke-width: 100;
                }
            }

            &-icon {
                border-radius: 50%;
                text-align: center;
                width: 32px;
                height: 32px;
                position: relative;

                &:hover {
                    cursor: pointer;
                    background-color: #E5F5FF;
                }

                &:before {
                    content: url('/static/icons/login.svg');
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 6px
                }

                &.open {
                    background-color: #E5F5FF;
                    transition: 1s all;

                }
            }
        }
    }
}

// from desktop small
@media (min-width: 992px) {
    header {
        & .login-my-cepheid {
            order: unset;
            margin: 0 30px 0 0;
            width: 20px;
            height: 20px;

            &-container {
                right: -83px;

                svg {
                    top: -20px;
                    left: 34.5%;
                }
            }

            &-links {
                &-item {
                    width: unset;

                    &__icon {
                        width: 15px;
                        height: 15px;
                    }
                }
            }

            &-icon {
                padding-top: 5px;
                height: 30px;
            }
        }
    }
}

@keyframes slideDown {
    0% { max-height: 0; }
    100% {max-height: 205px; }
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
