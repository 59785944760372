.navigation-based-block {

    .container {
        padding: 0 30px;
    }

    .column-container {
        padding-left: 0;
        padding-right: 0;

        a {
            color: var(--grey);
        }

        .navigation-based-block-title {
            padding-top: 8px;
            padding-bottom: 8px;
            border-top: black solid 6px;
            //border-bottom: var(--grey) dotted 2px;
            margin-bottom: 10px;
            min-width: 200px;
        }

        &:nth-of-type(1) .navigation-based-block-title {
            border-top-color: #8DC63F;

            a {
                color: #8DC63F;
            }
        }

        &:nth-of-type(2) .navigation-based-block-title {
            border-top-color: #15BEF0;

            a {
                color: #15BEF0;
            }

        }

        &:nth-of-type(3) .navigation-based-block-title {
            border-top-color: #FDB71A;

            a {
                color: #FDB71A;
            }

        }

        &:nth-of-type(4) .navigation-based-block-title {
            border-top-color: #DF6020;

            a {
                color: #DF6020;
            }

        }

        &:nth-of-type(5) .navigation-based-block-title {
            border-top-color: #7979B9;

            a {
                color: #7979B9;
            }

        }

        .navigation-based-block-item {
            padding: 4px 15px 4px 0;
        }


    }

}