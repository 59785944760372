@media (min-width: 992px) {
    #nav > button,
    .mobile-footer,
    .more-icon {
        display: none;
    }
}

@media (max-width: 991px) {
    #nav > button {
        border: none;
        background: none;
        padding: 0;
        cursor: pointer;
        margin-left: 20px;
        position: relative;
        z-index: 5;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;

        path {
            transition: all 0.3s;
        }

        span {
            display: block;
            width: 20px;
            position: absolute;
        }

        &:not(.mobileOpen) span.close-icon path {
            &:first-of-type {
                transform: translate(-100%, -100%);
            }

            &:last-of-type {
                transform: translate(100%, -100%);
            }
        }

        &.mobileOpen span {
            &.open-icon path {
                &:nth-of-type(odd) {
                    transform: translateX(100%);
                }

                &:nth-of-type(even) {
                    transform: translateX(-100%);
                }
            }

            &.close-icon {
                display: block;
            }
        }
    }

    #nav {
        order: 2;
        ul {
            margin: 0;
            list-style: none;
            position: fixed;
            left: 0;
            top: 0;
            width: 100vw;
            height: 100vh;
            padding: 30px 60px;
            z-index: -1;
            visibility: hidden;
            transition: visibility 0s 0.5s;

            body.mobileOpen & {
                height: ~"calc(100vh - 80px)";
            }

            > li {
                position: relative;
                left: 70vw;
                opacity: 0;
                transition: all 0.4s;
                transition-delay: calc((var(--delay) + 1) * 0.04s);
                margin: 10px 0;
            }

            &.active-view {
                transition: visibility 0s;
                overflow-y: scroll;
                overflow-x: hidden;

                > li {
                    left: 0vw;
                }
            }

            &.prev-view > li {
                left: -80vw;
            }

            &.active-view,
            &.prev-view {
                z-index: 4;

                > li {
                    opacity: 1;
                }
            }

            a {
                color: white;
                font: 300 20px/30px var(--defaultFonts);
                letter-spacing: 0.5px;
                transition: all 0.2s;

                &:hover {
                    color: var(--yellow);
                }
            }

            li.active:not(.active-trail) > a {
                color: var(--yellow);
            }

            li.mobile-header {
                position: relative;
                transition-delay: 0s;

                a {
                    font-size: 18px;
                    font-weight: bold;
                }

                ~ li a {
                    font-size: 16px;
                }
            }
        }

        > ul {
            opacity: 0;
            visibility: hidden;
            transition: all 0.5s, visibility 0s 0.5s;
            z-index: 4;

            &:before {
                content: "";
                position: fixed;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: var(--grey);
                opacity: 0.95;
                z-index: -1;
                pointer-events: none;
            }

            &.active-view {
                transition: all 0.5s, visibility 0s 0.5s;
            }

            > li {
                transition: all 0.4s, top 0.4s;
                transition-delay: calc((var(--delay) + 1) * 0.04s), 0.4s;
            }

            &.active-view > li,
            &.prev-view > li {
                opacity: 0;
                top: 70px;
                margin: 30px 0;
            }
        }

        button.mobile-back {
            position: absolute;
            left: 10px;
            line-height: 1;
            top: 44px;
            margin-left: 0;
            width: auto;
        }

        .more-icon {
            z-index: 5;
            position: fixed;
            bottom: 100px;
            right: 15px;
        }

        button.mobile-back,
        .more-icon {
            visibility: hidden;
            transition: all 0.5s, visibility 0s 0.5s;
            opacity: 0;
        }

        &.mobileOpen {
            ul.active-view {
                visibility: visible;
            }
            > ul {
                transition: all 0.5s;
                visibility: visible;
                opacity: 1;

                &.active-view {
                    transition: all 0.5s;
                }

                > li {
                    opacity: 1;
                    top: 0px;
                    transition-delay: calc((var(--delay) + 1) * 0.04s);
                }
            }

            > button.mobile-back.visible,
            > .more-icon.visible {
                transition: all 0.5s;
                visibility: visible;
                opacity: 1;
            }
        }
    }

    .mobile-footer {
        position: fixed;
        bottom: -80px;
        left: 0;
        width: 100%;
        height: 80px;
        background: var(--white);
        transition: bottom 0.5s;
        z-index: 6;

        .col {
            display: grid;
            grid-auto-flow: column;
            grid-template-columns: 1fr 1fr 1fr 20px;
            grid-gap: 0px;
            height: 80px;
            align-items: center;
            justify-content: center;
            text-align: center;

            @media (max-width: 370px) {
                grid-gap: 5px;
            }

            a {
                font: 12px/15px var(--defaultFonts);
                color: var(--grey);
                transition: all 0.3s calc(var(--delay) * 0.04s);
                display: block;

                body.search-open & {
                    margin-top: -20px;
                    opacity: 0;
                    transition: all 0.3s calc(1 / var(--delay) * 0.3s);
                    pointer-events: none;
                }
            }
        }

        body.mobileOpen & {
            bottom: 0px;
        }
    }
}
