/* purgecss start ignore */

.custom-table {

    .custom-table__header-container {
        margin-top: 15px;
    }

    .custom-table__header {
        color: var(--white);
        padding: 5px;

        &.grey {
            background-color: var(--grey);
        }

        &.blue {
            background-color: var(--cepheidBlue);
        }

        &.green {
            background-color: var(--green);
        }
    }

    .custom-table__container {
        display: grid;
        grid-template-columns: 1fr 4fr;

        .custom-table__content {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 50px;

            @media (max-width: 992px) {
                grid-template-columns: 1fr;
                grid-gap: 30px;
            }

            .custom-table__content-area {
                display: grid;
                grid-template-columns: 1fr 5fr;
                grid-gap: 15px;
                position: relative;
                top: 30%;

                p {
                    margin: 0;
                }

                @media (max-width: 992px) {
                    grid-template-columns: 1fr;
                    top: 10%;
                }

            }
        }
    }
}

/* purgecss end ignore */