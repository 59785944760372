.product-header {
    min-height: 400px;
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
        min-height: 200px;
    }

    h2,
    p {
        margin: 30px 0;
        color: var(--grey);
        font-size: 20px;
        line-height: 25px;

        @media (max-width: 767px) {
            font-size: 16px;
            line-height: 18px;
        }
    }

    h1:not([class]) {
        color: var(--cepheidBlue);
    }

    @media (max-width: 767px) {
        h5 {
            display: none;
        }
    }

    img {
        height: 354px;
        max-width: 100%;
        object-fit: contain;
        display: block;
        margin: auto;
    }

    &.bg {
        color: white;
        background-size: cover;
        //text-shadow: 1px 1px rgba(0,0,0,0.5);

        h1,
        h5 {
            &:not([class]) {
                color: white;
            }
        }

        a {
            color: white;
        }
    }

     h1 strong, h1 b {
        font-weight: 400;
    }
}

.ordering-info {
    background: var(--lightGrey);
    padding: 30px;
    width: 350px;
    max-width: 100%;
    height: 100%;

    h4 {
        font-size: 18px;
        font-weight: bold;
    }
}

.ordering-info-header {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
        margin: 0;
    }

    .how-to-order {
        a {
            display: inline-flex;
            align-items: center;
            color: var(--mediumGrey);

            img {
                margin-right: 5px;
            }
        }
    }
}

.product-sections, .sticky-nav-container {
    #product-information img {
        height: auto;
        max-width: 100%;
    }

    @media (max-width: 767px) {
        #product-information hr {
            margin: 15px 0;
        }
    }

    nav.product-sections-nav, nav.sticky-nav {
        background: var(--cepheidBlue);
        position: sticky;
        top: 80px;
        z-index: 4;

        @media (max-width: 767px) {
            top: 70px;
        }

        .row,
        .col {
            margin-top: 0;
            margin-bottom: 0;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            text-align: center;
            border-bottom: none;

            @media (max-width: 767px) {
                justify-content: space-between;
            }

            @media (max-width: 480px) {
                font-size: 10px;
                line-height: 12px;
                font-weight: bold;
            }

            li {
                margin-right: 60px;

                &:last-of-type {
                    margin-right: 0;
                }

                @media (max-width: 991px) {
                    margin-right: 30px;
                }

                @media (max-width: 480px) {
                    margin-right: 15px;
                }

                a {
                    display: inline-block;
                    color: white;
                    padding: 30px 0;
                    border-left: none !important;
                    border-right: none !important;
                    border-top: none !important;
                    border-bottom: 0px solid var(--yellow);
                    transition: all 0.3s;
                    margin: 0;
                    overflow: visible;

                    @media (max-width: 480px) {
                        padding: 15px 0;
                    }

                    &.active {
                        padding-bottom: 20px;
                        background: none;
                        color: white;
                        border: none;
                        border-bottom: 10px solid var(--yellow);

                        @media (max-width: 480px) {
                            padding-bottom: 10px;
                            border-bottom: 5px solid var(--yellow);
                        }
                    }
                }
            }
        }
    }
}

.product-row h3 {
    margin: 15px 0;
    font-weight: 400;
    font-size: var(--desktopFontSize);
    line-height: var(--desktopLineHeight);
    font-family: var(--defaultFonts);
    color: var(--grey);
}



.product-variants {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-column-gap: 115px;

    > h4 {
        display: none;
        color: var(--cepheidBlue);
        font-weight: normal;
        margin: 0;
        align-items: center;
        text-transform: uppercase;

        hr {
            flex-grow: 1;
            margin-left: 15px !important;
        }
    }

    h5:first-child {
        grid-column: ~"2/3";
    }

    hr {
        grid-column: span 3;
        margin: 0;
        border-top-width: 2px;
    }

    .dimensions,
    .ordering {
        padding: 20px 0;
    }

    .img {
        padding: 20px;
        background: var(--lightGrey);
        display: flex;
        flex-direction: column;
        justify-content: center;

        h3 {
            margin: 0;
        }

        img {
            margin: 0 auto 10px;
            display: block;
        }
    }

    @media (max-width: 991px) {
        grid-gap: 20px 15px;
        grid-auto-flow: dense;
        grid-template-columns: 1fr 1fr;

        > h4.column-header {
            display: flex;
            grid-column: span 2;
        }

        > h5,
        > hr {
            display: none;
        }

        .dimensions,
        .ordering {
            padding: 0;
        }
    }

    @media (max-width: 500px) {
        grid-template-columns: 1fr;
        grid-column-gap: 0;

        [item] {
            grid-column-start: 1 !important;
        }

        > h4 {
            grid-column: 1;
            display: flex;
        }
    }
}
