[v-cloak] {
    display: none;
}
.arrow-box {
    .content {
        padding: 60px 30px;
        position: relative;
        opacity: 0;
        top: 20px;
        transition: all 1s 0.5s;
    }

    svg {
        display: block;
        position: absolute;
        top: 0;

        &.arrow-svg {
            top: -30px;
            opacity: 0;
            transition: all 1s 0.5s;
        }

        rect {
            fill: transparent;
            stroke: var(--mediumGrey);
            stroke-width: 0px;
            transform: rotateX(180deg);

            .cta-section & {
                stroke: white;
            }

            &.left {
                transform: rotateX(180deg) rotateY(180deg);
            }
        }
    }

    .visible,
    &.visible {
        svg rect {
            transition: stroke-dashoffset 1.5s;
            stroke-width: 2px;
        }

        svg.arrow-svg {
            top: -11px;
            opacity: 1;
        }

        .content {
            opacity: 1;
            top: 0;
        }
    }
}

.quote {
    font: 300 28px/40px var(--defaultFonts);
    text-align: center;
    max-width: 478px;
    position: relative;

    @media (max-width: 767px) {
        font-size: var(--mobileFontSize);
        line-height: var(--mobileLineHeight);
    }

    @media (max-width: 991px) {
        margin: auto;
    }

    strong {
        color: var(--green);
    }

    em {
        font-size: var(--desktopFontSize);
        line-height: var(--desktopLineHeight);
    }
}

.quotes .quote {
    max-width: 790px;
    margin: auto;
}

.quotes-large .quote {
    max-width: none;
}

section.greybackground {
    background: var(--lightGrey);
    color: var(--cepheidBlue);
    padding: var(--sectionSpacing) 0;

    .greybackground {
        transition: all 1s;
        .arrow-box {
            .quote {
                font: 300 21px/25px var(--defaultFonts);
                text-align: left;
                max-width: 100%;
                position: relative;

                @media (max-width: 767px) {
                    font-size: var(--mobileFontSize);
                    line-height: var(--mobileLineHeight);
                }

                @media (max-width: 991px) {
                    margin: auto;
                }

                strong {
                    color: var(--green);
                }

                em {
                    font-size: var(--desktopFontSize);
                    line-height: var(--desktopLineHeight);
                }

                .content {
                    padding: 0;
                    position: relative;
                    opacity: 0;
                    top: 20px;
                    transition: all 1s 0.5s;
                }

                svg {
                    display: block;
                    position: absolute;
                    top: 0;

                    &.arrow-svg {
                        top: -30px;
                        opacity: 0;
                        transition: all 1s 0.5s;
                    }

                    rect {
                        fill: transparent;
                        stroke: var(--mediumGrey);
                        stroke-width: 0px;
                        transform: rotateX(180deg);

                        .cta-section & {
                            stroke: white;
                        }

                        &.left {
                            transform: rotateX(180deg) rotateY(180deg);
                        }
                    }
                }

                .visible,
                &.visible {
                    svg rect {
                        transition: stroke-dashoffset 1.5s;
                        stroke-width: 0px;
                    }

                    svg.arrow-svg {
                        top: -11px;
                        opacity: 0;
                    }

                    .content {
                        opacity: 1;
                        top: 0;
                    }
                }
            }
        }
    }
}
