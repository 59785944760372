// Tabbed slider Carousel
.carousel-caption {
    bottom: 25%;
    text-shadow: 0 0 5px #000;
}

.carousel-indicators.preview {
    bottom: -10px;
    // width: 100%;
    // margin-left: -50%;
}

/* Indicators list style */
.carousel-indicators.preview li {
    border: medium none;
    border-radius: 0;
    height: 54px;
    margin-bottom: 5px;
    margin-left: 0;
    margin-right: 5px !important;
    margin-top: 0;
    width: 100px;
}
/* Indicators images style */
.carousel-indicators.preview img {
    border: 2px solid #ffffff;
    float: left;
    height: 54px;
    left: 0;
    width: 100px;
    opacity: 0.7;
}
/* Indicators active image style */
.carousel-indicators.preview .active img {
    border: 2px solid #428bca;
    opacity: 1;
}

.portal-page .gallery .carousel-inner > .item > img {
    min-width: 100%;
    height: auto;
}

.tabbed-slider {
    padding-bottom: 30px;
}

.tabbed-slider .nav a small {
    display: block;
}

.tabbed-slider .nav {
    background: #eee;
}

.tabbed-slider .nav-justified > li > a {
    border-radius: 0px;
}

.tabbed-slider .carousel-inner .item {
    height: 300px;
    overflow: hidden;
}

.tabbed-slider .carousel-inner .item-0,
.tabbed-slider .nav-pills .item-0.active a {
    background-color: #16a085;
}
.tabbed-slider .carousel-inner .item-1,
.tabbed-slider .nav-pills .item-1.active a {
    background-color: #2980b9;
}
.tabbed-slider .carousel-inner .item-2,
.tabbed-slider .nav-pills .item-2.active a {
    background-color: #e67e22;
}
.tabbed-slider .carousel-inner .item-3,
.tabbed-slider .nav-pills .item-3.active a {
    background-color: #8e44ad;
}
.tabbed-slider .carousel-inner .item-4,
.tabbed-slider .nav-pills .item-4.active a {
    background-color: #2980b9;
}
.tabbed-slider .carousel-inner .item-5,
.tabbed-slider .nav-pills .item-5.active a {
    background-color: #e67e22;
}
