.masthead:not(.svg) {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 400px;

    @media (max-width: 767px) {
        min-height: 200px;
    }
}

.masthead {
    overflow: hidden;
    background: var(--cepheidBlue);
    position: relative;

    .masthead-bg img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: absolute;
        opacity: 0;
        transition: opacity 0.5s;
        top: 0;
    }

    div.svg {
        width: 100%;
        position: relative;
        z-index: 2;

        img.masthead-bg {
            position: relative;
            opacity: 0;
        }

        img,
        svg {
            width: 100%;
            height: 100%;
            opacity: 0;
        }

        svg {
            animation: 1s fadeIn forwards;

            @keyframes fadeIn {
                to {
                    opacity: 1;
                }
            }
        }
    }

    &.adjusted .masthead-bg img {
        opacity: 1;
    }

    .icon {
        margin: 0 auto 30px;
        display: block;
    }

    h3:last-of-type {
        margin-bottom: 0;
    }

    > .container {
        position: relative;
        width: 100%;
        color: var(--white);
        text-align: center;
        z-index: 1;
        padding-top: 60px;
        padding-bottom: 60px;
        min-height: 400px;
        display: flex;
        align-items: center;

        @media (max-width: 767px) {
            min-height: 200px;
            padding-top: 20px;

            &.center-mobile {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        &.padded {
            padding-top: 150px;
            padding-bottom: 150px;

            @media (max-width: 767px) {
                padding-top: 20px;
            }
        }

        &.darken {
            position: unset;
        }

        &.darken:before {
            content: "";
            background: var(--grey);
            opacity: 0.62;
            width: 100vw;
            height: 100%;
            position: absolute;
            z-index: -1;
            left: 50%;
            transform: translate(-50%);
            top: 0;
        }

        > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            a.cta-btn:hover {
                color: white;
            }
        }

        .pimcore_tag_link_text a {
            color: white;
        }
    }

    &.svg > .container {
        display: none;
    }
}

.masthead,
.featurette {
    overflow: hidden;

    .featurette {
        overflow: visible;
    }

    .masthead-bg {
        img {
            transform-origin: top;
        }

        &.position-left-top img {
            object-position: left top !important;
        }

        &.position-left-center img {
            object-position: left center !important;
        }

        &.position-left-bottom img {
            object-position: left bottom !important;
        }

        &.position-right-top img {
            object-position: right top !important;
        }

        &.position-right-center img {
            object-position: right center !important;
        }

        &.position-right-bottom img {
            object-position: right bottom !important;
        }

        &.position-center-top img {
            object-position: center top !important;
        }

        //&.position-center img {
        //    object-position: center;
        //}

        &.position-center-bottom img {
            object-position: center bottom !important;
        }
    }

    &.parallax {
        .loop(@counter) when (@counter > 0) {
            .loop((@counter - 1)); // next iteration

            &[data-intensity="@{counter}"] > .masthead-bg img {
                @calc: 1 + @counter / 10;
                transform: scale(@calc);
            }
        }
    }
}
