.video-cta {
    .videos a.video-cta {
        color: white;
        display: block;
        padding-bottom: 10px;
        width: 100%;
        margin: auto;

        .title {
            height: 90px;
            display: flex;
            align-items: center;
            padding: 0 20px;

            h3 {
                margin-bottom: 0;
            }
        }

        .image {
            position: relative;
            width: 100%;

            .thumbnail {
                width: 100%;
                height: 200px;
                object-fit: cover;
                min-height: 0;
            }
        }

        &:hover .image {
            .thumbnail {
                transform: scale(1.1);
            }
        }
    }

    .videos .image {
        overflow: hidden;

        .play {
            position: absolute;
            width: 100px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            @media (max-width: 767px) {
                width: 70px;
            }
        }

        .thumbnail {
            transition: all 0.3s;

            &:hover {
                transform: scale(1.1);
            }
        }
    }

    .videos {
        div[class*="col"] {
            &:nth-child(4n + 1) a.video-cta {
                background: linear-gradient(
                    180deg,
                    var(--cepheidBlue) 10.42%,
                    var(--lightBlue) 100%
                );
            }

            &:nth-child(4n + 2) a.video-cta {
                background: linear-gradient(
                    180deg,
                    var(--green) 0%,
                    #68883d 100%
                );
            }

            &:nth-child(4n + 3) a.video-cta {
                background: linear-gradient(
                    180deg,
                    var(--grey) 10.42%,
                    #c4c4c4 100%
                );
            }

            &:nth-child(4n + 4) a.video-cta {
                background: var(--blueGradient);
            }
        }
    }

    &.single-cta {
        img {
            width: 100%;
            max-height: 400px;
            object-fit: cover;
        }
    }
}
