footer {
    padding: 40px 0 32px;
    background: var(--grey);
    color: var(--white);
    font-weight: 200;

    .legal {
        font: 300 12px/26px var(--defaultFonts);
        color: var(--mediumGrey);
    }

    h6 {
        font-size: 10px;
    }

    a.white:not(.btn):not(.small-btn):not(.x-btn) {
        color: inherit;
        font-weight: inherit;
        text-decoration: none;

        &:hover {
            color: var(--grey);
        }
    }

    ul {
        padding: 0;
        list-style: none;
        display: grid;
        grid-template-rows: repeat(5, auto);
        grid-auto-flow: column;
        margin: 0;

        a {
            @animationSpeed: 0.3s;

            font-size: 16px;
            color: var(--white);
            padding: 5px;
            margin: -5px;
            position: relative;
            z-index: 1;
            transition: color @animationSpeed;

            &:before {
                content: "";
                width: 0%;
                height: 100%;
                position: absolute;
                right: 0;
                background: white;
                z-index: -1;
                animation: footerLinkBlur @animationSpeed;
            }

            &:hover {
                color: var(--grey);

                &:before {
                    left: 0;
                    animation: footerLinkFocus @animationSpeed forwards;
                }
            }
        }
    }

    .legal-links-footer-container {
        ul {
            grid-template-rows: repeat(6, auto); // per ben
        }
    }

    @media (max-width: 767px) {
        padding: 0 0 25px;
        text-align: center;

        ul,
        h6,
        .logo,
        .quick-links {
            display: none;
        }
    }
}

@keyframes footerLinkFocus {
    to {
        width: 100%;
    }
}

@keyframes footerLinkBlur {
    from {
        width: 100%;
    }
    to {
        width: 0%;
    }
}
