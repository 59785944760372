/* purgecss start ignore */

.featurette,
.video-cta {
    &.brick-area {
        min-height: 480px;
        display: flex;
        align-items: center;
        flex-direction: column;

        @media (max-width: 767px) {
            min-height: 240px;
        }

        #product-resources & {
            min-height: 0;
        }
    }

    position: relative;

    .container .row {
        position: relative;

        .gallery-content {
            background: white;
            display: flex;
            // justify-content: center;
            flex-direction: column;
            padding: 45px;

            ul {
                padding-left: 20px;
            }

            h3:last-child {
                margin: 0;
            }

            &.text-shadow {
                text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
            }

            >div>div {
                padding-top: 15px !important;
            }

            &.clear {
                background: none;
                padding: 0;

                +.image img {
                    padding: 60px;

                    @media (max-width: 767px) {
                        padding: 0 0 60px;
                    }
                }

                @media (max-width: 767px) {
                    +.image.order-lg-first img {
                        padding: 0 0 0px;
                    }
                }

                &:not(.dark-text) {
                    color: white;

                    h1,
                    h2,
                    h3.medium {
                        color: white;
                    }

                    a.btn {
                        text-shadow: none;
                    }

                    a.small-btn {
                        color: white;
                    }
                }
            }

            @media (max-width: 767px) {
                padding: 20px;

                &.clear {
                    padding: 20px 0;
                }
            }
        }

        a>.image {
            height: 100%;
        }

        .image {
            >.pimcore_tag_image_empty {
                opacity: 0.3;
            }

            >.pimcore_tag_image {
                height: 100% !important;

                img {
                    height: 100% !important;
                    object-fit: cover;
                }
            }

            .thumbnail {
                //min-height: 350px;
                height: 100%;
                width: 100%;
                object-fit: cover;

                &.contain {
                    object-fit: contain;
                }

                @media (max-width: 991px) {
                    min-height: 0;
                    height: auto;
                    object-fit: unset;
                }
            }
        }
    }

    .math {
        position: absolute;
        bottom: 15%;
        width: 100%;
        left: 0;

        img {
            position: absolute;
            bottom: 0;

            @media (max-width: 991px) {
                height: 15vw;
            }
        }

        .left {
            left: -20%;
        }

        .right {
            right: -1%;
        }
    }
}

.featurette,
.video-cta.gallery {
    h3 {
        margin: 0 0 15px;
    }

    .container .row {
        margin-bottom: 0;

        div[class*="col"] {
            margin-bottom: 0;
        }
    }
}

.featurette {
    width: 100%;

    .pimcore_tag_block {
        width: 100%;
    }

    h1 {
        color: var(--cepheidBlue);
        margin-bottom: 15px;
    }

    @media (max-width: 991px) {
        .image.mobile-spacing {
            min-height: 50vw;
        }
    }
}

.featurette,
.wysiwyg {
    .backgrounds {
        z-index: -1;

        &,
        img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        &.contain img {
            object-fit: contain;
            object-position: right bottom !important;
        }

        @media (min-width: 992px) {

            &.object-left img,
            img:only-child {
                object-position: center;
            }

            &.object-right img:not(:only-child) {
                object-position: center;
            }
        }

        @media (max-width: 991px) {
            .desktop-bg:not(:only-child) {
                display: none;
            }

            &.object-left img,
            img:only-child {
                object-position: center bottom;
            }

            &.object-right img:not(:only-child) {
                object-position: center bottom;
            }
        }

        @media (min-width: 992px) {
            .mobile-bg:not(:only-child) {
                display: none;
            }
        }
    }
}

/* purgecss end ignore */