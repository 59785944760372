/**
 * Horizontal timeline
 */

.timeline {
    background: url("backgrounds/timeline-bg.png");
    background-size: cover;
    position: sticky;
    top: 80px;
    color: white;
    overflow: hidden;
    pointer-events: none;
    height: ~"calc(100vh - 80px)";
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @media (max-width: 991px) {
        top: 70px;
        height: ~"calc(100vh - 70px)";
    }

    @media (max-width: 767px) {
        top: 64px;
        height: ~"calc(100vh - 64px)";
    }

    .disclaimer {
        .sml-txt {
            color: white;

            p {
                margin: 0;
            }
        }
    }

    .pimcore_editable &,
    &.locked {
        pointer-events: auto;
    }

    component {
        display: none;
    }

    .prog-bar {
        content: "";
        display: block;
        height: 8px;
        background: var(--yellow);
        position: absolute;
        top: 0;
        left: 0;
    }

    .stopwatch {
        position: absolute;
        bottom: -50px;
        left: 0;

        @media (max-width: 767px) {
            left: -50px;
        }

        svg {
            height: 600px;

            @media (max-width: 767px) {
                height: 50vh;
                width: 40vh;
            }

            path {
                transform-origin: 251px 358px;

                &.minutes {
                    @rotations: 6 * 360deg;
                    @deg: calc((var(--progress) / 100) * @rotations);
                    transform: rotate(@deg);
                }

                &.hours {
                    @rotations: 0.5 * 360deg;
                    @deg: calc((var(--progress) / 100) * @rotations + 60deg);
                    transform: rotate(@deg);
                }
            }
        }
    }

    h2 {
        text-align: left;
        color: white;
    }

    h2,
    h3 {
        transition: all 0.4s;
        opacity: 0;

        &.visible {
            opacity: 1;
        }
    }

    .items {
        overflow: hidden;
        margin-top: 30px;
        width: 100%;
        flex-grow: 1;

        .container > .row {
            margin-left: -30px;
            margin-right: -30px;
        }

        .col,
        .col > .pimcore_editable {
            display: grid;
            grid-template-columns: 255px;
            grid-auto-flow: column;
            grid-gap: 0;
            width: 1170px;
            max-width: 100vw;
            padding: 0 30px;
        }

        .item {
            --speed: 0.4s;

            @media (max-width: 767px) {
                --speed: 0.2s;
            }

            width: 255px;
            overflow: hidden;
            padding-right: 30px;

            &.arrow {
                overflow: visible;
            }

            h4 {
                color: var(--yellow);
                margin-top: 5px;
                position: relative;
                top: -5px;
                opacity: 0;
                transition: ~"all var(--speed)";
            }

            div[class*="-svg"] {
                &.arrow-svg {
                    opacity: 0;
                    position: relative;
                    left: -30px;
                    transition: ~"all var(--speed)";

                    svg {
                        height: 36px;
                    }
                }

                &.timeline-svg {
                    line {
                        stroke-dasharray: 221px;
                        stroke-dashoffset: 221px;
                        transition: ~"all var(--speed) calc(var(--speed) + 0.1s)";
                    }

                    .outer {
                        stroke-dasharray: 104px;
                        stroke-dashoffset: 104px;
                        transition: ~"all var(--speed)";
                        transform-origin: 17.5px 17.5px;
                        transform: rotateY(180deg);
                    }

                    .inner {
                        transform: scale(0);
                        transform-origin: 17.5px 17.5px;
                        transition: ~"all var(--speed) calc(var(--speed) / 3) cubic-bezier(0.175, 0.885, 0.32, 1.275)";
                    }
                }
            }

            .content {
                font: 16px/24px var(--defaultFonts);
                letter-spacing: -0.5px;
                opacity: 0;
                position: relative;
                top: 30px;
                transition: ~"all var(--speed) calc(var(--speed) / 2)";

                img {
                    display: block;
                    max-width: 100%;

                    @media (max-height: 800px) {
                        display: none;
                    }
                }

                a {
                    color: white;
                    text-decoration: underline;
                }

                strong.header {
                    letter-spacing: -0.5px;
                    margin: 15px 0;
                    display: block;
                }
            }

            &.visible,
            .pimcore_editable & {
                h4 {
                    opacity: 1;
                    top: 0;
                }

                .content {
                    top: 0;
                    opacity: 1;
                }

                div[class*="-svg"] {
                    &.arrow-svg {
                        left: -1px;
                        opacity: 1;
                    }

                    &.timeline-svg {
                        line {
                            stroke-dashoffset: 0px;
                        }

                        .outer {
                            &.bottom {
                                stroke-dashoffset: 52px;
                            }

                            &.top {
                                stroke-dashoffset: 156px;
                            }
                        }

                        .inner {
                            transform: scale(1);
                        }
                    }
                }
            }
        }
    }
}

/**
 * Vertical Timeline
 */

.vertical-timeline-container {
    background: linear-gradient(274.54deg, #0079C1 41.98%, #15BBED 135.54%);
    padding: 50px 0px 120px 0px;

    /**
     * Header
     */
    
    .header-container {
        h2 {
            color: #ffffff;
            margin-bottom: 75px;
        }
    }
    
    /**
     * Content
     */

    .timeline-items-container {
        position: relative;
        z-index: 0;

        // static vertical line
        &:before {
            content: '';
            width: 2px;
            height: calc(100% - 35px);
            background-color: #BEC0BD;
            position: absolute;
            top: 10px;
            left: 50%;
            transform: translate(-50%);
            z-index: -1;
        }

        // arrow at bottom
        &:after {
            content: url("../icons/timeline-bottom-arrow.svg");
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);

            @media (max-width: 992px) {
                left: 32px;
            }

            @media (max-width: 767px) {
                left: 32px;
            }
        }

        // vertical line that moves with scroll
        .scrolling-vertical-line {
            width: 8px;
            height: 0px;
            background-color: #7ECCF0;
            position: absolute;
            top: 10px;
            left: 50%;
            transform: translate(-50%);
            z-index: -1;
            transition: height 0.6s;
        }

        @media (max-width: 992px) {
            padding-top: 10px;

            // static vertical line
            &:before {
                content: '';
                width: 2px;
                height: calc(100% - 35px);
                background-color: #BEC0BD;
                position: absolute;
                top: 10px;
                left: 32px;
                transform: translate(-50%);
                z-index: -1;
            }

            // vertical line that moves with scroll
            .scrolling-vertical-line {
                width: 8px;
                height: 0px;
                background-color: #7ECCF0;
                position: absolute;
                top: 10px;
                left: 32px;
                transform: translate(-50%);
                z-index: -1;
                transition: height 0.6s;
            }
        }

        .timeline-item {
            display: flex;

            /**
             * Timeline editmode styles
             */

            &.item-editmode {
                .item-col {
                    flex-direction: column !important;

                    .item-media-container {
                        position: static !important;
                    }
                }
            }

            .item-col {
                display: flex;
                flex-basis: 50%;
                margin-bottom: 30px;
                position: relative;

                // shared media container styles
                .item-media-container {
                    position: absolute;
                    bottom: 10px;

                    @media (max-width: 992px) {
                        position: static;
                    }

                    .item-image-container {
                        img {
                            max-height: 200px;
                        }
                    }

                    .item-video-container {
                        .preview-item {
                            display: block;
                            width: 250px;
                            min-height: 150px;
                            position: relative;

                            .pimcore_tag_image {
                                z-index: 2;
                            }

                            .image {
                                position: relative;
                            }

                            img {
                                display: block;
                                margin: auto;
                                width: 100%;
                                height: 208px;
                                object-fit: contain;
                                position: relative;

                                &.play {
                                    position: absolute;
                                    width: 74px;
                                    height: 74px;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                }
                            }

                            @media (max-width: 992px) {
                                margin-left: 20px;
                            }

                            @media (max-width: 767px) {
                                width: 150px;

                                img.play {
                                    width: 50px;
                                    height: 50px;
                                }
                            }
                        }
                    }
                }

                // shared column content styles
                .item-text-container {
                    width: 100%;

                    .year-container {
                        position: relative;

                        h2 {
                            color: #FDB71A;
                        }

                        .timeline-indicator {
                            position: absolute;
                            top: 5px;
                            background: #3480be;
                            border-radius: 50%;
                        }
                    }

                    .body-container {
                        color: #ffffff;
                    }
                }

                // left column styles
                &.item-col-left {
                    padding-right: 48px;
                    text-align: right;
                    justify-content: flex-end;

                    .item-media-container {
                        left: 0;
                    }

                    .item-text-container {
                        .year-container {
                            .timeline-indicator {
                                right: -65px;
                            }
                        }
                    }
                }

                // right column styles
                &.item-col-right {
                    padding-left: 48px;
                    justify-content: flex-start;

                    .item-media-container {
                        right: 0;
                    }

                    .item-text-container {
                        .year-container {
                            .timeline-indicator {
                                left: -65px;
                            }
                        }
                    }
                }
            }

            // mobile/tablet view for timeline items
            
            @media (max-width: 992px) {
                justify-content: flex-start;

                .hide-mobile {
                    display: none;
                }

                .item-col {
                    flex-basis: 100%;

                    // left and right column styles
                    &.item-col-left,
                    &.item-col-right {
                        padding-left: 50px;
                        padding-right: 0px;
                        text-align: left;
                        justify-content: flex-start;

                        .item-text-container {
                            .year-container {
                                .timeline-indicator {
                                    left: -65px;
                                    right: unset;
                                    top: -2px;
                                }
                            }
                        }
                    }
                }
            }
            
            @media (max-width: 767px) {
                .item-col {

                    // left and right column styles
                    &.item-col-left,
                    &.item-col-right {

                        // media
                        .item-media-container {
                            .item-image-container {
                                img {
                                    max-width: 130px;
                                }
                            }
                        }

                        // text content
                        .item-text-container {
                            .year-container {
                                .timeline-indicator {
                                    left: -50px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/**
 * Vertical Timeline Tests/Systems
 */

.timeline-tests-systems-container {
    background: linear-gradient(274.54deg, #0079C1 41.98%, #15BBED 135.54%);
    padding: 50px 0px 120px 0px;

    .timeline-header {
        h2 {
            color: #ffffff;
            margin-bottom: 50px;
        }
    }

    .timeline-toggles {
        text-align: center;
        margin-bottom: 75px;

        @media (max-width: 992px) {
            margin-bottom: 20px;
        }

        #systems-timeline-toggle {
            margin-right: 10px;
        }

        #tests-timeline-toggle {
            margin-left: 10px;
        }

        .btn {
            &.active {
                background-color: white;
                color: var(--cepheidBlue);
            }
        }
    }
}