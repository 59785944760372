.carousel {
    //width: 100vw;
    overflow: hidden;

    component {
        display: none;
    }

    &.quotes .arrow-box {
        padding-top: 15px;
    }

    .markers {
        display: grid;
        grid-template-columns: auto;
        grid-auto-flow: column;
        grid-gap: 10px;
        justify-content: center;
        margin-top: 30px;
        position: relative;
        z-index: 3;

        @media (max-width: 767px) {
            margin-top: 20px;
        }

        &.light .marker {
            background: var(--mediumGrey);
        }

        &.compressed {
            margin: 20px;
        }

        .marker {
            height: 20px;
            width: 20px;
            background: var(--grey);
            cursor: pointer;

            @media (max-width: 767px) {
                width: 10px;
                height: 10px;
            }

            &.active {
                background: var(--yellow);
            }
        }
    }

    &.gallery-multiple {
        margin-bottom: -60px;

        @media (max-width: 767px) {
            padding-bottom: 30px;
        }
    }
}
